import React from "react";
import { Modal, Tooltip } from 'antd';
import { URL_INFLORESCENCE } from "../../constants/urls";
import TSTable from "../common/TSTable";
import ColorButton from "../common/ColorButton";
import _ from 'lodash';
import dayjs from "dayjs";
import { DATETIME_FORMAT, INFLORESCENCE_ACTION_TH, INFLORESCENCE_STATE_TH, INFLORESCENCE_TASK_STATE_TH } from "../../constants/strings";
import { ellipsisString } from "../../frameworks/Util";

export default function InflorescenseStateModal(props) {
  const {open, onClose, target} = props;
  const tableRef = React.useRef(null);

  const columns = [
    {
      title: "งาน",
      dataIndex: "action",
      render : (value) => (value ? INFLORESCENCE_ACTION_TH[value] : "-")
    },
    {
      title: "วันที่กำหนด",
      dataIndex: "plan_date",
    },
    {
      title: "วันที่ปฎิบัติ",
      dataIndex: "done_datetime",
      render : (value) => (value ? dayjs(value).format(DATETIME_FORMAT): '-')
    },
    {
      title: "ผล",
      dataIndex: "task_state",
      render : (value) => _.get(INFLORESCENCE_TASK_STATE_TH, value, '-')
    },
    {
      title: "หมายเหตุ",
      dataIndex: "action_note",
      width : 200,
      render : (value, original) => (
        original.reject_reason ? `(${_.get(original, 'reject_reason_name', '')}) ${value}` : value
      )
    },
    {
      title: "รูปภาพ",
      dataIndex: "images",
      width : 300,
      render : (value) => (
        <a href={_.get(value, '0.image', null)} target="_blank">
          <Tooltip title={_.get(value, '0.image', null)} placement="bottomRight">
            {ellipsisString(_.get(value, '0.image', '-'), 30)}
          </Tooltip>
        </a>
      ),
    },
    {
      title: "ผู้ปฏิบัติงาน",
      dataIndex: "submitter_name",
    },
  ]

  return (
    <Modal
      destroyOnClose
      open={open}
      width={1400}
      onCancel={onClose}
      maskClosable={false}
      title={`${_.get(target, 'code', '')} - ${_.get(INFLORESCENCE_STATE_TH, _.get(target, 'state'), '???')}`}
      footer={[ <ColorButton onClick={props.onClose} type="primary" key={_.get(target, 'code', '')}>{"ปิด"}</ColorButton> ]}>

      <TSTable
        ref={tableRef}
        columns={columns}
        style={{ marginTop : 16 }}
        rowKey={"id"}
        url={URL_INFLORESCENCE.TASK}
        params={{ inflorescence : _.get(target, 'id', null) }}
        size={"small"}
        pageSize={10}
        paginationOptions={{
          position: ["bottomCenter"],
          showTotal: total => `Total ${total} rows`,
          showSizeChanger: false,
        }}
      />
    </Modal>
  )
}