import React from 'react';
import HeaderMobile from '../components/common/HeaderMobile';
import { theme, Layout, Form, Input, List, Divider, Alert, Spin, Card, Button, Modal} from 'antd';
import Icon, { 
  SendOutlined, 
  QuestionCircleOutlined, 
  CheckCircleOutlined, 
  CloseCircleOutlined, 
  InfoCircleOutlined 
} from '@ant-design/icons';
import { Content, } from 'antd/es/layout/layout';
import { useNavigate, useParams } from 'react-router-dom';
import { GET, POST } from '../frameworks/HttpClient';
import { URL_DELIVERY } from '../constants/urls';
import * as _ from 'lodash';
import { DELIVERY_TRANSACTION_STATE, EXPORT_TYPE, EXPORT_TYPE_TH, TASK_COLOR } from '../constants/strings';
import FloatColorButton from '../components/common/FloatColorButton';
import { GRAY_COLOR } from '../constants/color';
import "../styles.css"
import EvaluateBunchModal from '../receiver/EvaluateBunchModal';
import EvaluatePollenModal from '../receiver/EvaluaPollenModal';
import { PATH } from '../routes/CustomRoutes';

export default function ReceiverMobileScreen() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [form] = Form.useForm();
  const [productData, setProductData] = React.useState([]);
  const [shippingData, setShippingData] = React.useState(null);
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [bunchTarget, setBunchTarget] = React.useState(null);
  const [pollenTarget, setPollenTarget] = React.useState(null);
  const [isInflorescencesDone, setIsInflorescencesDone] = React.useState(false);
  
  const {
    token: { colorSuccess, colorInfo, colorError },
  } = theme.useToken();

  const formItemLayout = {
    labelCol: {
      xs: { span: 8, },
      sm: { span: 8, },
    },
    wrapperCol: {
      xs: { span: 14, },
      sm: { span: 14, },
    },
  };

  // Fetch Over all deliver transaction
  const fetchShippingData = async() => {
    setErrorMessages(null)
    setLoading(true)
    try {
      const response = await GET(`${URL_DELIVERY.DELIVERY_TRANSACTION}${id}/`)
      setShippingData(response.data)
      form.setFieldsValue(response.data)
    } catch (error) {
      setErrorMessages(error.errorMessages); 
    } finally {
      setLoading(false)
    }
  }

  // Fetch Transaction List
  const fetchProductTransactionsData = async() => {
    setErrorMessages(null)
    try {
      const response = await GET(
        URL_DELIVERY.INFLORESCENCE_DELIVERY, 
        {delivery_transaction: id, page_size: 999})
      setProductData(response.data.results)
    } catch (error) {
      setErrorMessages(error.errorMessages)
    }
  }

  // Submit receive product
  const handleReceivedProduct = async() => {
    setErrorMessages(null)
    try {
      await POST(`${URL_DELIVERY.DELIVERY_TRANSACTION}${id}/${DELIVERY_TRANSACTION_STATE.RECEIVED}/`)
      navigate(PATH.RECEIVER_MOBILE)
    } catch (error) {
      setErrorMessages(error.errorMessages)
    }
  }

  // Select evaluate modal (pollen / bunch)
  const handleEvaluateButton = (item) => {
    if(shippingData.export_type === EXPORT_TYPE.POLLEN){
      setPollenTarget(item)
    } else if(shippingData.export_type === EXPORT_TYPE.BUNCH){
      setBunchTarget(item)
    }
  }

  // Refresh data
  const refreshData = () => {
      fetchShippingData();
      fetchProductTransactionsData();
  }

  React.useEffect(() => {
    if(shippingData){
      // Calulate evaluate state
      setIsInflorescencesDone(shippingData && shippingData.inflorescences_number === shippingData.accepted_number + shippingData.rejected_number)
    }
  },[shippingData])

  React.useEffect(() => {
    refreshData();
  },[])

  return(
    <Spin spinning={loading}>
    <Layout style={{ minHeight: '100vh' }}>
      <HeaderMobile 
        title={`ใบส่งของ - ${_.get(shippingData, 'code', null) ? _.get(shippingData, 'code', null) : '-'}`} 
        className='navbar-top' 
        back={true} 
        right={
          <Button 
            type={'primary'} 
            disabled={isInflorescencesDone ? false : true}
            onClick={() => {
              Modal.confirm({
                title: `ยืนยันรับสินค้า - ${_.get(shippingData, 'code', null)}`,
                content: 'คุณต้องการยืนยันรับสินค้าใช่หรือไม่',
                okText: 'ยืนยัน',
                icon: <InfoCircleOutlined />,
                okButtonProps: {style: { background : colorInfo}},
                maskClosable: true,
                onOk: () => {
                  handleReceivedProduct();
                },
                cancelText: 'ยกเลิก',
              })
            }}>
            รับเข้า
          </Button>
        }/>
        <Content
          style={{
          margin: 24,
          marginTop: 60,
          marginBottom: 60,
          minHeight: 280,
          }}>
            {errorMessages && 
            <Alert
              style={{marginBottom: "12px", textAlign: "left"}}
              message={errorMessages}
              type="error"
              showIcon 
            />}
          <Form
            {...formItemLayout}
            variant="filled"
            form={form}
            className='custom-inline-form-item'
            style={{ maxWidth: 400, }}>
            <Form.Item
              name={'sent_date'}
              label={'วันที่ส่ง :'}
              style={{
                maxWidth: '100%',
              }}>
              <Input disabled={true}/>
            </Form.Item>
              
            <Form.Item
              name={'vehicle'}
              label={'ทะเบียนรถ :'}
              style={{
                maxWidth: '100%',
              }}>
              <Input disabled={true}/>
            </Form.Item>
          </Form>
          <Divider>{`${EXPORT_TYPE_TH[_.get(shippingData, 'export_type', '-')]}`}</Divider>
          <List
            itemLayout="horizontal"
            dataSource={productData}
            renderItem={(item) => (
              <Card 
                className="custom-card-body" 
                style={{
                  marginBottom: 12, 
                  background: item.officer_evaluate_datetime ? null : TASK_COLOR.WARNING}}
                  onClick={() => handleEvaluateButton(item)}>
                  <List.Item style={{border: "none", padding: 0}}>
                    <List.Item.Meta 
                      title={
                        <div>
                          <Icon 
                            style={{
                              marginRight: 8, 
                              fontSize: 24,
                              color: (item.is_qc_passed === null) ? GRAY_COLOR 
                                : item.is_qc_passed ? colorSuccess : colorError 
                            }}
                            component={(item.is_qc_passed === null) ? QuestionCircleOutlined
                              : item.is_qc_passed ? CheckCircleOutlined : CloseCircleOutlined} />
                          
                          {_.get(item , 'inflorescence_code', '-')} - {_.get(item , 'inflorescence_species_name', '-')}
                        </div>
                      }
                      description={
                        <div>
                          {shippingData && (shippingData.export_type === EXPORT_TYPE.BUNCH) && (
                            <div>เกรด : {_.get(item , 'grade_name', null) ? item.grade_name : "-"}, น้ำหนัก {_.get(item , 'weight', null) ? item.weight : "-"} กก.</div>
                          )}
                          <div>หมายเหตุ : {_.get(item , 'worker_note', null) ? _.get(item , 'worker_note', '-') : '-'}</div>
                        </div>
                      }
                    />
                  </List.Item>
              </Card>
            )}
          />
        </Content>

        <FloatColorButton
          className='float-button-mobile'
          override={colorInfo}
          type='primary'
          icon={<SendOutlined />}
          onClick={_ => handleEvaluateButton('add')}/>

        <EvaluateBunchModal
          open={bunchTarget ? true : false}
          onClose={() => setBunchTarget(null)}
          onUpdate={() => {
            refreshData();
            setBunchTarget(null);
          }}
          shippingData={shippingData}
          target={bunchTarget}
        />

        <EvaluatePollenModal
          open={pollenTarget ? true : false}
          onClose={() => setPollenTarget(null)}
          onUpdate={() => {
            refreshData();
            setPollenTarget(null);
          }}
          shippingData={shippingData}
          target={pollenTarget}
        />

    </Layout>
    </Spin>
  )
}