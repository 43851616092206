import React from "react";
import { URL_DELIVERY } from "../constants/urls";
import ReceiverList from "../receiver/ReceiverList";
import { useUserContext } from "../hooks/UserSettingProvider";
import { Button } from "antd";
import { SUCCESS_COLOR } from "../constants/color";
import SenderModal from "../components/shipping/SenderModal";

export default function FieldSenderView() {
  const { user } = useUserContext();
  const listRef = React.useRef(null);
  const [isActive, setActive] = React.useState(null); 
  const [siTarget, setSiTarget] = React.useState(null);

  const refresh = () => {
    if (listRef && listRef.current)
      listRef.current.refresh();
  }
  
  React.useEffect(() => {
    setActive(true);
    return () => { setActive(false) }
  }, [])
  
  return(
    <div>
      <Button 
        style={{
          float: "right", 
          marginLeft: 8, 
          background: SUCCESS_COLOR}}
        type={"primary"}
        onClick={_ => setSiTarget({id : 'add'})}>
          + เพิ่มใบส่งของ
      </Button>
      <ReceiverList
        ref={listRef}
        url={URL_DELIVERY.DELIVERY_TRANSACTION}
        params={{
          is_active: true, 
          has_sender: true,
          plantation_code: user ? user.plantation_code : null,
          ordering: '-created'
        }}
        plantationCode={user ? user.plantation_code : null}
        isSender={true}
        isActive={isActive}
        onEdit={(item) => setSiTarget(item)}
      />,

      <SenderModal
        open={siTarget ? true : false}
        onClose={() => setSiTarget(null)}
        target={siTarget}
        onUpdate={() => {
          setSiTarget(null);
          refresh();
        }}
      />
    </div>
  )
}