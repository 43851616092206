import React from "react";
import { Alert, Button, Col, DatePicker, Form, Modal, Row, Select, Spin, theme, Input } from "antd";
import { useForm } from "antd/es/form/Form";
import { FileExcelOutlined, FilePdfOutlined } from "@ant-design/icons";
import Header from "../common/Header";
import moment from "moment";
import { DATE_FORMAT, DELIVERY_TRANSACTION_STATE,
  REPORT_FILTER, REPORT_FILTER_TH, 
  TREE_GENDER_OPTIONS, INFLORESCENCE_REPORT_ACTION_OPTIONS, 
  INFLORESCENCE_QC_PASSED_OPTIONS } from "../../constants/strings";
import propTypes from "prop-types";
import useDropdownItem from "../../hooks/useDropdownItem";
import { URL_COMMON, URL_DELIVERY, URL_PALMTREE } from "../../constants/urls";
import { GET } from "../../frameworks/HttpClient";
import _ from 'lodash'

const SEARCH_DELAY_MILLISECONDS = 800;

export default function ReportModal(props) {
  const [form] = useForm();
  const {open, onClose} = props;
  const [pdfLoading, setPDFLoading] = React.useState(false);
	const [excelLoading, setExcelLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [data, setData] = React.useState(null); 
  const [searchPalmTree, setSearchPalmTree] = React.useState(null);
  const plantationDropdown = useDropdownItem({ url: URL_PALMTREE.PLANTATION, label: 'code', value:'id', pagination: true, params: { page_size: 999 } })
  const treeTypeDropdown = useDropdownItem({ url: URL_PALMTREE.TREE_TYPE, label: 'name', value:'id', pagination: true, params: { page_size: 999 } })
  const productionTypeDropdown = useDropdownItem({ url: URL_COMMON.PRODUCTION_TYPE, label: 'name', pagination: true, params: { page_size: 999 } })
  const speciesDropdown = useDropdownItem({ url: URL_PALMTREE.SPECIES, label: 'title', pagination: true, params: { page_size: 999 } })
  const plamTreeDropdown = useDropdownItem({ url: URL_PALMTREE.PALMTREE, label: 'code', pagination: true, params: { page_size: 50 } })
  const plotDropdown = useDropdownItem({ url: URL_PALMTREE.PLOT, label: 'code', pagination: true, params: { page_size: 999 } })

  const debounceSearchPalmTree = React.useCallback(
    _.debounce(setSearchPalmTree, SEARCH_DELAY_MILLISECONDS),
    []
  );

  React.useEffect(() => {
    // if (!scanLoading) {
      
    //   //fetchData({ [optionLabel]: search })
    // }
    plamTreeDropdown.fetchOptions({ 'code': searchPalmTree })
  }, [searchPalmTree])

  const [deliveryParams, setDeliveryParams] = React.useState({code: ''})
  const deliveryDropdown = useDropdownItem({ 
    url: URL_DELIVERY.DELIVERY_TRANSACTION, 
    label: 'code', 
    pagination: true, 
    params: {is_active: true, state : DELIVERY_TRANSACTION_STATE.RECEIVED, ...deliveryParams} })
  const plantationWatch = Form.useWatch('plantation', form);

  const {
		token: { colorSuccessTextActive, colorErrorTextActive }
	} = theme.useToken();

  const debounceDelivery = React.useCallback(
    _.debounce((_searchVal) => {
      setDeliveryParams({code: _searchVal})
    }, 800),
    []
  )

  const handleSubmit = async (exportType) => {
		let url = data.url
		if (!url) {
			setErrorMessages("Report not found!");
			return;
		}

		// Set loading
		props.onStartLoading(exportType)
		if (exportType === "pdf")
			setPDFLoading(true);
		else
			setExcelLoading(true);
	
		// Prepare data
		setErrorMessages(null);
		try {
			const values = await form.validateFields();
			const params = getParams(values, exportType);
      
			// Goto export
			const response = await GET(`${url}`, params, false, true) // Download as blob
      const fileURL = URL.createObjectURL(response.data)
			
			if(exportType === "excel" && props.namePrefix != null) {
				const fileLink = document.createElement('a');
				fileLink.href = fileURL;
				fileLink.download = `${props.namePrefix.replace(/ /g, "_")}_${moment().unix() }.xlsx`;
				fileLink.click();
				setTimeout(() => {fileLink.remove()}, 100);
			}
			else {
				setTimeout(() => {window.open(fileURL, '_blank')})
			}
			props.onClose();

		} catch (error) {
			if (error.errorMessages) 
				setErrorMessages(error.errorMessages);
		}

		finally {
			props.onEndLoading()
			if (exportType === "pdf")
				setPDFLoading(false);
			else
				setExcelLoading(false);
		}
	}

  const filterOption = (input, option) =>
  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())

  const isShowElement = (list, targetName) => {
    return list.find(({ name }) => name === targetName) ? true : false;
  }

  const isRequired = (list, targetName) => {
    const item = list.find(({ name }) => name === targetName);
    return item.required ? true : false;
  }

  const getPlaceHolder = (list, targetName) => {
    const item = list.find(({ name }) => name === targetName);
    return item.placeholder
  }

  const getParams = (values, exportType) => {
    let params = {...values}
    if (values[REPORT_FILTER.DATE_RANGE]) {
      params["from_date"] = values[REPORT_FILTER.DATE_RANGE][0].format(DATE_FORMAT);
      params["to_date"] = values[REPORT_FILTER.DATE_RANGE][1].format(DATE_FORMAT);
      delete params[REPORT_FILTER.DATE_RANGE]
    }
    if (values[REPORT_FILTER.DATE_RANGE_2]) {
      params["start_date"] = values[REPORT_FILTER.DATE_RANGE_2][0].format(DATE_FORMAT);
      params["end_date"] = values[REPORT_FILTER.DATE_RANGE_2][1].format(DATE_FORMAT);
      delete params[REPORT_FILTER.DATE_RANGE_2]
    }
    if (values[REPORT_FILTER.DATE]) {
      params["date"] = values[REPORT_FILTER.DATE].format(DATE_FORMAT);
    }

    if (values[REPORT_FILTER.PLAN_DATE]) {
      params["plan_date"] = values[REPORT_FILTER.PLAN_DATE].format(DATE_FORMAT);
    }
    params["export_type"] = exportType
    return params;
  }

  React.useEffect(() => {
    if (open) {
      setErrorMessages(null);
      form.resetFields();
    }
  }, [open])

  React.useEffect(() => {
		setData(props.data);
	}, [props.data])

  React.useEffect(() => {
    if(data && data.filters){
      if(isShowElement(data.filters, REPORT_FILTER.PLANTATION)){
        plantationDropdown.fetchOptions();
      }
      if(isShowElement(data.filters, REPORT_FILTER.TREE_TYPE)){
        treeTypeDropdown.fetchOptions();
      }
      if(isShowElement(data.filters, REPORT_FILTER.PRODUCTION_TYPE)){
        productionTypeDropdown.fetchOptions();
      }
      if(isShowElement(data.filters, REPORT_FILTER.SPECIES)){
        speciesDropdown.fetchOptions();
      }
      if(isShowElement(data.filters, REPORT_FILTER.PALMTREE)){
        plamTreeDropdown.fetchOptions();
      }
    }
  }, [data])

  React.useEffect(() => {
    if (plantationWatch && data && data.filters && 
      isShowElement(data.filters, REPORT_FILTER.PLOT)) {
      plotDropdown.fetchOptions({ plantation: plantationWatch });
    }
  }, [plantationWatch])

  React.useEffect(() => {
    if (deliveryParams && data && data.filters && 
      isShowElement(data.filters, REPORT_FILTER.DELIVERY_TRANSACTION)) {
      deliveryDropdown.fetchOptions();
    }
  }, [deliveryParams])

  return(
    <Modal
      open={open}
      onCancel={onClose}
      maskClosable={false}
      footer={[
				<Button key={"1"} onClick={props.onClose}>{"ยกเลิก"}</Button>,
				props.data && !props.data.hideExcel && (
					<Button
						key={"2"}
						type={"primary"}
						loading={excelLoading}
						icon={<FileExcelOutlined />}
						style={{ background: colorSuccessTextActive }}
						onClick={() => handleSubmit("excel")}>
						{"Excel"}
					</Button>
				)
				,
				props.data && !props.data.hidePDF && (
					<Button 
						key={"3"}
						type={"primary"}
						loading={pdfLoading}
						style={{background: colorErrorTextActive}}
						icon={<FilePdfOutlined />}
						onClick={() => handleSubmit("pdf")}>
						{"PDF"}
					</Button>
				)
    ]}>
    <Header title={data ? data.detail : "Report"}></Header>
    {errorMessages && 
      <Alert
        message={errorMessages}
        type="error"
        showIcon
        style={{marginTop: "12px", marginBottom: "12px", textAlign: "left"}}
      />
    }

    <Form 
      style={{marginTop: 16}}
      form={form}
      layout={"vertical"}>
        <Row gutter={16} justify={"start"}>
          {data && data.filters && isShowElement(data.filters, REPORT_FILTER.TREE_GENDER) && (
            <Col xl={12} sm={12} xs={24} key={"showTreeGender"}>
              <Form.Item 
                name={REPORT_FILTER.TREE_GENDER} 
                label={REPORT_FILTER_TH[REPORT_FILTER.TREE_GENDER]}
                rules={[{ required: isRequired(data.filters, REPORT_FILTER.TREE_GENDER) }]}>
                <Select
                  showSearch
                  style={{ width : '100%'}}
                  placeholder={getPlaceHolder(data.filters, REPORT_FILTER.TREE_GENDER)}
                  initialvalues={''}
                  autoComplete='off'
                  allowClear={true}
                  filterOption={filterOption}
                  options={TREE_GENDER_OPTIONS}
                />
              </Form.Item>
            </Col>
          )}

          {data && data.filters && isShowElement(data.filters, REPORT_FILTER.TREE_TYPE) && (
            <Col xl={12} sm={12} xs={24} key={"showTreeType"}>
              <Form.Item 
                name={REPORT_FILTER.TREE_TYPE} 
                label={REPORT_FILTER_TH[REPORT_FILTER.TREE_TYPE]}
                rules={[{ required: isRequired(data.filters, REPORT_FILTER.TREE_TYPE) }]}>
                <Select
                  showSearch
                  style={{ width : '100%'}}
                  placeholder={getPlaceHolder(data.filters, REPORT_FILTER.TREE_TYPE)}
                  initialvalues={''}
                  autoComplete='off'
                  allowClear={true}
                  filterOption={filterOption}
                  options={treeTypeDropdown.options}
                  loading={treeTypeDropdown.loading}
                />
              </Form.Item>
            </Col>
          )}

          {data && data.filters && isShowElement(data.filters, REPORT_FILTER.PRODUCTION_TYPE) && (
            <Col xl={12} sm={12} xs={24} key={"showProductionType"}>
              <Form.Item 
                name={REPORT_FILTER.PRODUCTION_TYPE} 
                label={REPORT_FILTER_TH[REPORT_FILTER.PRODUCTION_TYPE]}
                rules={[{ required: isRequired(data.filters, REPORT_FILTER.PRODUCTION_TYPE) }]}>
                <Select
                  showSearch
                  style={{ width : '100%'}}
                  placeholder={getPlaceHolder(data.filters, REPORT_FILTER.PRODUCTION_TYPE)}
                  initialvalues={''}
                  autoComplete='off'
                  allowClear={true}
                  filterOption={filterOption}
                  options={productionTypeDropdown.options}
                  loading={productionTypeDropdown.loading}
                />
              </Form.Item>
            </Col>
          )}

          {data && data.filters && isShowElement(data.filters, REPORT_FILTER.PLANTATION) && (
            <Col xl={12} sm={12} xs={24} key={"showPlantation"}>
              <Form.Item 
                name={REPORT_FILTER.PLANTATION} 
                label={REPORT_FILTER_TH[REPORT_FILTER.PLANTATION]}
                rules={[{ required: isRequired(data.filters, REPORT_FILTER.PLANTATION) }]}>
                <Select
                  showSearch
                  style={{ width : '100%'}}
                  placeholder={getPlaceHolder(data.filters, REPORT_FILTER.PLANTATION)}
                  initialvalues={''}
                  autoComplete='off'
                  filterOption={filterOption}
                  allowClear={true}
                  options={plantationDropdown.options}
                  loading={plantationDropdown.loading}
                />
              </Form.Item>
            </Col>
          )}

          {data && data.filters && isShowElement(data.filters, REPORT_FILTER.SPECIES) && (
            <Col xl={12} sm={12} xs={24} key={"showSpecies"}>
              <Form.Item 
                name={REPORT_FILTER.SPECIES} 
                label={REPORT_FILTER_TH[REPORT_FILTER.SPECIES]}
                rules={[{ required: isRequired(data.filters, REPORT_FILTER.SPECIES) }]}>
                <Select
                  showSearch
                  style={{ width : '100%'}}
                  placeholder={getPlaceHolder(data.filters, REPORT_FILTER.SPECIES)}
                  initialvalues={''}
                  autoComplete='off'
                  allowClear={true}
                  filterOption={filterOption}
                  options={speciesDropdown.options}
                  loading={speciesDropdown.loading}
                />
              </Form.Item>
            </Col>
          )}

          {data && data.filters && isShowElement(data.filters, REPORT_FILTER.PALMTREE) && (
            <Col xl={12} sm={12} xs={24} key={"showPalmTree"}>
              <Form.Item 
                name={REPORT_FILTER.PALMTREE} 
                label={REPORT_FILTER_TH[REPORT_FILTER.PALMTREE]}
                rules={[{ required: isRequired(data.filters, REPORT_FILTER.PALMTREE) }]}>
                <Select
                  showSearch
                  style={{ width : '100%'}}
                  placeholder={getPlaceHolder(data.filters, REPORT_FILTER.PALMTREE)}
                  initialvalues={''}
                  autoComplete='off'
                  allowClear={true}
                  filterOption={filterOption}
                  options={plamTreeDropdown.options}
                  loading={plamTreeDropdown.loading}
                  onSearch={(value) => {
                    debounceSearchPalmTree(value)
                  }}
                />
              </Form.Item>
            </Col>
          )}

          {data && data.filters && isShowElement(data.filters, REPORT_FILTER.PLOT) && (
            <Col xl={12} sm={12} xs={24} key={"showPlot"}>
              <Form.Item 
                name={REPORT_FILTER.PLOT} 
                label={REPORT_FILTER_TH[REPORT_FILTER.PLOT]}
                rules={[{ required: isRequired(data.filters, REPORT_FILTER.PLOT) }]}>
                <Select
                  showSearch
                  style={{ width : '100%'}}
                  placeholder={getPlaceHolder(data.filters, REPORT_FILTER.PLOT)}
                  initialvalues={''}
                  autoComplete='off'
                  allowClear={true}
                  filterOption={filterOption}
                  options={plotDropdown.options}
                  loading={plotDropdown.loading}
                />
              </Form.Item>
            </Col>
          )}

          {data && data.filters && isShowElement(data.filters, REPORT_FILTER.ROW) && (
            <Col xl={12} sm={12} xs={24} key={"showRow"}>
              <Form.Item 
                name={REPORT_FILTER.ROW} 
                label={REPORT_FILTER_TH[REPORT_FILTER.ROW]}
                rules={[{ required: isRequired(data.filters, REPORT_FILTER.ROW) }]}>
                <Input 
                  autoComplete="off" 
                  placeholder={getPlaceHolder(data.filters, REPORT_FILTER.ROW)}/>
              </Form.Item>
            </Col>
          )}
          
          {data && data.filters && isShowElement(data.filters, REPORT_FILTER.COL) && (
            <Col xl={12} sm={12} xs={24} key={"showCol"}>
              <Form.Item 
                name={REPORT_FILTER.COL} 
                label={REPORT_FILTER_TH[REPORT_FILTER.COL]}
                rules={[{ required: isRequired(data.filters, REPORT_FILTER.COL) }]}>
                <Input 
                  autoComplete="off" 
                  placeholder={getPlaceHolder(data.filters, REPORT_FILTER.COL)}/>
              </Form.Item>
            </Col>
          )}

          {data && data.filters && isShowElement(data.filters, REPORT_FILTER.DELIVERY_TRANSACTION) && (
            <Col xl={12} sm={12} xs={24} key={"showDelivery"}>
              <Form.Item 
                name={REPORT_FILTER.DELIVERY_TRANSACTION} 
                label={REPORT_FILTER_TH[REPORT_FILTER.DELIVERY_TRANSACTION]}
                rules={[{ required: isRequired(data.filters, REPORT_FILTER.DELIVERY_TRANSACTION) }]}>
                <Select
                  showSearch
                  style={{ width : '100%'}}
                  placeholder={getPlaceHolder(data.filters, REPORT_FILTER.DELIVERY_TRANSACTION)}
                  initialvalues={''}
                  autoComplete='off'
                  filterOption={false}
                  allowClear={true}
                  onSearch={debounceDelivery}
                  options={[...deliveryDropdown.options]}
                  loading={deliveryDropdown.loading}
                />
              </Form.Item>
            </Col>
          )}

          {data && data.filters && isShowElement(data.filters, REPORT_FILTER.DATE) && (
            <Col xl={12} sm={12} xs={24} key={"showDate"}>
              <Form.Item 
                name={REPORT_FILTER.DATE} 
                label={REPORT_FILTER_TH[REPORT_FILTER.DATE]}
                rules={[{ required: isRequired(data.filters, REPORT_FILTER.DATE) }]}>
                <DatePicker 
                  style={{ width: '100%' }} 
                  inputReadOnly={true} 
                  placeholder={getPlaceHolder(data.filters, REPORT_FILTER.DATE)}/>
              </Form.Item>
            </Col>
          )}

          {data && data.filters && isShowElement(data.filters, REPORT_FILTER.DATE_RANGE) && (
            <Col xl={24} sm={12} xs={24} key={"showDateRange"}>
              <Form.Item 
                name={REPORT_FILTER.DATE_RANGE} 
                label={REPORT_FILTER_TH[REPORT_FILTER.DATE_RANGE]} 
                rules={[{ required: isRequired(data.filters, REPORT_FILTER.DATE_RANGE) }]}>
                <DatePicker.RangePicker 
                  style={{ width: '100%' }} 
                  inputReadOnly={true} 
                  placeholder={getPlaceHolder(data.filters, REPORT_FILTER.DATE_RANGE)}/>
              </Form.Item>
            </Col>
          )}

          {data && data.filters && isShowElement(data.filters, REPORT_FILTER.IS_QC_PASSED) && (
            <Col xl={12} sm={12} xs={24} key={"showQCPassed"}>
              <Form.Item
                name={REPORT_FILTER.IS_QC_PASSED}
                label={REPORT_FILTER_TH[REPORT_FILTER.IS_QC_PASSED]}
                rules={[{ required: isRequired(data.filters, REPORT_FILTER.IS_QC_PASSED) }]}>
                <Select
                  style={{ width: '100%' }}
                  placeholder={getPlaceHolder(data.filters, REPORT_FILTER.IS_QC_PASSED)}
                  initialvalues={''}
                  autoComplete='off'
                  allowClear={true}
                  filterOption={filterOption}
                  options={INFLORESCENCE_QC_PASSED_OPTIONS}
                />
              </Form.Item>
            </Col>
          )}

          {data && data.filters && isShowElement(data.filters, REPORT_FILTER.ACTION) && (
            <Col xl={12} sm={12} xs={24} key={"showAction"}>
              <Form.Item
                name={REPORT_FILTER.ACTION}
                label={REPORT_FILTER_TH[REPORT_FILTER.ACTION]}
                rules={[{ required: isRequired(data.filters, REPORT_FILTER.ACTION) }]}>
                <Select
                  showSearch
                  style={{ width: '100%' }}
                  placeholder={getPlaceHolder(data.filters, REPORT_FILTER.ACTION)}
                  initialvalues={''}
                  autoComplete='off'
                  allowClear={true}
                  filterOption={filterOption}
                  options={INFLORESCENCE_REPORT_ACTION_OPTIONS}
                />
              </Form.Item>
            </Col>
          )}

          {data && data.filters && isShowElement(data.filters, REPORT_FILTER.DATE_RANGE_2) && (
            <Col xl={24} sm={12} xs={24} key={"showDateRange2"}>
              <Form.Item 
                name={REPORT_FILTER.DATE_RANGE_2} 
                label={REPORT_FILTER_TH[REPORT_FILTER.DATE_RANGE_2]} 
                rules={[{ required: isRequired(data.filters, REPORT_FILTER.DATE_RANGE_2) }]}>
                <DatePicker.RangePicker 
                  style={{ width: '100%' }} 
                  inputReadOnly={true} 
                  placeholder={getPlaceHolder(data.filters, REPORT_FILTER.DATE_RANGE_2)}/>
              </Form.Item>
            </Col>
          )}

          {data && data.filters && isShowElement(data.filters, REPORT_FILTER.PLAN_DATE) && (
            <Col xl={12} sm={12} xs={24} key={"showPlanDate"}>
              <Form.Item 
                name={REPORT_FILTER.PLAN_DATE} 
                label={REPORT_FILTER_TH[REPORT_FILTER.PLAN_DATE]}
                rules={[{ required: isRequired(data.filters, REPORT_FILTER.PLAN_DATE) }]}>
                <DatePicker 
                  style={{ width: '100%' }} 
                  inputReadOnly={true} 
                  placeholder={getPlaceHolder(data.filters, REPORT_FILTER.PLAN_DATE)}/>
              </Form.Item>
            </Col>
          )}

      </Row>
    </Form>
    </Modal>
  )
}

ReportModal.defaultProps = {
  open: false,
	data: null,
  onClose: () => {},
  onStartLoading: (type) => {},
	onEndLoading: () => {},
}

ReportModal.propTypes = {
	namePrefix: propTypes.string,
  open: propTypes.bool,
	data: propTypes.object,
  onClose: propTypes.func,
	onStartLoading: propTypes.func,
	onEndLoading: propTypes.func,
}