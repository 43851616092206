import React from 'react';
import * as _ from 'lodash';
import { GET } from '../frameworks/HttpClient';


export default function useDropdownItem({
  url,
  manual=true,
  key='id',
  value='id',
  label='name',
  label_renderer=null,
  pagination=false,
  params={},
}) {
  const [options, setOptions] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const itemParse = (item, idx='-') => ({
    ...item,
    key: _.get(item, key, idx),
    value: _.get(item, value, idx),
    label: label_renderer ? label_renderer(item) : _.get(item, label, idx),
  })

  const setQueryparams = (params) => {
    let newParams = new URLSearchParams();
    Object.entries(params).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach(v => newParams.append(key, v))
      } else {
        newParams.append(key, value)
      }
    })
    return newParams
  }

  const fetchOptions = async (additional_params={}) => {
    setLoading(true);
    try {
      const response = await GET(`${url}?${setQueryparams({...params, ...additional_params }).toString()}`)
      setOptions(
        _.get(response, pagination ? 'data.results' : 'data', [])
        .map((item, idx) => itemParse(item, idx)
      ))
    } catch (error) { console.log(error) } finally {
      setLoading(false);
    }
  }

  const addOption = (item) => {
    if (!options.find(e => _.get(e, 'value', '-') == _.get(item, value, '-'))) {
      setOptions((prev) => [...prev, itemParse(item)])
    }
  }

  React.useEffect(() => {
    if(!manual) {
      fetchOptions();
    }
  }, [])

  return { options, loading, fetchOptions, addOption };
}
