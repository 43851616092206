import React from "react";
import { Tabs} from 'antd';
import DashboardInfoView from "../components/dashboard/DashboardInfoView";
import DashboardBunchPollenView from "../components/dashboard/DashboardBunchPollenView";
import * as Dashboard from "../components/dashboard";

export default function DashboardView() {
  
  return (
    <Tabs
      defaultActiveKey='active'
      items={[
        {
          key: 'info',
          label: 'ข้อมูลทั่วไป',
          children: <DashboardInfoView/>
        },
        {
          key: 'work',
          label: 'การปฏิบัติงาน',
          children: <DashboardBunchPollenView/>
        },
      ]}
    />
  )
}   