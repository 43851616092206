import React from "react";
import { Modal, Form, DatePicker, Input, Button, Row, Col, Image, Alert } from 'antd';
import propTypes from 'prop-types';
import ColorButton from '../../components/common/ColorButton';
import imageDefault from "../../assets/placeholderImage.png";
import { UploadOutlined } from '@ant-design/icons';
import { PRIMARY_COLOR } from "../../constants/color";
import { GET, POST } from "../../frameworks/HttpClient";
import { URL_COMMON, URL_TASK } from "../../constants/urls";
import { DATE_FORMAT } from "../../constants/strings";
import * as dayjs from 'dayjs'
import { resizedImage } from "../../frameworks/CropUtil";
import { MAX_TASK_NUMNER } from "../../constants/configs";

export default function SurveyModal(props) {
  const { target, open, onClose, onUpdate } = props;
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [imageData, setImageData] = React.useState(null);
  const [maximumDay, setMaximumDay] = React.useState(null);
  const [taskNumber, setTaskNumber] = React.useState(null);
  const uploadUserRef = React.useRef(null);
  const [form] = Form.useForm();

  const handleSave = async() => {
    try {
      let data = await form.validateFields();
      data['new_date'] = dayjs(data.new_date).format(DATE_FORMAT)
      if(imageData){
        data['images'] = [{ image : imageData}]
      }
      await POST(`${URL_TASK[`${target.content_type}`]}${target.object_id}/postpone/`, data)
      onUpdate();
    } catch (error) {
      setErrorMessages(error.errorMessage)
    }
  }

  // Fetch Task on day (number)
  const fetchTaskNumber = async (dateStr) => {
    try {
      const response = await GET(URL_TASK.WORKER_TASKA_COUNT, {plan_date: dateStr});
      setTaskNumber(response.data.number);
    } catch (error) {
      console.log(error.errorMessages);
    }
  }

  // Handle maximum day
  const fetchTaskActivateDate = async (action, gender) => {
    if (!action) {
      return;
    }

    try {
      const response = await GET(URL_COMMON.TASK_ACTIVATE_DATE_SURVEY, {action: action, gender: gender});
      setMaximumDay(response.data.detail);
    } catch (error) {
      console.log(error.errorMessages);
    }
  }
  
  React.useEffect(() => {
    if(open){
      setImageData(null)
      if (target) {
        fetchTaskActivateDate(
          target.detail.action ? target.detail.action : null,
          target.detail.inflorescence_gender ? target.detail.inflorescence_gender : null
        )
      }
    } else {
      form.resetFields();
      setMaximumDay(null);
      setTaskNumber(null);
      setErrorMessages(null);
    }
  },[open])

  return (
    <Modal
      open={open}
      title={"สำรวจ"}
      width={400}
      onCancel={onClose}
      footer={null}
      maskClosable={false}
      centered>
      <Form form={form}>
        <Col md={24} sm={24} xs={24}>
          <>
            <Form.Item  
              name={"new_date"}
              label="สำรวจช่อครั้งถัดไป"
              rules={[{ required: true}]}>
              <DatePicker 
                allowClear={false}
                placeholder={"เลือกวันที่"}
                onFocus={evt => {evt.target.blur()}}
                minDate={dayjs()} 
                maxDate={(target && maximumDay) ? dayjs(target.created).add(maximumDay, 'day') : null}
                style={{ width : '100%'}}
                onChange={(date, dateStr) => {
                  fetchTaskNumber(dateStr);
                  form.setFieldValue('new_date', date)
                }}/>
            </Form.Item>
            {taskNumber != null &&
              <Form.Item>
                <Alert
                  message={taskNumber != null ? `งานรอทำ ${taskNumber} งาน` : ""}
                  type={(taskNumber != null && taskNumber > MAX_TASK_NUMNER) ? "warning" : "success"} />
              </Form.Item>
            }
          </>
        </Col>
        <Form.Item
          name={'note'}
          label={'หมายเหตุ'}>
          <Input.TextArea />
        </Form.Item>
      </Form>
      <Row>
        <Col md={24} sm={24} xs={24} 
          style={{ 
            width : '100%', 
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
          }}>
          {imageData && <Image
            src={imageData ? imageData : imageDefault}
              style={{ aspectRatio: imageData ? 2/3 : 1 , height : 350 }}
          />}
        </Col>
        <Col md={24} sm={24} xs={24}>
        <div                
          style={{ 
            width : '100%', 
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
          }}>
          <Button 
            style={{marginTop: 8, marginBottom: 16}}
            icon={<UploadOutlined />} 
            onClick={() => uploadUserRef.current.click()}>
              อัพโหลดรูป
          </Button>
        </div>
        </Col>
      </Row>

      {errorMessages && 
        <Alert
          message={errorMessages}
          type="error"
          showIcon
          style={{marginBottom: 8, textAlign: "left"}}
        />
      }

      <ColorButton 
        style={{ width : '100%', height: 48}} 
        type='primary' 
        override={PRIMARY_COLOR} 
        onClick={() => handleSave()}>  
        บันทึก
      </ColorButton>

      <input id="imgSelect"
        type="file"
        accept="image/*"
        ref={uploadUserRef}
        style={{ display: 'none' }}
        onChange={async (evt) => {
          if (evt.target.files.length > 0) {
            setImageData(await resizedImage(evt.target.files[0]))
            evt.target.value = null  // clear reference to enable select same file}
        }}}
      />

    </Modal>
  )
}

SurveyModal.defaultProps = {
  open: false, 
  target: null,
  onClose: () => {},
  onUpdate: () => {},
}

SurveyModal.propTypes = {
  open: propTypes.bool,
  target: propTypes.string,
  onClose: propTypes.func,
  onUpdate: propTypes.func,
}