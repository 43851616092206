import React from 'react'
import { Button, Result } from "antd";
import { useNavigate } from "react-router-dom";
import { PATH } from '../routes/CustomRoutes';

export default function NotFoundScreen() {
  const navigate = useNavigate();

  return (
    <Result
      status="404"
      title="Not Found !"
      subTitle="Sorry, the page you visited does not exist."
      extra={<Button type="primary" onClick={() => navigate(PATH.MAIN)}>Back Home</Button>}
    />
  )
}