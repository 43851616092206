import React from "react";
import propTypes from "prop-types";
import { Form, DatePicker, List, Space, Alert, Skeleton } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { DATE_FORMAT } from "../constants/strings";
import InfiniteScroll from 'react-infinite-scroll-component';
import * as _ from 'lodash';
import SenderModal from "../components/shipping/SenderModal";
import { GET } from "../frameworks/HttpClient";
import { URL_DELIVERY } from "../constants/urls";
import FloatColorButton from "../components/common/FloatColorButton";
import { SUCCESS_COLOR } from "../constants/color";
import SenderCard from "../components/shipping/SenderCard";
import { useNavigate } from 'react-router-dom';
import { useUserContext } from "../hooks/UserSettingProvider";

const DEFAULT_PAGE_SIZE = 5;

export default function SenderView(props) {
  const { user } = useUserContext();
  const [form] = Form.useForm();
  const [siTarget, setSiTarget] = React.useState(null);
  const [shippingData, setShippingData] = React.useState([]);
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [dataLazyLoadURL, setDataLazyLoadURL] = React.useState(null);
  const [isRefetch, setIsRefetch] = React.useState(false);
  const [selectedDate, setSelectedDate] = React.useState(null);
  const navigate = useNavigate();

  const onChange = (date, _) => {
    setSelectedDate(date)
  };

  const fetchDeliveryTransactions = async() => {
    setIsRefetch(true);
    setErrorMessages(null)
    try {
      const response = await GET(
        URL_DELIVERY.DELIVERY_TRANSACTION , 
        {
          ordering : 'sent_date', 
          plantation_code: user ? user.plantation_code : '',
          sent_after: selectedDate ? selectedDate.format(DATE_FORMAT) : null,
          sent_before: selectedDate ? selectedDate.format(DATE_FORMAT) : null,
          page_size: DEFAULT_PAGE_SIZE,
          ...props.params
        })
      setShippingData(response.data.results)
      setDataLazyLoadURL(response.data.links.next)
    } catch (error) {
      setErrorMessages(error.errorMessages)
    } finally {
      setIsRefetch(false);
    }
  }

  const fetchShippingDataMore = async () => {
    setErrorMessages(null);
    try {
      if (dataLazyLoadURL) {
        const response = await GET(dataLazyLoadURL)
        setShippingData([...shippingData, ...response.data.results])
        setDataLazyLoadURL(response.data.links.next)
      }
    } catch (error) {
      setErrorMessages(error.errorMessages)
    } finally {
      setIsRefetch(false);
    }
  }

  const fetchMoreData = () => {
    setTimeout(fetchShippingDataMore, 1000);
  };

  React.useEffect(() => {
    fetchDeliveryTransactions();
  },[selectedDate])

  return(
    <>
      <Form form={form}
        layout="inline">
        {errorMessages && 
          <Alert
            message={errorMessages}
            type="error"
            showIcon
            style={{marginBottom: "12px", textAlign: "left"}}
          />
        }
        <div style={{ width : '100%'}}>
          <Form.Item
            name={'search'}
            label={'วันที่ส่ง: '}>
            <DatePicker 
              style={{width: '100%'}} 
              placeholder={""}
              onChange={onChange} />
          </Form.Item>
        </div>
      </Form>
      <Space direction="vertical" align={'center'} style={{width: '100%', justifyContent: 'center'}}>
        <InfiniteScroll
          style={{ overflow: 'visible' }}
          dataLength={shippingData.length}
          next={fetchMoreData}
          hasMore={dataLazyLoadURL || isRefetch}
          hasChildren={shippingData.length ? true : false}
          loader={<Skeleton paragraph={{ rows: 2 }} active />}
        >
        <List
          style={{marginTop: 4}}
          dataSource={shippingData}
          renderItem={(item) => (
            <List.Item style={{border: "none", paddingTop: 4}}>
              <SenderCard
                style={{ width: '350px' }}
                readOnly={props.readOnly}
                item={item}
                onEdit={() => setSiTarget(item)}
                onDetail={() => navigate(`${props.detailURL}/${item.id}`)}/>
            </List.Item>
          )}
        />
        </InfiniteScroll>
      </Space>

      {/* {!props.readOnly && (
        <FloatColorButton
          className='float-button-mobile'
          override={SUCCESS_COLOR}
          type='primary'
          icon={<PlusOutlined/>}
          onClick={_ => setSiTarget({id : 'add'})}/>
      )} */}

      <SenderModal
        open={siTarget ? true : false}
        onClose={() => setSiTarget(null)}
        target={siTarget}
        onUpdate={() => {
          setSiTarget(null)
          fetchDeliveryTransactions()
        }}
      />
    </>
  )
}

SenderView.defaultProps = {
  params: {},
  readOnly: false,
  detailURL: null
}

SenderView.defaultProps = {
  params: propTypes.object,
  readOnly: propTypes.bool, // For disable edit and create
  detailURL: propTypes.string,
}