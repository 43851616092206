import React from "react";
import { Row, Col, Card } from 'antd';
import SummaryAnnualImportChart from "./SummaryAnnualImportChart";
import SummaryMonthlyImportChart from "./SummaryMonthlyImportChart";
import ResultReceivingBunchesTable from "./ResultReceivingBunchesTable";
import EmployeePerformanceTable from "./EmployeePerformanceTable";

export default function DashboardBunchPollenView() {

  return(
    <Row gutter={[16, 16]}>
      <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
        <Card title="จำนวนทะลายที่รับเข้า (รายเดือน)" bordered={false} style={{ height: 470 }}>
          <SummaryMonthlyImportChart/>
        </Card>
      </Col>

      <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
        <Card title="จำนวนทะลายที่รับเข้า (รายปี)" bordered={false} style={{ height: 470 }}>
          <SummaryAnnualImportChart/>
        </Card>
      </Col>

      <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
        <Card title="ผลการรับทะลาย" bordered={false}>
          <ResultReceivingBunchesTable/> 
        </Card>
      </Col>

      <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
        <Card title="ผู้ปฏิบัติงาน" bordered={false}>
          <EmployeePerformanceTable /> 
        </Card>
      </Col>
    </Row>
  )
}   