import React from "react";
import { Alert, Form, List, Skeleton, Space } from 'antd';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import { PATH } from "../routes/CustomRoutes";
import { SUCCESS_COLOR } from "../constants/color";
import SurveyModal from "../components/task/SurveyModal";
import CancelTaskModal from "../components/task/CancelTaskModal";
import InfiniteScroll from 'react-infinite-scroll-component';
import { URL_TASK } from "../constants/urls";
import { GET } from "../frameworks/HttpClient";
import TaskInflorescenceModal from "../components/task/TaskInflorescenceModal";
import QRCodeSearch from "../components/common/QRCodeSearch";
import TaskCard from "../components/task/TaskCard";
import FloatColorButton from "../components/common/FloatColorButton";
import TaskFilterModel from "../components/task/TaskFilterModal";
import * as _ from 'lodash';
import { useUserContext } from "../hooks/UserSettingProvider";


const DEFAULT_PAGE_SIZE = 5;
const SEARCH_DELAY_MILLISECONDS = 500;

export default function TaskListView() {
  const navigate = useNavigate();

  // search state
  const { user } = useUserContext();
  const [form] = Form.useForm();
  const isInitialed = React.useRef(false);
  const qrRef = React.useRef();
  const searchValue = Form.useWatch('search', form);
  const [searchParams, setSearchParams] = React.useState(null);

  // fetching state
  const [taskData, setTaskData] = React.useState([]);
  const [dataLazyLoadURL, setDataLazyLoadURL] = React.useState(null);
  const [isRefetch, setIsRefetch] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null)

  // action modal state
  const [targetSurvey, setTargetSurvey] = React.useState(null);
  const [targetCancel, setTargetCancel] = React.useState(null);
  const [targetTask, setTargetTask] = React.useState(null);

  // filter modal state
  const [filterParams, setFilterParams] = React.useState({});
  const [filterModalOpen, setFilterModalOpen] = React.useState(false);

  const fetchTaskDataSearch = async (additionalParams) => {
    // set state for loading skeleton
    setTaskData([]);
    setIsRefetch(true);
    setErrorMessages(null);
    try {
      const params = {
        search: searchParams,
        worker: _.get(user, 'id', null),
        ordering: 'plan_date,plot_code,tree_row,tree_number',
        state: 'pending',
        page_size: DEFAULT_PAGE_SIZE,
        ...filterParams,
        ...additionalParams
      };
      const response = await GET(URL_TASK.TASK, params)
      setTaskData(response.data.results)
      setDataLazyLoadURL(response.data.links.next)
    } catch (error) {
      setErrorMessages(error.errorMessages)
    } finally{
      setIsRefetch(false);
    }
  }

  const fetchTaskDataMore = async () => {
    setErrorMessages(null);
    try {
      if (dataLazyLoadURL) {
        const response = await GET(dataLazyLoadURL)
        setTaskData([...taskData, ...response.data.results])
        setDataLazyLoadURL(response.data.links.next)
      }
    } catch (error) {
      setErrorMessages(error.errorMessages)
    }
  }

  const fetchMoreData = () => {
    setTimeout(fetchTaskDataMore, 1000);
  };

  const debounceSearch = React.useCallback(
    _.debounce(setSearchParams, SEARCH_DELAY_MILLISECONDS),
    []
  );

  React.useEffect(() => {
    if (isInitialed.current) {
      debounceSearch(searchValue)
    } else {
      isInitialed.current = true;

    }
  }, [searchValue])

  React.useEffect(() => {
      fetchTaskDataSearch()
  }, [searchParams, filterParams])

  // React.useEffect(() => {
  //   if (qrRef.current) {
  //     qrRef.current.focus();
  //   }
  // }, [])

  return (
    <>
      <Space direction="vertical" align={'center'} style={{ width: '100%', justifyContent: 'center' }}>
        <Form form={form}
          layout="inline">
          {errorMessages &&
            <Alert
              message={errorMessages}
              type="error"
              showIcon
              style={{ marginBottom: "12px", textAlign: "left" }}
            />
          }
          <div style={{ width: '100%' }}>
            <Form.Item
              name={'search'}
              label={'ค้นหา : '}
            >
              <QRCodeSearch ref={qrRef}/>
            </Form.Item>
          </div>
        </Form>
        <InfiniteScroll
          style={{ overflow: 'visible' }}
          dataLength={taskData.length}
          next={fetchMoreData}
          hasMore={dataLazyLoadURL || isRefetch}
          hasChildren={taskData.length ? true : false}
          loader={<Skeleton paragraph={{ rows: 2 }} active />}
        >
          <List
            style={{ overflow: 'visible' }}
            dataSource={taskData}
            renderItem={(item) => (
              <List.Item style={{border: "none", paddingTop: 4}}>
                <TaskCard
                  style={{ width: '346px' }}
                  item={item}
                  onAction={setTargetTask}
                  onSurvey={setTargetSurvey}
                  onCancel={setTargetCancel}/>
              </List.Item>
            )}
          />
        </InfiniteScroll>
      </Space>

      <FloatColorButton
        className='float-button-mobile'
        override={SUCCESS_COLOR}
        type='primary'
        icon={<PlusOutlined/>}
        onClick={_ => navigate(PATH.ADD_INFLORESCENCES)}/>

      <FloatColorButton
        style={{ marginBottom: '95px' }}
        icon={<SearchOutlined />}
        onClick={() => setFilterModalOpen(true)}
        badge={{ color: 'blue', count: Object.values(filterParams).filter(e => e).length }}/>

      <TaskInflorescenceModal
        open={(targetTask ? true : false)}
        target={targetTask}
        onClose={() => setTargetTask(null)}
        onUpdate={() => {
          setTargetTask(null);
          fetchTaskDataSearch();
        }}
      />

      <SurveyModal
        open={(targetSurvey ? true : false)}
        target={targetSurvey}
        onClose={() => setTargetSurvey(null)}
        onUpdate={() => {
          setTargetSurvey(null);
          fetchTaskDataSearch();
        }}
      />

      <CancelTaskModal
        open={(targetCancel ? true : false)}
        target={targetCancel}
        onClose={() => setTargetCancel(null)}
        onUpdate={() => {
          setTargetCancel(null);
          fetchTaskDataSearch();
        }}
      />

      <TaskFilterModel
        open={filterModalOpen}
        filterParams={filterParams}
        onClose={() => setFilterModalOpen(false)}
        onFilter={(params) => {
          setFilterParams(params)
        }}/>
    </>
  )
}