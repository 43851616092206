import React from "react";
import propTypes from "prop-types";
import dayjs from "dayjs";
import { Row, Col, theme, Alert, Modal, Button, Space, Tooltip } from 'antd';
import { 
  EyeOutlined, 
  DeleteOutlined, 
  EditOutlined, 
  RetweetOutlined, 
  FilePdfOutlined, 
  FileExcelOutlined,
  PrinterOutlined
} from '@ant-design/icons';
import TSTable from "../common/TSTable";
import { CYAN_COLOR, SUCCESS_COLOR } from "../../constants/color";
import SeedlingModal from "./SeedlingsModal";
import SeedlingsDeleteModal from "./SeedlingsDeleteModal";
import { PATH } from "../../routes/CustomRoutes";
import { URL_COMMON, URL_PALMTREE, URL_REPORT } from "../../constants/urls";
import { GET, POST } from "../../frameworks/HttpClient";
import useDropdownItem from "../../hooks/useDropdownItem";
import { DATETIME_FORMAT, HUMAN_DATETIME_FORMAT } from "../../constants/strings";

export default function SeedlingsList(props) {
  const tableRef = React.useRef(null);
  const [ editTarget, setEditTarget ] = React.useState(null);
  const [ deleteTarget, setDeleteTarget ] = React.useState(null);
  const plantationDropdown = useDropdownItem({ url: URL_PALMTREE.PLANTATION, label: 'code', value:'code', pagination: true, params: { page_size: 999 } })
  const treeTypeDropdown = useDropdownItem({ url: URL_PALMTREE.TREE_TYPE, label: 'name', value:'name', pagination: true, params: { page_size: 999 } })
  const speciesDropdown = useDropdownItem({ url: URL_PALMTREE.SPECIES, label: 'title', value:'code', pagination: true, params: { page_size: 999 } })
  const productionTypeDropdown = useDropdownItem({ url: URL_COMMON.PRODUCTION_TYPE, label: 'name', pagination: true, params: { page_size: 999 } })
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [printing, setPrinting] = React.useState(false);

  const {
    token: { colorError, colorWarning, colorInfo  },
  } = theme.useToken();

  const toPrintQRCode = async (data) => {
    if (!data || !data.code) {
      return;
    }

    setErrorMessages(null);
    setPrinting(data);
    try {
      const response = await GET(`${URL_REPORT.PALMTREE_BARCODE_REPORT}${data.id}/`, {}, false, true)
      window.open(URL.createObjectURL(response.data));
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally { setPrinting(null) }
  }

  const handleReuseSeedlings = async(id) => {
    setErrorMessages(null);
    try {
      await POST(`${URL_PALMTREE.PALMTREE}${id}/reactivate/`)
      refreshData();
    } catch (error) {
      setErrorMessages(error.errorMessages)
    }
  }

  const refreshData = () => {
    if(tableRef.current)
      tableRef.current.fetch();
  }
  
  const columns = [
    {
      title: "Action",
      dataIndex: "is_active",
      width: 90,
      skipReport: true,
      render: (value, original) => (
        // value ? 
        <Row gutter={[4,4]} style={{margin: "auto", justifyContent: 'center'}}>
          {!props.readOnly && value && (
            <a onClick={(e) => e.stopPropagation()} >
              <Col>
                <Tooltip title="รายละเอียดต้นพันธุ์">
                  <Button 
                    style={{background: colorInfo}}
                    type={"primary"} 
                    icon={<EyeOutlined />} 
                    onClick={() => window.open(`${PATH.SEEDLINGS_DETAIL}/${original.id}`)} />
                </Tooltip>
              </Col>
            </a>
          )}
          {!props.readOnly && value && (
            <a onClick={(e) => e.stopPropagation()}>
              <Col> 
                <Tooltip title="แก้ไขต้นพันธุ์">
                  <Button 
                    style={{background: colorWarning}}
                    type={"primary"} 
                    icon={<EditOutlined />} 
                    onClick={() => setEditTarget(original)} />
                </Tooltip>
              </Col>
            </a>
          )}
          {!props.readOnly && value && (
            <a onClick={(e) => e.stopPropagation()}>
              <Col>
                <Tooltip title="ลบต้นพันธุ์">
                  <Button 
                    style={{background: colorError}}
                    type={"primary"} 
                    icon={<DeleteOutlined />} 
                    onClick={() => setDeleteTarget(original)} />
                </Tooltip>
              </Col>
            </a>
          )}
          {!props.readOnly && !value && (
            <a onClick={(e) => e.stopPropagation()}>
              <Col>
                <Button 
                  style={{background: colorWarning}}
                  type={"primary"} 
                  icon={<RetweetOutlined />} 
                  onClick={() => {
                    Modal.confirm({
                      title: `ยืนยันนำต้นพันธุ์กลับมาใช้งาน - ${original.code}`,
                      content: 'คุณต้องการนำต้นพันธุ์กลับมาใช้งานใช่หรือไม่',
                      okText: 'ยืนยัน',
                      okButtonProps: {style: { background : colorWarning}},
                      maskClosable: true,
                      onOk: () => {
                        handleReuseSeedlings(original.id)
                      },
                      cancelText: 'ยกเลิก',
                    });
                  }} />
              </Col>
            </a>
          )}
          <a onClick={(e) => e.stopPropagation()}>
            <Col>
              <Tooltip title="พิมพ์บาร์โค้ด">
                <Button 
                  style={{background: CYAN_COLOR}}
                  loading={printing ? (printing.id === original.id) : false}
                  type={"primary"} 
                  icon={<PrinterOutlined />} 
                  onClick={() => toPrintQRCode(original)} />
              </Tooltip>
            </Col>
          </a>
        </Row> 
      ),
    },
    {
      title: "สวน",
      dataIndex: "plantation_code",
      searcher: true,
      searchPlaceholder: 'Search Plantation',
      searcherOptions: [{ key: 'all', label: 'ทั้งหมด', value: '' }, ...plantationDropdown.options],
    },
    {
      title: "เลขต้น",
      dataIndex: "tree_number",
      searcher : true
    },
    {
      title: "รหัสพันธุ์",
      dataIndex: "species_code",
      searcher: true,
      searchPlaceholder: 'Search Species',
      searcherOptions: [{ key: 'all', label: 'ทั้งหมด', value: '' }, ...speciesDropdown.options],
    },
    {
      title: "รหัสต้น",
      dataIndex: "code",
      searcher : true
    },
    {
      title: "แปลง",
      dataIndex: "plot_code",
      searcher: true,
    },
    {
      title: "แถว",
      dataIndex: "row",
    },
    {
      title: "ต้น",
      dataIndex: "column",
    },
    {
      title: "รูปแบบกะลา",
      dataIndex: "kernel_type_name",
    },
    {
      title: "ประเภท",
      dataIndex: "tree_type_name",
      searcher: true,
      searchPlaceholder: 'Search TreeType',
      searcherOptions: [{ key: 'all', label: 'ทั้งหมด', value: '' }, ...treeTypeDropdown.options],
    },
    {
      title: "สถานะผลิต",
      dataIndex: "production_type_name",
      key: "production_type",
      searcher: true,
      searchPlaceholder: 'Search Production Type',
      searcherOptions: [{ key: 'all', label: 'ทั้งหมด', value: '' }, ...productionTypeDropdown.options],
    },
    {
      title: "WBS",
      dataIndex: "wbs_name",
    },
    {
      title: "Sloc",
      dataIndex: "sloc_name",
    },
    {
      title: "CcTr",
      dataIndex: "cctr_name",
    },
    {
      title: "เดือนปลูก",
      dataIndex: "plant_date",
    },
  ]

  React.useEffect(() => {
    if (props.isActive) {
      treeTypeDropdown.fetchOptions();
      plantationDropdown.fetchOptions();
      speciesDropdown.fetchOptions();
      productionTypeDropdown.fetchOptions();

      // Fetch data
      refreshData();
    }
  },[props.isActive])

  return (
    <>
      {errorMessages &&
        <Alert
          message={errorMessages}
          type="error"
          showIcon
          style={{ marginBottom: 16, textAlign: "left" }}
        />
      }
      <Space style={{ float: 'right', marginBottom: '10px' }}>
        <Row gutter={8}>
          <Col>
            <Button
              icon={<FilePdfOutlined />}
              onClick={async () => {
                await tableRef.current.exportPDF({
                  title: `รายการ${props.title}`,
                  subtitle: `ดึงข้อมูล ณ​ วันที่ ${dayjs().format(HUMAN_DATETIME_FORMAT)} น.`,
                  landscape: true,
                  fetchPageSize: 100
                })
              }}>
              PDF
            </Button>
          </Col>
          <Col>
            <Button
              icon={<FileExcelOutlined />}
              onClick={async () => {
                await tableRef.current.exportXlsx({
                  title: `รายการ${props.title}`,
                  subtitle: `ดึงข้อมูล ณ​ วันที่ ${dayjs().format(HUMAN_DATETIME_FORMAT)} น.`,
                  filename: `รายการ${props.title} - ${dayjs().format(DATETIME_FORMAT)}.xlsx`,
                  fetchPageSize: 100
                })
              }}>
              Excel
            </Button>
          </Col>
          {props.enableAdded && (
            <Col>
              <Button
                style={{background: SUCCESS_COLOR, float: 'right'}}
                type={'primary'}
                onClick={() => setEditTarget("add")}>
                  + เพิ่มต้นพันธุ์
              </Button>
            </Col>
          )}
        </Row>
      </Space>

      <TSTable
        ref={tableRef}
        columns={columns}
        style={{ marginTop : 16 }}
        rowKey={"id"}
        url={props.url}
        params={{...props.params}}
        size={"small"}
        pageSize={10}
        autoFetch={false} />

      <SeedlingModal
        open={editTarget ? true : false}
        onClose={() => setEditTarget(null)}
        target={editTarget}
        onUpdate={() => {
            setEditTarget(null)
            refreshData();
          }} />

      <SeedlingsDeleteModal
        open={deleteTarget ? true : false}
        onClose={() => setDeleteTarget(null)}
        target={deleteTarget}
        onUpdate={() => {
          setDeleteTarget(null)
          refreshData();
        }} />
    </>
  )
}

SeedlingsList.defaultProps = {
  url: URL_PALMTREE.PALMTREE,
  params: {},
  enableAdded: false,
  title: "",
  readOnly: false,
  isActive: false,
}

SeedlingsList.propTypes = {
  url: propTypes.string,
  params: propTypes.object,
  enableAdded: propTypes.bool,
  title: propTypes.string,
  readOnly: propTypes.bool,
  isActive: propTypes.bool,
}