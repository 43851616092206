import React from "react";
import { Tabs } from 'antd';
import { URL_DELIVERY } from "../constants/urls";
import ReceiverList from "../receiver/ReceiverList";
import { DELIVERY_TRANSACTION_STATE } from "../constants/strings";

export default function ReceiverView() {
  const [activeKey, setActiveKey] = React.useState("waiting");

  const onChange = (key) => {
    setActiveKey(key);
  };
  
  return(
    <Tabs
    activeKey={activeKey}
    onChange={onChange}
      items={[
        {
          key: 'waiting',
          label: 'รอรับทะลาย - ช่อดอก',
          children: 
            <ReceiverList
              url={URL_DELIVERY.DELIVERY_TRANSACTION}
              params={{
                is_active: true, 
                state : DELIVERY_TRANSACTION_STATE.SENT, 
                ordering : '-sent_date'
              }}
              isWaiting={true}
              title="รอรับทะลาย - ช่อดอก"
              isActive={activeKey === "waiting"} />,
        },
        {
          key: 'received',
          label: 'รับแล้ว',
          children: 
            <ReceiverList
              url={URL_DELIVERY.DELIVERY_TRANSACTION}
              params={{
                is_active: true, 
                state : DELIVERY_TRANSACTION_STATE.RECEIVED, 
                has_sender: true,
                ordering : '-received_date'
              }}
              isWaiting={false}
              title="รับช่อดอกแล้ว"
              isActive={activeKey === "received"} />,
        },
      ]}
    />
  )
}