import React from 'react';
import { Form, Input, Modal, Select, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import ColorButton from '../common/ColorButton';
import useDropdownItem from '../../hooks/useDropdownItem';
import { URL_INFLORESCENCE, URL_PALMTREE } from '../../constants/urls';
import { useUserContext } from '../../hooks/UserSettingProvider';
import * as _ from 'lodash';
import { INFLORESCENCE_ACTION_OPTIONS } from '../../constants/strings';
import { GET } from '../../frameworks/HttpClient';


export default function TaskFilterModel (props) {
  const { 
    open,
    onClose,
    onFilter,
    filterParams
  } = props;

  const [form] = Form.useForm();
  const { user } = useUserContext();
  const [selectGender, setSelectGender] = React.useState(null); // For validate code required
  const [genderOptions, setGenderOptions] = React.useState(null);
  const [errorMessages, setErrorMessages] = React.useState(null);

  const fetchGenderOptions = async () => {
    setErrorMessages(null)
    try {
      const response = await GET(URL_INFLORESCENCE.GENDER, { page_size: 999 })
      setGenderOptions(response.data.map((item) => { 
          return { ...item, label: item[1], value: item[0] }
        })
      )
    } catch (error) {
      setErrorMessages(error.errorMessages)
    }
  }

  const plantationDropdown = useDropdownItem({
    url: URL_PALMTREE.PLANTATION,
    label: 'code',
    pagination: true,
    // params: { worker: _.get(user, 'id', null), page_size: 999 }
  })

  const plotDropdown = useDropdownItem({
    url: URL_PALMTREE.PLOT,
    label: 'code',
    pagination: true,
    params: { worker: _.get(user, 'id', null), page_size: 999 }
  })
  const treeTypeDropdown = useDropdownItem({
    url: URL_PALMTREE.TREE_TYPE,
    label: 'name',
    pagination: true,
    params: { page_size: 999 }
  })

  const handleSubmit = () => {
    onFilter(form.getFieldsValue());
    onClose();
  }

  const handleClear = () => {
    onFilter({});
    onClose();
  }

  const filterOption = (input, option) =>
  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())

  React.useEffect(() => {
    if (open) {
      plantationDropdown.fetchOptions()
      plotDropdown.fetchOptions()
      treeTypeDropdown.fetchOptions()
      fetchGenderOptions();
      form.setFieldsValue({...filterParams});
    } else {
      form.resetFields();
    }
  }, [open])

  return (
    <Modal
     open={open}
     onCancel={onClose}
     footer={null}
     maskClosable={false}
     title={<span><SearchOutlined/> ตัวกรอง</span>}
    >
      <Form form={form}>
        <Form.Item name='gender' label='เพศ'>
          <Select
            style={{ width : '100%'}}
            initialvalues={'-'}
            autoComplete='off'
            placeholder='เลือกเพศ...'                                                    
            filterOption={filterOption}
            options={genderOptions}
            onChange={(value) => setSelectGender(value)}
            allowClear={true}
          />
        </Form.Item>
        <Form.Item name='plantation' label='สวน'>
          <Select
            showSearch
            autoComplete='off'
            optionFilterProp='code'
            placeholder='เลือก...' 
            options={plantationDropdown.options}
            loading={plantationDropdown.loading}
            allowClear={true}/>
        </Form.Item>
        <Form.Item name='plot' label='แปลง'>
          <Select
            showSearch
            autoComplete='off'
            optionFilterProp='code'
            placeholder='เลือก...'
            options={plotDropdown.options}
            loading={plotDropdown.loading}
            allowClear={true}/>
        </Form.Item>
        <Form.Item name='row' label='แถว'>
          <Input 
            placeholder='ตัวอย่าง 1 หรือ 1-9'
            onFocus={(e) => {
              if (_.get(e, 'target.select', null)) {
                e.target.select();
              }
            }}/>
        </Form.Item>
        <Form.Item name='tree_type' label='ประเภทต้นพันธุ์'>
          <Select
            showSearch
            autoComplete='off'
            optionFilterProp='name'
            placeholder='เลือก...'
            options={treeTypeDropdown.options}
            loading={treeTypeDropdown.loading}
            allowClear={true}/>
        </Form.Item>
        <Form.Item name='action' label='การดำเนินงาน'>
          <Select
            autoComplete='off'
            placeholder='เลือก...'
            options={INFLORESCENCE_ACTION_OPTIONS}
            allowClear={true}/>
        </Form.Item>
      </Form>
      <Space direction='vertical' style={{ width: '100%' }}>
        <ColorButton type='primary' block onClick={handleSubmit}>
          <SearchOutlined /> ค้นหา
        </ColorButton>
        <ColorButton block onClick={handleClear}>
          ล้างการกรอง
        </ColorButton>
      </Space>

    </Modal>
  )
}