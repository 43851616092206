import React from "react";
import { Modal, Form, theme, Alert, Spin, Select} from 'antd';
import { PATCH } from "../../frameworks/HttpClient";
import { URL_INFLORESCENCE, URL_COMMON } from "../../constants/urls";
import useDropdownItem from "../../hooks/useDropdownItem";

export default function InflorescenseEditModal(props) {
  const {open, onClose, target, onUpdate} = props;
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const productionTypeDropdown = useDropdownItem({ url: URL_COMMON.PRODUCTION_TYPE, label: 'name', pagination: true, params: { page_size: 999 } })
  const [form] = Form.useForm();

  const {
    token: { colorWarning },
  } = theme.useToken();

  const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())

  const handleSave = async() => {
    setErrorMessages(null)
    setLoading(true)
    try {
      let data = await form.validateFields();
      await PATCH(`${URL_INFLORESCENCE.INFLORESCENCE}${target.id}/`, data)
      onUpdate();
    } catch (error) {
      setErrorMessages(error.errorMessages)
    } finally {
      setLoading(false)
    }
  }

  React.useEffect(() => {
    if(open){
      form.resetFields();
      productionTypeDropdown.fetchOptions();
    }
  },[open])

  return (
    <Spin spinning={loading}>
      <Modal
        open={open}
        onCancel={onClose}
        title={`สถานะผลิต - ${target && target.code}`}
        maskClosable={false}
        okButtonProps={{style : { background : colorWarning}}}
        cancelText={"ยกเลิก"}
        okText={"ยืนยัน"}
        onOk={() => handleSave()}
      >
      {errorMessages && 
        <Alert
          message={errorMessages}
          type="error"
          showIcon
          style={{marginBottom: 16, textAlign: "left"}}
        />
      }
        <Form form={form} >
          <Form.Item
            name={'production_type'}
            label={'สถานะผลิต'}
            rules={[{ required: true }]}>
              <Select
                showSearch
                style={{ width : '100%'}}
                initialvalues={'-'}
                filterOption={filterOption}
                options={productionTypeDropdown.options}
                loading={productionTypeDropdown.loading}
              />
          </Form.Item>
        </Form>

      </Modal>
    </Spin>
  )
}