import React from 'react';
import PropTypes from 'prop-types';
import useDropdownItem from '../../hooks/useDropdownItem';
import { Select, Spin } from 'antd';
import { LoadingOutlined }  from '@ant-design/icons'


const LABEL_FIELD = 'name'

const RejectReasonSelect = React.forwardRef((props, ref) => {
  const propsWithDefaults = { ...defaultProps, ...props };

  const {
    // fetch props
    url,
    state,
    isnull,

    // select props
    // value and onchange reserved for Form.Item only 
    // do not pass at any cost
    value,
    onChange,
    ...selectProps
  } = propsWithDefaults;

  const { options, loading, fetchOptions } = useDropdownItem({
    url: url, label: LABEL_FIELD, pagination: true, 
    params: { isnull, state, page_size: 999 }
  });

  React.useImperativeHandle(ref, () => ({
    fetch: fetchOptions,
  }))

  React.useEffect(() => {
    if (url) {
      fetchOptions();
    }
  }, [state])

  return (
    <Select
      options={options}
      loading={loading}
      optionFilterProp={LABEL_FIELD}
      notFoundContent={loading ? <Spin indicator={<LoadingOutlined size='small'/>} /> : null}
      value={value}
      onChange={onChange}
      {...selectProps}/>    
  );
})

const defaultProps = {
  // fetch props
  url: '',
  state: null,
  isnull: null,

  // select props
  value: '',
  onChange: () => null,
};

RejectReasonSelect.propTypes = {
  // fetch props
  url: PropTypes.string.isRequired,
  state: PropTypes.string,
  isnull: PropTypes.bool,

  // select props
  value: PropTypes.any,
  onChange: PropTypes.func,
}

export default RejectReasonSelect;
