import React from 'react'
import propTypes from "prop-types";
import { Alert, Form, Input, Modal, Row, Col } from 'antd';
import { URL_AUTH } from '../../constants/urls';
import { POST } from '../../frameworks/HttpClient';
import { useWindowDimensionContext } from '../../hooks/WindowDimensionProvider';
import ColorButton from '../common/ColorButton';

export default function ChangePasswordModal(props) {
	const [form] = Form.useForm();
	const [errorMessages, setErrorMessages] = React.useState(null);
	const { lessThanOrEqual } = useWindowDimensionContext();
	const isOnMobile = lessThanOrEqual("sm");

	const formItemLayout = {
		labelCol: {
			xs: { span: 24 },
			sm: { span: 8 },
		},
		wrapperCol: {
			xs: { span: 24 },
			sm: { span: 16 },
		},
	};

	const handlePasswordChange = async () => {
		setErrorMessages(null);
		try {
			const data = await form.validateFields();
			await POST(props.url, data);
			props.onClose();
		} catch (error) {
			if (error.errorMessages) 
				setErrorMessages(error.errorMessages);
		}
	}

	React.useEffect(() => {
		if (props.open && form) {
			form.resetFields();
			setErrorMessages(null);
		}
	}, [props.open])

	return (
		isOnMobile ? 
		// Mobile
		<Modal 
			getContainer={false}
			title={"เปลี่ยนรหัสผ่าน"}
			okText={"ตกลง"}
			cancelText={"ยกเลิก"}
			maskClosable={false}
			open={props.open}
			onCancel={props.onClose}
			onOk={handlePasswordChange}
			footer={() => {
				return(
				<Row style={{ textAlign : "center"}} gutter={16}>
					<Col span={12}>
						<ColorButton block style={{ height : 45, marginTop : 15}} onClick={props.onClose}>
							ยกเลิก
						</ColorButton>
					</Col>
					<Col span={12}>
						<ColorButton block style={{ height : 45, marginTop : 15}} type='primary' onClick={handlePasswordChange} >
							ยืนยัน
						</ColorButton>
					</Col>
				</Row>
				)
			}}>
				{errorMessages && 
					<Alert
						message={errorMessages}
						type="error"
						showIcon
						style={{marginBottom: "12px", textAlign: "left"}}
					/>
				}
				<Form 
					{...formItemLayout}
					form={form}
					onFinish={handlePasswordChange}>
					<Form.Item 
						label={"รหัสผ่านใหม่"}
						name={"new_password"} 
						rules={[
						{
							required: true,
							message: 'กรุณากรอกรหัสผ่านใหม่!',
						},
        		]}>
						<Input.Password />
					</Form.Item>

					<Form.Item 
						label={"ยืนยันรหัสผ่านใหม่"}
						name={"confirm_password"} 
						dependencies={['new_password']}
						hasFeedback
						rules={[
							{
								required: true,
								message: 'กรุณายืนยันรหัสผ่านใหม่!',
							},
							({ getFieldValue }) => ({
								validator(_, value) {
									if (!value || getFieldValue('new_password') === value) {
										return Promise.resolve();
									}
									return Promise.reject(new Error('รหัสผ่านทั้งสองไม่ตรงกัน กรุณาตรวจสอบใหม่อีกครั้ง!'));
								},
							}),
						]}>
						<Input.Password />
					</Form.Item>
				</Form>
		</Modal> :
		// PC
		<Modal 
			getContainer={false}
			title={"เปลี่ยนรหัสผ่าน"}
			okText={"ตกลง"}
			cancelText={"ยกเลิก"}
			maskClosable={false}
			open={props.open}
			onCancel={props.onClose}
			onOk={handlePasswordChange}>
				{errorMessages && 
					<Alert
						message={errorMessages}
						type="error"
						showIcon
						style={{marginBottom: "12px", textAlign: "left"}}
					/>
				}
				<Form 
					{...formItemLayout}
					form={form}
					onFinish={handlePasswordChange}>
					<Form.Item 
						label={"รหัสผ่านใหม่"}
						name={"new_password"} 
						rules={[
						{
							required: true,
							message: 'กรุณากรอกรหัสผ่านใหม่!',
						},
        		]}>
						<Input.Password />
					</Form.Item>

					<Form.Item 
						label={"ยืนยันรหัสผ่านใหม่"}
						name={"confirm_password"} 
						dependencies={['new_password']}
						hasFeedback
						rules={[
							{
								required: true,
								message: 'กรุณายืนยันรหัสผ่านใหม่!',
							},
							({ getFieldValue }) => ({
								validator(_, value) {
									if (!value || getFieldValue('new_password') === value) {
										return Promise.resolve();
									}
									return Promise.reject(new Error('รหัสผ่านทั้งสองไม่ตรงกัน กรุณาตรวจสอบใหม่อีกครั้ง!'));
								},
							}),
						]}>
						<Input.Password />
					</Form.Item>
				</Form>
		</Modal>
	)
}

ChangePasswordModal.defaultProps = {
	open: false,
	url: URL_AUTH.CHANGE_PASSWORD,
	onClose: () => {}
}

ChangePasswordModal.propTypes = {
	open: propTypes.bool,
	url: propTypes.string,
	onClose: propTypes.func,
}