import { FloatButton, ConfigProvider, theme } from 'antd';
import React from 'react';
import propTypes from "prop-types";
import { PRIMARY_COLOR } from '../../constants/color';


export default function FloatColorButton ({ children, override, ...props }) {
  const { token } = theme.useToken();
  const overrideColor = token[override] || override;
  const modifiedTheme = {
    token: {...token},
    components: {
      FloatButton: { colorPrimary: overrideColor ? overrideColor : PRIMARY_COLOR, algorithm: true }
    }
  };

  return (
    <ConfigProvider theme={modifiedTheme}>
      { !children || (typeof(children) == 'function' && !children()) ? <FloatButton {...props}/> : <FloatButton.Group {...props}>{children}</FloatButton.Group> }
    </ConfigProvider>
  )
}


FloatColorButton.defaultProps = {
  children: () => null,
  override: '',
}

FloatColorButton.propTypes = {
  // children: propTypes.func,
  override: propTypes.string,
}