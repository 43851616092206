export const PRIMARY_COLOR = "#005AAB";
export const PRIMARY_LIGHT_COLOR = "#005AAB1A";
export const SECONDARY_COLOR = "#e5e5e5";
export const WHITE_COLOR_BG = "#FFFFFF";
export const BLACK_COLOR = "#000000";
export const BG_LAYOUT_COLOR = "#EDEDED";
export const CYAN_COLOR = "#13c2c2";
export const GRAY_COLOR = "#A9A9A9";
export const ORANGE_COLOR = "#fa8c16";
export const SUCCESS_COLOR = "#7DB94A";
export const MALE_COLOR = "#4096FF";
export const FEMALE_COLOR = "#FF898B";
export const QUEER_COLOR = "#D663FF";
export const TITLE_LINE_CHART_COLOR = "#229954";
export const DASHBOARD_GENDER_MALE_COLOR = "rgba(81, 175, 255, 0.6)"
export const DASHBOARD_GENDER_FEMALE_COLOR = "rgba(255, 69, 106, 0.6)"
export const CHART_COLORS = [
  "rgba(93, 173, 226, 0.6)", // blue
  "rgba(236, 112, 99, 0.6)", // red
  "rgba(243, 156, 18, 0.6)", // orange
  "rgba(175, 122, 197, 0.6)", // purple
  "rgba(88, 214, 141, 0.6)", // green
  "rgba(240, 98, 146, 0.6)", // pink
  "rgba(241, 196, 15, 0.6)", // yellow
  "rgba(189, 195, 199, 0.6)", // gray
  "rgba(93, 109, 126, 0.6)", // drak gray
]