export const URL_AUTH = {
  API_TOKEN_AUTH: '/api/users/api-token-auth/',
  SIGN_OUT: '/api/users/sign-out/',
  CHANGE_PASSWORD: '/api/users/change-password/',
}

export const URL_COMMON = {
  PRODUCTION_TYPE: "/api/common/production-type/",
  TASK_ACTIVATE_DATE_SUBMIT: "/api/common/task-activate-date/submit/",
  TASK_ACTIVATE_DATE_SURVEY: "/api/common/task-activate-date/survey/",
  API_HISTORIES: "/api/common/api-histories/",
}

export const URL_PALMTREE = {
  PALMTREE: "/api/palmtree/palmtree/",
  GENDER: "/api/palmtree/tree-gender/",
  PLANTATION: "/api/palmtree/plantation/",
  SPECIES: "/api/palmtree/species/",
  PLOT: "/api/palmtree/plot/",
  KERNEL_TYPE: "/api/palmtree/kerneltype/",
  TREE_TYPE: "/api/palmtree/treetype/",
  WBS_NUMBER: "/api/palmtree/wbs-number/",
  SLOC_NUMBER: "/api/palmtree/sloc-number/",
  CCTR_NUMBER: "/api/palmtree/cctr-number/",
}

export const URL_INFLORESCENCE = {
  INFLORESCENCE: "/api/inflorescence/inflorescence/",
  GENDER: "/api/inflorescence/inflorescence-gender/",
  SUMMARY : '/api/inflorescence/inflorescence-summary/',
  TOTAL_PRODUCTION : '/api/inflorescence/inflorescence-total-production/',
  TASK : '/api/inflorescence/inflorescence-task/',
  PALMBUNCH_GRADE: '/api/inflorescence/palmbunch-grade',
  REJECT_REASON: '/api/inflorescence/inflorescence-reject-reason/',
  POLLINATION_RECIPE : '/api/inflorescence/pollination-recipe/',
  POLLINATION_PLAN : '/api/inflorescence/pollination-plan/',
  INFLORESCENCE_DELIVERY: '/api/delivery/inflorescence-delivery/',
  POLLINATION_STOCK_VALIDATE: '/api/inflorescence/pollination-stock-validate/',
}

export const URL_STOCK = {
  STOCK: "/api/stock/stock/",
  STOCK_TRANSACTION: "/api/stock/stock-transaction/",
}

export const URL_TASK = {
  TASK: "/api/task/task/",
  WORKER_TASKA_COUNT: "/api/task/worker-tasks-count/",
  'inflorescencetask' : '/api/inflorescence/inflorescence-task/'
}

export const URL_DELIVERY = {
  DELIVERY : "/api/delivery/delivery/",
  DELIVERY_TRANSACTION : "/api/delivery/delivery-transaction/",
  INFLORESCENCE_DELIVERY : "/api/delivery/inflorescence-delivery/",
  INFLORESCENCE_INFLORESCENCE: '/api/delivery/inflorescence-delivery/inflorescence/', 
}

export const URL_REPORT = {
  INFLORESCENCE_TAG_REPORT: "/api/report/inflorescence-tag-report/",
  STOCK_TAG_REPORT: "/api/report/stock-tag-report/",
  POLLINATION_TAG_REPORT: "/api/report/pollination-tag-report/",
  POLLINATION_TAG_LIST_REPORT: "/api/report/pollination-tag-list-report/",
  DELIVERY_TRANSACTION_COVER_REPORT: "/api/report/delivery-transaction-cover-report/",
  PALMTREE_BARCODE_REPORT: "/api/report/palmtree-barcode-report/",
  PALMTREE_REPORT: "/api/report/palmtree-report/", //1(1)
  BUNCH_SOURCE_REPORT: "/api/report/bunch-source-report/", //1(2)
  PALMTREE_SUMMARY_REPORT: "/api/report/palmtree-summary-report/", //1(3)
  PALMTREE_BUNCH_SUMMARY_REPORT: "/api/report/palmtree-bunch-summary-report/", //1(4)
  STOCK_REPORT: "/api/report/stock-report/", //2(1)
  STOCK_SUMMARY_REPORT: "/api/report/stock-summary-report/", //2(2)
  POLLINATIONPLAN_REPORT: "/api/report/pollinationplan-report/", //3(1)
  POLLEN_POLLINATION_REPORT: "/api/report/pollen-pollination-report/", //3(2)
  INFLORESCENCE_TASK_ACTION_REPORT: "/api/report/inflorescence-task-action-report/", //3(3)
  INFLORESCENCE_COVER_TASK_REPORT: "/api/report/inflorescence-cover-task-report/", //3(4)
  INFLORESCENCE_COVERING_SUMMARY_REPORT: "/api/report/inflorescence-covering-summary-report/", //3(5)
  INFLORESCENCE_COVERING_REPORT: "/api/report/inflorescence-covering-report/", //3(6)
  INFLORESCENCE_CUT_TASK_REPORT: "/api/report/inflorescence-cut-task-report/", //3(7)
  BUNCH_DELIVERY_PREPARING_REPORT: "/api/report/bunch-delivery-preparing-report/", //3(8)
  BUNCH_REJECT_REPORT: "/api/report/bunch-reject-report/", //3(9)
}

export const URL_DASHBOARD = {
  PALMTREE_DASHBOARD: "/api/dashboard/palmtree-dashboard/",
  STOCK_DASHBOARD: "/api/dashboard/stock-dashboard/",
  INFLORESCENCE_DASHBOARD: "/api/dashboard/inflorescence-dashboard/",
  PALMBUNCH_DASHBOARD: "/api/dashboard/palmbunch-dashboard/",
  DELIVERY_MONTHLY_DASHBOARD: "/api/dashboard/delivery-dashboard/monthly/",
  DELIVERY_YEARLY_DASHBOARD: "/api/dashboard/delivery-dashboard/yearly/",
  BUNCH_DELIVERY_RESULT_DASHBOARD: "/api/dashboard/palmbunch-delivery-result-dashboard/",
  WORKER_DASHBOARD: "/api/dashboard/worker-dashboard/",
}
