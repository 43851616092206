import React from "react";
import dayjs from "dayjs";
import { Form, theme, Select, Row, Col, Button, Space, Tooltip} from 'antd';
import { URL_PALMTREE, URL_STOCK } from "../constants/urls";
import { 
  EyeOutlined, 
  PlusCircleOutlined, 
  MinusCircleOutlined, 
  FilePdfOutlined, 
  FileExcelOutlined 
} from '@ant-design/icons';
import TSTable from "../components/common/TSTable";
import { SUCCESS_COLOR } from "../constants/color";
import StockModal from "../components/stock/StockModal";
import StockInOutModal from "../components/stock/StockInOutModal";
import { DATETIME_FORMAT, HUMAN_DATETIME_FORMAT, STOCK_ACTION, STOCK_IS_EMPTY_OPTIONS } from "../constants/strings";
import StockHistoryModal from "../components/stock/StockHistoryModal";
import useDropdownItem from "../hooks/useDropdownItem";
import * as _ from 'lodash'

export default function StockListView(props) {
  const [target, setTarget] = React.useState(null);
  const [isEmpty, setIsEmpty] = React.useState("false")
  const [importTarget, setImportTarget] = React.useState(null)
  const [exportTarget, setExportTarget] = React.useState(null)
  const [historyTarget, setHistoryTarget] = React.useState(null)
  const plantationDropdown = useDropdownItem({ url: URL_PALMTREE.PLANTATION, label: 'code', value:'code', pagination: true, params: { page_size: 999 } })

  const tableRef = React.useRef(null);

  const {
    token: { colorInfo, colorSuccess, colorError },
  } = theme.useToken();

  const refreshData = () => {
    if(tableRef.current)
      tableRef.current.fetch();
  }

  const filterOption = (input, option) =>
  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())

  const columns = [
    {
      title: "Action",
      dataIndex: "plantation_code",
      width : 130,
      skipReport: true,
      render: (_, original) => (
        <Row gutter = {8}>
          <a onClick={(e) => e.stopPropagation()} >
            <Col>
              <Tooltip title="ประวัติสต๊อกเกสร">
                <Button 
                  style={{background: colorInfo}}
                  type={"primary"} 
                  icon={<EyeOutlined />} 
                  onClick={() => setHistoryTarget(original)}/>
              </Tooltip>
            </Col>
          </a>
          <a onClick={(e) => e.stopPropagation()}>
            <Col>
              <Tooltip title="รับเข้าสต๊อก">
                <Button 
                  style={{background: colorSuccess}}
                  type={"primary"} 
                  icon={<PlusCircleOutlined />} 
                  onClick={() => setImportTarget(original)} />
              </Tooltip>
            </Col>
          </a>
          <a onClick={(e) => e.stopPropagation()}>
            <Col> 
              <Tooltip title="ตัดออกจากสต๊อก">
                <Button 
                  style={{background: colorError}}
                  type={"primary"} 
                  icon={<MinusCircleOutlined />} 
                  onClick={() => setExportTarget(original)} />
              </Tooltip>
            </Col>
          </a>
        </Row>
      )
    },
    {
      title: "สวน",
      dataIndex: "plantation_code",
      searcher: true,
      searchPlaceholder: 'Search Plantation',
      searcherOptions: [{ key: 'all', label: 'ทั้งหมด', value: '' }, ...plantationDropdown.options],
    },
    {
      title: "เลขต้น",
      dataIndex: "tree_number",
      searcher : true
    },
    {
      title: "รหัสพันธ์ุ",
      dataIndex: "species_name",
      searcher: true,
    },
    {
      title: "แปลง",
      dataIndex: "plot_code",
      searcher: true,
    },
    {
      title: "แถว",
      dataIndex: "tree_row",
    },
    {
      title: "ต้น",
      dataIndex: "tree_column",
    },
    {
      title: "ทางใบ",
      dataIndex: "inflorescence_branch",
    },
    {
      title: "รหัสช่อ",
      dataIndex: "inflorescence_code",
      searcher: true,
    },
    {
      title: "Lot ผลิต",
      dataIndex: "lot_number",
      searcher: true,
    },
    {
      title: "เกสรทั้งหมด (หลอด)",
      dataIndex: "total_imported_amount",
      width : 100,
      render : (value , original) => `${value + _.get(original, 'initial_amount', 0)}`,
      renderReport : (value , original) => `${value + _.get(original, 'initial_amount', 0)}`
    },
    {
      title: "เกสรคงหลือ (หลอด)",
      dataIndex: "current_amount",
      width : 100,
    },
  ]

  React.useEffect(() => {
    plantationDropdown.fetchOptions();
  },[])

  React.useEffect(() => {
      refreshData();
  },[isEmpty])

  return(
    <div>
      <Form
        autoComplete="off">
        <Space style={{ float: 'right' }}>
          <Row gutter={8}>
            <Col>
              <Button
                icon={<FilePdfOutlined />}
                onClick={async () => {
                  await tableRef.current.exportPDF({
                    title: `รายการสต๊อกเกสร`,
                    subtitle: `ดึงข้อมูล ณ​ วันที่ ${dayjs().format(HUMAN_DATETIME_FORMAT)} น.`,
                    landscape: true
                  })
                }}>
                PDF
              </Button>
            </Col>
            <Col>
              <Button
                icon={<FileExcelOutlined />}
                onClick={async () => {
                  await tableRef.current.exportXlsx({
                    title: `รายการสต๊อกเกสร`,
                    subtitle: `ดึงข้อมูล ณ​ วันที่ ${dayjs().format(HUMAN_DATETIME_FORMAT)} น.`,
                    filename: `รายการสต๊อกเกสร - ${dayjs().format(DATETIME_FORMAT)}.xlsx`
                  })
                }}>
                Excel
              </Button>
            </Col>
            <Col>
              <Button 
                style={{background: SUCCESS_COLOR, float : 'right'}}
                type={'primary'} 
                onClick={() => setTarget('add')}>
                  + เพิ่มสต๊อกเกสร
              </Button>
            </Col>
          </Row>
        </Space>

        <Row gutter={[16, 16]} wrap>
          <Col lg={8} md={12} xs={24}>
            <Form.Item
              name={'is_empty'}
              label={'สถานะ'} >
              <Select
                showSearch
                style={{ width: 200 }}
                placeholder={"ประเภท"}
                defaultValue={"false"}
                autoComplete='off'
                filterOption={filterOption}
                options={[{ label: 'ทั้งหมด', value: "" }, ...STOCK_IS_EMPTY_OPTIONS]}
                onSelect={(e) => setIsEmpty(e)}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <TSTable
        ref={tableRef}
        columns={columns}
        rowKey={"id"}
        url={URL_STOCK.STOCK}
        params={{...props.params, is_empty : isEmpty }}
        pageSize={10} />

      <StockModal
        open={target ? true : false}
        onClose={() => setTarget(null)}
        target={target}
        onUpdate={() => {
          setTarget(null)
          refreshData()
        }}
      />

      <StockInOutModal
        open={importTarget ? true : false}
        onClose={() => setImportTarget(null)}
        target={importTarget}
        action={STOCK_ACTION.IMPORT}
        onUpdate={() => {
          setImportTarget(null)
          refreshData()
        }}
      />

      <StockInOutModal
        open={exportTarget ? true : false}
        onClose={() => setExportTarget(null)}
        target={exportTarget}
        action={STOCK_ACTION.EXPORT}
        onUpdate={() => {
          setExportTarget(null)
          refreshData()
        }}
      />

      <StockHistoryModal
        open={historyTarget}
        onClose={() => setHistoryTarget(null)}
        target={historyTarget}
      />
    </div>
  )
}