import React from "react";
import { Routes, Route } from "react-router-dom";
import AddInflorescences from "../screens/AddInflorescences";
import LoginScreen from "../screens/LoginScreen";
import MenuScreen from "../screens/MenuScreen";
import NotFoundScreen from "../screens/NotFoundScreen";
import ReceiverDetailScreen from "../screens/ReceiverDetailScreen";
import SeedlingsDetailScreen from "../screens/SeedlingsDetailScreen";
import SenderDetailScreen from "../screens/SenderDetailScreen";
import TaskListView from "../views/TaskListView";
import PrivateOutlet from "./PrivateOutlet";
import ReceiverMobileScreen from "../screens/ReceiverMobileScreen";

export const PATH = {
  LOGIN: "/login",
  MAIN: "/",
  TASK: "/task",
  SENDER: "/sender",
  RECEIVER_MOBILE: "/receiver_mobile",
  PROFILE: "/profile",
  ADD_INFLORESCENCES :"/add-inflorescences",
  SENDER_DETAIL :"/sender-detail",
  DASHBOARD :"/dashboard",
  SEEDLINGS :"/seedlings",
  SEEDLINGS_DETAIL :"/seedlings-detail",
  INFLORESCENCE : "/inflorescence",
  ORDER : "/order",
  RECEIVER : "/receiver",
  RECEIVER_DETAIL : '/receiver-detail',
  RECEIVER_DETAIL_MOBILE : '/receiver-detail-mobile',
  FIELD_SENDER: "/field-sender",
  STOCK : "/stock",
  BARCODE : "/barcode",
  REPORT : "/report",
}

export default function CustomRoutes() {
  return (
    <Routes>
      <Route exact path={PATH.LOGIN} element={<LoginScreen/>} />

      <Route path={PATH.MAIN} element={<PrivateOutlet/>}>
        <Route index element={<MenuScreen />} />
        <Route path={`${PATH.TASK}/:id`} element={<TaskListView />}/>
        <Route path={`${PATH.ADD_INFLORESCENCES}/`} element={<AddInflorescences />}/>
        <Route path={`${PATH.SEEDLINGS_DETAIL}/:id`} element={<SeedlingsDetailScreen />}/>
        <Route path={`${PATH.RECEIVER_DETAIL}/:id`} element={<ReceiverDetailScreen />}/>
        <Route path={`${PATH.RECEIVER_DETAIL_MOBILE}/:id`} element={<ReceiverMobileScreen />}/>
        <Route path={`${PATH.SENDER_DETAIL}/:id`} element={<SenderDetailScreen/>}/>
        <Route path="/:menu/*" element={<MenuScreen />} />
      </Route>
      <Route path="*" element={<NotFoundScreen />} />
    </Routes>
  )
}