import React from 'react';
import PropTypes from 'prop-types';
import { Badge, Card, theme, Space, Typography } from 'antd';
import * as _ from 'lodash';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { DATE_FORMAT, HUMAN_DATE_FORMAT, INFLORESCENCE_GENDER_TH, TASK_ACTION_MAP, TASK_COLOR, TASK_TYPE } from '../../constants/strings';
import { dateToString } from '../../frameworks/Util';


const { Text } = Typography;

const WARNING_DAY = 0;

const TaskCard = React.forwardRef((props, ref) => {
  const propsWithDefaults = { ...defaultProps, ...props };
  const { item, style, onAction, onSurvey, onCancel, ...cardProps } = propsWithDefaults

  const { token: { colorError, colorInfo, colorSuccess } } = theme.useToken();

  const isAlmostDue = (dateString) => {
    const date = dayjs(dateString);
    return date.subtract(WARNING_DAY, 'day').isSame(dayjs(), 'day')
  }

  const isLate = (dateString) => {
    const extenedDayjs = dayjs.extend(isSameOrBefore)
    const date = extenedDayjs(dateString);
    return date.isBefore(dayjs(), 'day')
  }

  const badgeColor = (date) => {
    if (isAlmostDue(date)) {
      return 'orange'
    } else if (isLate(date)) {
      return 'red'
    }
    return null
  }

  const cardColor = (date) => {
    if (isAlmostDue(date)) {
      return TASK_COLOR.WARNING
    } else if (isLate(date)) {
      return TASK_COLOR.LATE
    }
    return null
  }

  const renderAction = (item) => {
    const taskType = _.get(item, 'content_type', null);
    const taskAction = _.get(item, 'detail.action', null);
    const actions = _.get(TASK_ACTION_MAP, taskType);
    return _.get(actions, taskAction, 'unknown');
  }

  const getDateRange = (dateString) => {
    const date = dayjs(dateString);
    return Math.abs(date.diff(dayjs(dateToString(dayjs())), 'day', true))
  }

  const renderDetail = (item) => {
    const taskType = _.get(item, 'content_type', null);
    switch (taskType) {
      case TASK_TYPE.INFLORESCENCE:
        return <Text style={{fontSize: 15.5}}>
          รหัสช่อ : {_.get(item, 'detail.inflorescence_code', '-')} - {_.get(item, 'detail.inflorescence_branch', '-')} ({getDateRange(dayjs(_.get(item, 'detail.inflorescence_created')).format(DATE_FORMAT))} วัน) {((item && item.detail && item.detail.inflorescence_gender) ? INFLORESCENCE_GENDER_TH[item.detail.inflorescence_gender] : "")}
        </Text>
      default:
        return null
    }
  }

  return (
    <Badge.Ribbon
      text={dayjs(_.get(item, 'plan_date', '')).format(HUMAN_DATE_FORMAT)}
      color={badgeColor(_.get(item, 'plan_date', ''))}
    >
      <Card
        style={{ ...style, backgroundColor: cardColor(_.get(item, 'plan_date', '')) }} {...cardProps}
        actions={[
          <a style={{ color: colorInfo }} onClick={() => onSurvey(item)}>สำรวจ</a>,
          <a style={{ color: colorSuccess }} onClick={() => onAction(item)}>{renderAction(item)}</a>,
          <a style={{ color: colorError }} onClick={() => onCancel(item)}>เสีย</a>,
        ]}
      >
        <Space direction='vertical'>
          <Text strong>{_.get(item, 'title', 'Unknown')}</Text>
          {renderDetail(item)}
          { _.get(item, 'note', null) && <Text style={{fontSize: 15.5}}>หมายเหตุ : {_.get(item, 'note', '-')}</Text>}
        </Space>
      </Card>
    </Badge.Ribbon>
  )
})

const defaultProps = {
  item: {},
  style: {},
  onAction: () => null,
  onSurvey: () => null,
  onCancel: () => null,
}

TaskCard.propTypes = {
  item: PropTypes.object,
  style: PropTypes.object,
  onAction: PropTypes.func,
  onSurvey: PropTypes.func,
  onCancel: PropTypes.func,
}

export default TaskCard;