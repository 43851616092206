import React from "react";
import { Tabs } from 'antd';
import { URL_INFLORESCENCE } from "../constants/urls";
import OrderList from "../components/order/OrderList";
import { INFLORESCENCE_GENDER } from "../constants/strings";

export default function OrderView() {
  const [activeKey, setActiveKey] = React.useState("waiting");

  const onChange = (key) => {
    setActiveKey(key);
  };
  
  return(
    <Tabs
      activeKey={activeKey}
      onChange={onChange}
      items={[
        {
          key: 'waiting',
          label: 'ช่อรอผสม',
          children: 
            <OrderList 
              url={URL_INFLORESCENCE.INFLORESCENCE}
              params={{pollinated: false, gender : INFLORESCENCE_GENDER.FEMALE}}
              pollinated={false}
              title="ช่อรอผสม"
              isActive={activeKey === "waiting"}
            />,
        },
        {
          key: 'pollinated',
          label: 'ช่อผสมแล้ว',
          children: 
            <OrderList
              url={URL_INFLORESCENCE.INFLORESCENCE}
              params={{pollinated: true, gender : INFLORESCENCE_GENDER.FEMALE}}
              pollinated={true}
              title="ช่อผสมแล้ว"
              isActive={activeKey === "pollinated"}
            />,
        },
      ]}
    />
  )
}