import React from "react";
import TSTable from "../common/TSTable";
import { Table, DatePicker, Typography } from 'antd';
import { URL_DASHBOARD, URL_INFLORESCENCE } from "../../constants/urls";
import dayjs from 'dayjs';
import { GET } from "../../frameworks/HttpClient";
import * as _ from "lodash";

const defaultChild = [
  { title: <center>A</center>, dataIndex: ""},
  { title: <center>B</center>, dataIndex: ""},
  { title: <center>C</center>, dataIndex: ""},
  { title: <center>D</center>, dataIndex: ""},
]

export default function ResultReceivingBunchesTable(props) {  
  const { Text } = Typography;
  const [selectedDate, setSelectedDate] = React.useState(dayjs());
  const [child, setChild] = React.useState(defaultChild);
  const [data, setData] = React.useState([]);
  const tableRef = React.useRef(null);

  const renderGradeChild = (grades) => {
    let childX = []
    grades.map(item => {
      childX.push({
        title: <center>{item}</center>, 
        dataIndex: "grades",
        render: (value) => {
          const found = value.find((element) => element.grade_name === item);
          return found ? found.amount : 0
        }
      })
    })

    setChild(childX)
  }

  const renderSummary = () => {
    return (
      <Table.Summary fixed>
        <Table.Summary.Row >
          <Table.Summary.Cell index={0}> รวม</Table.Summary.Cell>
          <Table.Summary.Cell index={1}></Table.Summary.Cell>
          <Table.Summary.Cell index={2}></Table.Summary.Cell>

          {child.map((item, index) => {
            return (<Table.Summary.Cell index={index + 3}>0</Table.Summary.Cell>)
          })}
        </Table.Summary.Row>
      </Table.Summary>
    )
  }

  const columns = [
    {
      title: "ลูกผสม",
      dataIndex: "species_name",
    },
    {
      title: "สวน",
      dataIndex: "plantation_code",

    },
    {
      title: "จำนวนทะลาย",
      dataIndex: "total_amount",
      
    },
    {
      title: "เกรด",
      dataIndex: "",
      children: child
    },
  ]

  const onChange = (date, _) => {
    setSelectedDate(date)
  };

  // Fetch Grades
  const fetchPalmGrades = async () => {
    try {
      const response = await GET(URL_INFLORESCENCE.PALMBUNCH_GRADE);
      let results = []
      response.data.results.map(item => {
        results.push(item.grade)
      })
      // Render grade column
      renderGradeChild(results)
    } catch(err) {
      console.error(err)
    }
  }

  // Fetch data from server
  const fetchData = async () => {
    try {
      const response = await GET(
        URL_DASHBOARD.BUNCH_DELIVERY_RESULT_DASHBOARD, 
        {
          ordering: "species_name", 
          month: selectedDate ? selectedDate.format("M") : null, 
          year: selectedDate ? selectedDate.format("YYYY") : null,
        })
      setData(response.data.results);
    } catch (error) {
      console.log(error.errorMessages)
    }
  }

  React.useEffect(() => {
    fetchPalmGrades()
  }, [])

  React.useEffect(() => {
    if (selectedDate)
      fetchData()
    
  }, [selectedDate])

  return (
    <div>
      <div style={{ marginBottom : 8}}>
        <Text>เดือน : </Text>
        <DatePicker 
          onChange={onChange} 
          picker="month" 
          placeholder={"เลือก..."} 
          allowClear={false}
          defaultValue={dayjs()}/>
      </div>
    
      <TSTable
        ref={tableRef}
        columns={columns}
        rowKey={"id"}
        data={data}
        size={"small"}
        hidePagination={true}
        pageSize={100}
        summary={() => renderSummary()}
      />
    </div>
  )
}