import React from "react";
import propTypes from "prop-types";
import { Tabs } from 'antd';
import SeedlingsList from "../components/seedlings/SeedlingsList";
import { URL_PALMTREE } from "../constants/urls";

export default function SeedlingsView(props) {
  const [activeKey, setActiveKey] = React.useState("active");

  const onChange = (key) => {
    setActiveKey(key);
  };

  return(
    <Tabs
      activeKey={activeKey}
      onChange={onChange}
      items={[
        {
          key: 'active',
          label: 'ต้นพันธ์ุที่ใช้งาน',
          children: 
            <SeedlingsList 
              title='ต้นพันธ์ุที่ใช้งาน'
              url={URL_PALMTREE.PALMTREE}
              params={{is_active: true}}
              enableAdded={true && !props.readOnly}
              readOnly={props.readOnly}
              isActive={activeKey === "active"}/>,
        },
        {
          key: 'cancel',
          label: 'ต้นพันธ์ุที่ยกเลิก',
          children: 
            <SeedlingsList
              title='ต้นพันธ์ุที่ยกเลิก'
              url={URL_PALMTREE.PALMTREE}
              params={{is_active: false}}
              readOnly={props.readOnly}
              isActive={activeKey === "cancel"}/>,
        },
      ]}
    />
  )
}

SeedlingsView.defaultProps = {
  readOnly: false,
}

SeedlingsView.propTypes = {
  readOnly: propTypes.bool,
}