import React from 'react'
import propTypes from "prop-types";
import { Modal, Row, Col } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { PATH } from '../../routes/CustomRoutes';
import { URL_AUTH } from '../../constants/urls';
import { GET } from '../../frameworks/HttpClient';
import { useUserContext } from '../../hooks/UserSettingProvider';
import Cookies from 'js-cookie';
import { useWindowDimensionContext } from '../../hooks/WindowDimensionProvider';
import ColorButton from '../common/ColorButton';

export default function LogoutModal(props) {
	const navigate = useNavigate();
	const user = useUserContext();
	const { lessThanOrEqual } = useWindowDimensionContext();
	const isOnMobile = lessThanOrEqual("sm");

	const handleLogout = async () => {
		try {
			await GET(URL_AUTH.SIGN_OUT)
		} catch (error) {
			// Do nothings
		}finally {
			user.onLogout();
			navigate(PATH.LOGIN);
			props.onClose();
			Cookies.remove('id');
		}
	}

	return (
		isOnMobile ? 
		// Mobile
		<Modal 
			title={"ออกจากระบบ"}
			okText={"ตกลง"}
			cancelText={"ยกเลิก"}
			centered={false}
			icon={<LogoutOutlined />}
			maskClosable={false}
			open={props.open}
			onOk={handleLogout}
			onCancel={props.onClose}
			footer={() => {
				return(
				<Row style={{ textAlign : "center"}} gutter={16}>
					<Col span={12}>
						<ColorButton block style={{ height : 45, marginTop : 15}} onClick={props.onClose}>
							ยกเลิก
						</ColorButton>
					</Col>
					<Col span={12}>
						<ColorButton block style={{ height : 45, marginTop : 15}} type='primary' onClick={handleLogout} >
							ยืนยัน
						</ColorButton>
					</Col>
				</Row>
				)
			}}>
				<div>{"คุณต้องการที่จะออกจากระบบใช่หรือไม่ ?"}</div>
		</Modal>
		: 
		// PC
		<Modal 
			title={"ออกจากระบบ"}
			okText={"ตกลง"}
			maskClosable={false}
			cancelText={"ยกเลิก"}
			centered={false}
			icon={<LogoutOutlined />}
			open={props.open}
			onOk={handleLogout}
			onCancel={props.onClose}>
				<div>{"คุณต้องการที่จะออกจากระบบใช่หรือไม่ ?"}</div>
		</Modal>
	)
}

LogoutModal.defaultProps = {
	open: false,
	onClose: () => {},
	isLiff: false,
}

LogoutModal.propTypes = {
	open: propTypes.bool,
	onClose: propTypes.func,
	isLiff: propTypes.bool,
}