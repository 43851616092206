import React from 'react';
import { ConfigProvider, Layout, Spin } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { BrowserRouter as Router } from 'react-router-dom';
import { PRIMARY_COLOR, SUCCESS_COLOR, WHITE_COLOR_BG } from './constants/color';
import CustomRoutes from './routes/CustomRoutes';
import WindowDimensionProvider from './hooks/WindowDimensionProvider';
import UserSettingProvider from './hooks/UserSettingProvider';
import {Chart} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

function App() {

  return (
    <ConfigProvider
      theme={{
        token: {
          colorSuccess: SUCCESS_COLOR,
          colorPrimary: PRIMARY_COLOR,
          colorBgLayout: WHITE_COLOR_BG,
          fontSize: 16,
        },
        components: {
          Modal: {
            wireframe: true
          }
        }
      }}>
      <WindowDimensionProvider>
        <UserSettingProvider>
          <React.Suspense fallback={
            <Layout>
              <Content>
                <Spin size="large" tip="Loading..."/>
              </Content>
            </Layout>
          }>
            <Router>
              <div className="App">
                <CustomRoutes />
              </div>
            </Router>
          </React.Suspense>
        </UserSettingProvider>
      </WindowDimensionProvider>
    </ConfigProvider>
  );
}

export default App;

Chart.register(ChartDataLabels);
