import React from "react";
import { Col, Row, Input, Modal, Form, Radio, Alert } from 'antd';
import { URL_DELIVERY, URL_INFLORESCENCE } from "../constants/urls";
import { PATCH } from "../frameworks/HttpClient";
import * as _ from 'lodash'
import QRCodeSelect from "../components/common/QRCodeSelect";

export default function EvaluatePollenModal(props) {
  const { open, onClose, target, shippingData, onUpdate } = props;
  const isNoTarget = (target && target === 'add')
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [form] = Form.useForm();
  const isQCPassedWatch = Form.useWatch('is_qc_passed', form);

  const handleSave = async() => {
    setErrorMessages(null)
    try {
      let data = await form.validateFields();
      await PATCH(`${URL_DELIVERY.INFLORESCENCE_INFLORESCENCE}${form.getFieldValue('Inflorescence')}/`, data)
      onUpdate();
    } catch (error) {
      setErrorMessages(error.errorMessages)
    }
  }

  React.useEffect(() => {
    if(open){
      form.resetFields();
    } else {
      setErrorMessages(null)
    }
  },[open])

  React.useEffect(() => {
    if(!isNoTarget){
      form.setFieldValue('Inflorescence', target && target.inflorescence)
    }
  },[isNoTarget])

  return (
    <Modal
      open={open}
      onCancel={onClose}
      title={'รับช่อ'}
      okText={'รับเข้า'}
      cancelText={'ยกเลิก'}
      width={600}
      maskClosable={false}
      onOk={handleSave} >
      {errorMessages && 
        <Alert
          message={errorMessages}
          type="error"
          showIcon
          style={{marginBottom: "12px", textAlign: "left"}}
        />
      }

      <Form
        form={form}
        layout="vertical" >
        <Row gutter ={16}>
          <Col md={12} sm={24} xs={24}>
            <Form.Item
              name="Inflorescence"
              label="รหัสช่อ"
              rules={[{ required: true}]}>
              <QRCodeSelect
                fetchUrl={URL_INFLORESCENCE.INFLORESCENCE}
                fetchParams={{ 
                  is_active: true,
                  delivery_transaction : shippingData ? shippingData.id : "none",
                  page_size: 999
                }}
                optionLabel={'code'}
                placeholder='เลือกช่อ'
                disabled={isNoTarget ? false : true}/>
            </Form.Item>
          </Col>

          <Col md={24} sm={24} xs={24} style={{ padding : 12}}>
            <Form.Item
              name='is_qc_passed'
              label='ผลการประเมิน'
              rules={[{ required: true }]}>
            <Radio.Group>
              <Radio value={true}>ผ่าน</Radio>
              <Radio value={false}>ไม่ผ่าน</Radio>
            </Radio.Group>
            </Form.Item>
          </Col>

          <Col md={24} sm={24} xs={24}>
            <Form.Item
              name='note'
              label='หมายเหตุ'
              rules={[{ required: isQCPassedWatch ? false : true }]}>
              <Input.TextArea />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}