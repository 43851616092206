import React from "react";
import { Form, Collapse, Row, Col, Alert, DatePicker, Input, Select, Button } from 'antd';
import { HistoryOutlined } from '@ant-design/icons';
import { INFLORESCENCE_GENDER_OPTIONS, MONTH_FORMAT } from "../constants/strings";
import { URL_PALMTREE, URL_REPORT, URL_STOCK } from "../constants/urls";
import { GET } from "../frameworks/HttpClient";
import { CYAN_COLOR } from "../constants/color";
import useDropdownItem from "../hooks/useDropdownItem";
import BarcodeHistoryModal from "../components/barcode/BarcodeHistoryModal";

export default function BarcodeView() {
    const [errorMessages, setErrorMessages] = React.useState(null);
    const [openHistory, setOpenHistory] = React.useState(false);
    const [ formBunch ] = Form.useForm();
    const [ formPollen ] = Form.useForm();
    const [loadingBunchBarcode, setLoadingBunchBarcode] = React.useState(false);
    const [loadingPollenBarcode, setLoadingPollenBarcode] = React.useState(false);
    const plantationDropdown = useDropdownItem({ url: URL_PALMTREE.PLANTATION, label: 'code', value:'id', pagination: true, params: { page_size: 999 } })
    const stockDropdown = useDropdownItem({ url: URL_STOCK.STOCK, label : 'lot_number', value : 'id', pagination: true, params: { page_size: 999, is_empty: false } })

  const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())

    const items = [
      {
        key: '1',
        label: 'บาร์โค้ดช่อดอก',
        extra: 
          <Button
            icon={<HistoryOutlined />}
            onClick={(event) => {
              event.stopPropagation();
              setOpenHistory(true);
            }}>
          </Button>,
        children: 
        <Form form={formBunch} layout='vertical'>
          <Row gutter={16}>
            <Col lg={5} md={24} sm={24} xs={24}>
              <Form.Item
                name='month'
                label='เดือน'
                rules={[{ required: true }]} >
                <DatePicker style={{ width : '100%'}} picker="month" format={MONTH_FORMAT} placeholder={'เลือกเดือน'}/>
              </Form.Item>
            </Col>

            <Col lg={5} md={24} sm={24} xs={24}>
              <Form.Item
                name='plantation'
                label='สวน'
                rules={[{ required: true }]}>
              <Select
                showSearch
                style={{ width : '100%'}}
                initialvalues={'-'}
                filterOption={filterOption}
                options={plantationDropdown.options}
                loading={plantationDropdown.loading}
                placeholder={'เลือกสวน'} />
              </Form.Item>
            </Col>

            <Col lg={4} md={24} sm={24} xs={24}>
              <Form.Item
                name='gender'
                label='เพศช่อดอก'
                rules={[{ required: true }]}>
              <Select
                style={{ width : '100%'}}
                options={INFLORESCENCE_GENDER_OPTIONS}
                placeholder={'เลือกเพศ'} />
              </Form.Item>
            </Col>

            <Col lg={5} md={24} sm={24} xs={24}>
              <Form.Item
                name='start'
                placeholder='ตั้งแต่...'
                label={'ตั้งแต่เลขที่'}
                rules={[{ required: true }]} >
                <Input 
                  autoComplete="off" 
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                    
                }} />
              </Form.Item>
            </Col>

            <Col lg={5} md={24} sm={24} xs={24}>
              <Form.Item
                name='end'
                placeholder='จนถึง...'
                label={'จนถึงเลขที่'}
                rules={[{ required: true }]} >
                <Input 
                  autoComplete="off" 
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                }} />
              </Form.Item>
            </Col>
            <Col lg ={24} md={24} sm={24} xs={24}>
              <Button
                onClick={() => handleBarcodePrint('bunch')} 
                type="primary" 
                style={{ float : 'right', background: CYAN_COLOR}} 
                loading={loadingBunchBarcode}>
                  พิมพ์บาร์โค้ด
              </Button>
            </Col>
          </Row>
        </Form>
      },
      {
        key: '2',
        label: 'บาร์โค้ด Lot เกสร',
        children: 
        <Form form={formPollen} layout='vertical'>
          <Row gutter={16}>
            <Col lg={16} md={24} sm={24} xs={24}>
              <Form.Item
                name='stock'
                label='Lot เกสร'
                rules={[{ required: true }]}>
              <Select
                showSearch
                style={{ width : '100%'}}
                initialvalues={'-'}
                filterOption={filterOption}
                options={stockDropdown.options}
                loading={stockDropdown.loading}
                placeholder={'เลือก lot'} />
              </Form.Item>
            </Col>

            <Col lg ={8} md={24} sm={24} xs={24}>
              <Form.Item
                name='count'
                label='จำนวน'
                rules={[{ required: true }]}>
                <Input 
                  autoComplete="off" 
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                  }}}
                  placeholder={'จำนวน'} />
              </Form.Item>
            </Col>
            <Col lg ={24} md={24} sm={24} xs={24}>
              <Button 
                onClick={() => handleBarcodePrint('pollen')}  
                type="primary" 
                style={{ float : 'right', background: CYAN_COLOR }} 
                loading={loadingPollenBarcode}>
                  พิมพ์บาร์โค้ด
              </Button>
            </Col>
          </Row>
        </Form>
      },
    ];

    const handleBarcodePrint = async(type) =>{
      if(type === 'bunch'){
        setLoadingBunchBarcode(true)
      } else if (type === 'pollen'){
        setLoadingPollenBarcode(true)
      }
      setErrorMessages(null)

      try {
        if(type === 'bunch'){
          let params = await formBunch.validateFields();
          params['month'] = params['month'].format(MONTH_FORMAT)
          const response = await GET(URL_REPORT.INFLORESCENCE_TAG_REPORT, params, false, true)
          window.open(URL.createObjectURL(response.data));
        } else if(type === 'pollen'){
          let params = await formPollen.validateFields();
          const stockId = params['stock']
          delete params["stock"]
          const response = await GET(`${URL_REPORT.STOCK_TAG_REPORT}${stockId}`, params, false, true)
          window.open(URL.createObjectURL(response.data));
        }
      } catch (error) {
        setErrorMessages(error.errorMessages)
      } finally {
        
        if(type === 'bunch'){
          setLoadingBunchBarcode(false)
        } else if (type === 'pollen'){
          setLoadingPollenBarcode(false)
        }
      }
    }

    React.useEffect(() => {
        stockDropdown.fetchOptions();
        plantationDropdown.fetchOptions();
    },[])

  return (
    <>
    <Row>
      <Col lg ={24} md={24} sm={24} xs={24}>
        {errorMessages && 
          <Alert
            message={errorMessages}
            type="error"
            showIcon
            style={{marginBottom: 16, textAlign: "left"}}
          />
        }
          <Collapse 
            items={items}
            defaultActiveKey={['1', '2']}/>
        </Col>
      </Row>

      <BarcodeHistoryModal 
        open={openHistory}
        onClose={() => setOpenHistory(false)}
        plantations={plantationDropdown.options}/>
    </>
  )
}