import React from "react";
import { theme, Card, Row, Col, Avatar, Space } from 'antd';
import ColorButton from "../components/common/ColorButton";
import LogoutModal from "../components/user/LogoutModal";
import { useUserContext } from "../hooks/UserSettingProvider";
import { UserOutlined, UnlockOutlined, LogoutOutlined } from '@ant-design/icons';
import { Typography } from "antd";
import { PRIMARY_COLOR } from "../constants/color";
import ChangePasswordModal from "../components/user/ChangePasswordModal";

export default function ProfileView() {
  const [openLogoutModal, setOpenLogoutModal] = React.useState(false);
  const [openChangePassword, setOpenChangePassword] = React.useState(false);
  const { Text, Title } = Typography;
  const {
    token: { colorError,  },
  } = theme.useToken();
  const { user } = useUserContext();

  return(
    <>
    <div style={{ textAlign : 'center'}}>
      <Card>
        <div style={{marginTop : 20, height : 400}}>
        <Avatar size={86} icon={<UserOutlined />} style={{backgroundColor : PRIMARY_COLOR}} />
        <Title level={3} style={{ marginTop : 20}}>โปรไฟล์</Title>
        <Row>
          <Col md={8} sm={8} xs={8} style={{ textAlign : "left",  marginTop : 20}}>
            <Text strong={true} style={{fontSize: 20, color : PRIMARY_COLOR }} icon={<UserOutlined />}> ชื่อ - สกุล : </Text>
          </Col>
          <Col md={16} sm={16} xs={16} style={{ textAlign : "right",  marginTop : 20}}>
            <Text style={{ fontSize: 20 }}>{user.full_name} </Text> 
          </Col>
        </Row>
        <Row>
          <Col md={8} sm={8} xs={8} style={{ textAlign : "left",  marginTop : 20}}>
            <Text strong={true} style={{ fontSize: 20, color : PRIMARY_COLOR}}>สวน : </Text>
          </Col>
          <Col md={16} sm={16} xs={16} style={{ textAlign : "right",  marginTop : 20}}>
            <Text style={{ fontSize: 20 }}>{user.plantation_code} </Text> 
          </Col>
        </Row>
          <ColorButton block style={{ height : 45, width : "100%", marginTop : 40}} onClick={() => setOpenChangePassword(true)} icon={<UnlockOutlined />}>
            เปลี่ยนรหัสผ่าน
          </ColorButton>
          <ColorButton block style={{ height : 45, width : "100%", marginTop : 15}} type='primary' onClick={() => setOpenLogoutModal(true)} override={colorError} icon={<LogoutOutlined />}>
            ออกจากระบบ
          </ColorButton>
        </div>
      </Card>
      
    </div>
      <LogoutModal
        open={openLogoutModal}
        onClose={() => setOpenLogoutModal(false)}
      />

      <ChangePasswordModal
        open={openChangePassword}
        onClose={() => setOpenChangePassword(false)}
      />
    </>

  )
}