import React from 'react';
import HeaderMobile from '../components/common/HeaderMobile';
import { Layout, Form, Input, List, Divider, Alert, Spin} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { Content, } from 'antd/es/layout/layout';
import { useParams } from 'react-router-dom';
import ColorButton from '../components/common/ColorButton';
import AddProductSiModal from '../components/shipping/AddProductSiModal';
import DeleteProductSiModal from '../components/shipping/DeleteProductSiModal';
import { GET } from '../frameworks/HttpClient';
import { URL_DELIVERY } from '../constants/urls';
import * as _ from 'lodash';
import { EXPORT_TYPE_TH } from '../constants/strings';
import FloatColorButton from '../components/common/FloatColorButton';
import { SUCCESS_COLOR } from '../constants/color';
import SendProductSiModal from '../components/shipping/SendProductSiModal';

export default function SenderDetailScreen() {
  const { id } = useParams();
  const [form] = Form.useForm();
  const [productData, setProductData] = React.useState([]);
  const [deleteTarget, setDeleteTarget] = React.useState(null);
  const [openProductSiModal, setOpenProductSiModal] = React.useState(false);
  const [openConfirmModal, setOpenConfirmModal] = React.useState(false);
  const [shippingData, setShippingData] = React.useState(null);
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  const formItemLayout = {
    labelCol: {
      xs: {
        span: 8,
      },
      sm: {
        span: 8,
      },
    },
    wrapperCol: {
      xs: {
        span: 14,
      },
      sm: {
        span: 14,
      },
    },
  };

  const fetchShippingData = async() => {
    setErrorMessages(null)
    setLoading(true)
    try {
      const response = await GET(`${URL_DELIVERY.DELIVERY_TRANSACTION}${id}/`)
      setShippingData(response.data)
      form.setFieldsValue(response.data)
    } catch (error) {
      setErrorMessages(error.errorMessages); 
    } finally {
      setLoading(false)
    }
  }

  const fetchProductTransactionsData = async() => {
    setErrorMessages(null)
    try {
      const response = await GET(
        URL_DELIVERY.INFLORESCENCE_DELIVERY, 
        {delivery_transaction: id, page_size: 999})
      setProductData(response.data.results)
    } catch (error) {
      setErrorMessages(error.errorMessages)
    }
  }

  React.useEffect(() => {
    fetchShippingData();
    fetchProductTransactionsData();
  },[])

  return(
    <Spin spinning={loading}>
    <Layout style={{ minHeight: '100vh' }}>
      <HeaderMobile 
        title={`ใบส่งของ - ${_.get(shippingData, 'code', null) ? _.get(shippingData, 'code', null) : '-'}`} 
        className='navbar-top' 
        back={true}
        right={
          <ColorButton type={'primary'} onClick={() => setOpenConfirmModal(true)}>
            ส่ง
          </ColorButton>
        }
        />
        <Content
          style={{
          margin: 24,
          marginTop: 60,
          marginBottom: 60,
          minHeight: 280,
          }}>
            {errorMessages && 
            <Alert
              style={{marginBottom: "12px", textAlign: "left"}}
              message={errorMessages}
              type="error"
              showIcon 
            />}
          <Form
            {...formItemLayout}
            variant="filled"
            form={form}
            style={{
              maxWidth: 400,
            }}
          >
            <Form.Item
              name={'sent_date'}
              label={'วันที่ส่ง :'}
              style={{
                maxWidth: '100%',
              }}
            >
              <Input disabled={true}/>
            </Form.Item>
              
            <Form.Item
              name={'vehicle'}
              label={'ทะเบียนรถ :'}
              style={{
                maxWidth: '100%',
              }}
            >
              <Input disabled={true}/>
            </Form.Item>
          </Form>
          <Divider>{`${EXPORT_TYPE_TH[_.get(shippingData, 'export_type', '-')]}`}</Divider>
          <List
            itemLayout="horizontal"
            dataSource={productData}
            renderItem={(item) => (
              <List.Item>
                {_.get(item , 'inflorescence_species_name', '-')}
                <List.Item.Meta
                  title={
                    <>
                    {_.get(item , 'inflorescence_code', '-')} - {_.get(item , 'worker_reject_reason', null) ? 'ไม่ผ่าน' : 'ผ่าน'}
                    <a style={{ float : 'right', color : 'gray', fontWeight: 'normal'}} onClick={() => {setDeleteTarget(item)}}>ลบ</a>
                    </>
                    }
                  description={
                    <>
                      หมายเหตุ : {_.get(item , 'worker_note', null) ? _.get(item , 'worker_note', '-') : '-'}
                    </>
                  }
                />
              </List.Item>
            )}
          />
        </Content>

        <FloatColorButton
        className='float-button-mobile'
        override={SUCCESS_COLOR}
        type='primary'
        icon={<PlusOutlined/>}
        onClick={_ => setOpenProductSiModal(true)}/>

        <AddProductSiModal
          open={openProductSiModal}
          onClose={() => 
            setOpenProductSiModal(false)
          }
          onUpdate={() => {
            setOpenProductSiModal(false)
            fetchProductTransactionsData();
          }}
          exportType = {_.get(shippingData, 'export_type', null)}
        />

        <DeleteProductSiModal
          open={deleteTarget ? true : false}
          onClose={() => { setDeleteTarget(null) }}
          target={deleteTarget}
          onUpdate={() => { 
            setDeleteTarget(null)
            fetchProductTransactionsData();
          }}
        />

        <SendProductSiModal
          open={openConfirmModal}
          onClose={() => 
            setOpenConfirmModal(false)
          }
          target={shippingData}
        />

    </Layout>
    </Spin>
  )
}