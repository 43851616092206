import React from "react";
import { Modal, Form, theme, Select, Row, Col, Input, Alert } from 'antd';
import { SUCCESS_COLOR } from "../../constants/color";
import { GET, POST } from "../../frameworks/HttpClient";
import { URL_INFLORESCENCE, URL_STOCK } from "../../constants/urls";
import { INFLORESCENCE_GENDER } from "../../constants/strings";

export default function StockModal(props){
  const { target, open, onClose, onUpdate } = props;
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [inflorescenceOptions, setInflorescenceOptions] = React.useState(null);
  const [form] = Form.useForm();
  const isCreate = target && target.id === 'add'

  const {
    token: { colorWarning, colorSuccess, colorInfo },
  } = theme.useToken();

  const filterOption = (input, option) =>
  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())

  const handleSave = async() => {
    setErrorMessages(null)
    try {
      let data = await form.validateFields();
      await POST(URL_STOCK.STOCK, data)
      onUpdate();
    } catch (error) {
      setErrorMessages(error.errorMessages)
    }
  }

  const fetchInflorescenceOptions = async() => {
    setErrorMessages(null)
    try {
      const response = await GET(URL_INFLORESCENCE.INFLORESCENCE, { stocked : false, gender : INFLORESCENCE_GENDER.MALE })
      setInflorescenceOptions(response.data.results.map((item) => { 
        return { ...item, label: item.code, value: item.id }
      }))
    } catch (error) {
      setErrorMessages(error.errorMessages)
    }
  }

  React.useEffect(() => {
    if(open){
      form.resetFields()
      fetchInflorescenceOptions();
    } else {

    }
  },[open])

  return(
    <Modal
      open={open}
      okText={"เพิ่ม"}
      cancelText={"ยกเลิก"}
      onOk={handleSave}
      okButtonProps={{style : { background : SUCCESS_COLOR}}}
      onCancel={onClose}
      maskClosable={false}
      title={"เพิ่มสต๊อกเกสร"}
    >
      {errorMessages && 
        <Alert
          message={errorMessages}
          type="error"
          showIcon
          style={{marginBottom: "12px", textAlign: "left"}}
        />
      }
      <Form
        form={form}
        layout="vertical"
        autoComplete="off">
        <Row gutter ={16}>
          <Col lg={24} md={24} sm={24} xs={24}>
            <Form.Item
              name={'inflorescence'}
              label={'รหัสช่อ'}
              rules={[{ required: true }]}
            >
              <Select
                showSearch
                initialvalues={'-'}
                filterOption={filterOption}
                options={inflorescenceOptions}
                allowClear={'-'}
              />
            </Form.Item>
          </Col>
          <Col lg={12} md={12} sm={12} xs={12}>
            <Form.Item
              name={'lot_number'}
              label={'Lot ผลิต'}
              rules={[{ required: true }]}
            >
              <Input style={{width : '100%'}}/>
            </Form.Item>
          </Col>
          <Col lg={12} md={12} sm={12} xs={12}>
            <Form.Item
              name={'initial_amount'}
              label={'ปริมาณเกสร (หลอด)'}
              rules={[{ required: true }]}
            >
              <Input style={{width : '100%'}}/>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}