import React, { useRef } from "react";
import { Row, Col, theme, Spin, Layout, Image, Carousel, Typography, Card, DatePicker, Statistic, Space, Alert, List } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from "react-router-dom";
import { FEMALE_COLOR, MALE_COLOR, QUEER_COLOR, WHITE_COLOR_BG } from "../constants/color";
import { GET } from '../frameworks/HttpClient';
import HeaderScreen from "../components/common/HeaderScreen";
import _ from 'lodash'
import { URL_INFLORESCENCE, URL_PALMTREE } from "../constants/urls";
import TSTable from "../components/common/TSTable";
import { INFLORESCENCE_GENDER, INFLORESCENCE_GENDER_TH, INFLORESCENCE_STATE_TH, TREE_GENDER_TH } from "../constants/strings";
import ColorButton from "../components/common/ColorButton";
import InflorescenceStateModal from "../components/inflorescence/inflorescenceStateModal";
import { formatComma } from "../frameworks/Util";

export default function SeedlingsDetailScreen() {
  const [ loading, setLoading ] = React.useState(false);
  const params = useParams();
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [seedlingsData, setSeedlingsData] = React.useState(null);
  const [summaryData, setSummaryData] = React.useState(null);
  const [totalProductionData, setTotalProductionData] = React.useState(null);
  const [rangeDateFilterSum, setRangeDateFilterSum] = React.useState(null)
  const [rangeDateFilterTotal, setRangeDateFilterTotal] = React.useState(null)
  const [stateTarget, setStateTarget] = React.useState(null);
  const { Text, Title } = Typography;
  const tableRef = useRef(null);
  const navigate = useNavigate();

  const genderColor = (gender) => {
    switch (gender) {
      case INFLORESCENCE_GENDER.FEMALE: return FEMALE_COLOR
      case INFLORESCENCE_GENDER.MALE: return MALE_COLOR
      case INFLORESCENCE_GENDER.QUEER: return QUEER_COLOR
    }
  }

  const {
    token: { colorInfo  },
  } = theme.useToken();

  const fetchSeedlingsData = async() => {
    setErrorMessages(null)
    setLoading(true)
    try {
      const response = await GET(`${URL_PALMTREE.PALMTREE}${params.id}/`)
      setSeedlingsData(response.data)
    } catch (error) {
      setErrorMessages(error.errorMessage)
    } finally {
      setLoading(false)
    }
  }

  const fetchInflorescenceSum = async() => {
    setErrorMessages(null)
    setLoading(true)
    try {
      const response = await GET(`${URL_INFLORESCENCE.SUMMARY}`, {tree : params.id, created_start : rangeDateFilterSum ? rangeDateFilterSum[0] : null, created_end : rangeDateFilterSum ? rangeDateFilterSum[1] : null})
      setSummaryData(response.data)
    } catch (error) {
      setErrorMessages(error.errorMessage)
    } finally {
      setLoading(false)
    }
  }

  const fetchInflorescenceTotalProduction = async() => {
    setErrorMessages(null)
    setLoading(true)
    try {
      const response = await GET(`${URL_INFLORESCENCE.TOTAL_PRODUCTION}`, {tree : params.id, start : rangeDateFilterTotal ? rangeDateFilterTotal[0] : null, end : rangeDateFilterTotal ? rangeDateFilterTotal[1] : null })
      setTotalProductionData(response.data)
    } catch (error) {
      setErrorMessages(error.errorMessage)
    } finally {
      setLoading(false)
    }
  }

  const imageData = seedlingsData && seedlingsData.images.map((item) => {
      return(
        <div key={item.id}>
          <Image
            style={{ aspect : 3/2, height : 600 }}
            src={item.image}
          />
          </div>
      )
    })

  const StatisticStyle = {
    width : 100
  }

  const columns = [
    {
      title: "รหัสช่อ",
      dataIndex: "code",
      searcher : true
    },
    {
      title: "ทางใบ",
      dataIndex: "branch",
    },
    {
      title: "เพศ",
      dataIndex: "gender",
      render : (value) => <>{INFLORESCENCE_GENDER_TH[value]}</>
    },
    {
      title: "สถานะ",
      dataIndex: "state",
      render : (value) => <>{INFLORESCENCE_STATE_TH[value]}</>
    },
    {
      title: "Action",
      dataIndex: "",
      render: (_, original) => (
        <Row justify={'center'}>
          <Col>
            <ColorButton type={"primary"} icon={<EyeOutlined />} onClick={() => setStateTarget(original)} override={colorInfo}/>
          </Col>
        </Row>
      )
    },
  ]

  React.useEffect(() => {
    fetchSeedlingsData();
    fetchInflorescenceSum();
    fetchInflorescenceTotalProduction();
  },[])

  React.useEffect(() => {
    if(rangeDateFilterSum){
      fetchInflorescenceSum();
    }
  },[rangeDateFilterSum])

  React.useEffect(() => {
    if(rangeDateFilterTotal){
      fetchInflorescenceTotalProduction();
    }
  },[rangeDateFilterTotal])

  return(
    <Spin spinning={loading}>
      <HeaderScreen
        onClick={() => window.close()} 
        title={`รหัสต้น - ${_.get(seedlingsData, 'code', '')}`}
        />
        <Layout style={{ background : WHITE_COLOR_BG, padding : 24, paddingLeft : 30}}>
          {errorMessages && 
            <Alert
              message={errorMessages}
              type="error"
              showIcon
              style={{marginBottom: 16, textAlign: "left"}}
            />
          }
          <Row>
            <Col xl={8} lg={24} md={24} sm={24} xs={24}>
              <Title 
                style={{marginTop: 0}} 
                level={3}>
                  {`${seedlingsData ? TREE_GENDER_TH[seedlingsData.gender] : ""} - ${_.get(seedlingsData, 'tree_type_name', "")}`}
              </Title>
              <Title level={4} style={{ marginTop : 0}}>รูปแบบกะลา: {_.get(seedlingsData, 'kernel_type_name', '')}</Title>
              <div className='section-wrapper' style={{ textAlign: "center", background : '#000'}}  >
                {seedlingsData && 
                <Image.PreviewGroup>
                <Carousel 
                  style={{ background : '#000', marginTop: 16, height : 640}} 
                  arrows 
                  infinite={false}>
                  {imageData}
                </Carousel>
                </Image.PreviewGroup>
                }
              </div>
                
            </Col>

            <Col xl={16} lg={24} md={24} sm={24} xs={24} style={{padding : 16}}>
              {/* Card for infloresence summary */}
              <Card style={{marginBottom : 16}}>
                <Title level={4} style={{ marginTop: 0}}>ช่อดอก</Title>

                <div style={{ marginBottom : 24}}>
                  <Text>วันที่พบช่อใหม่ : </Text>
                  <DatePicker.RangePicker style={{ marginLeft : 8}} onChange={(_,dateStrings) => setRangeDateFilterSum(dateStrings)}/>
                </div>

                <List
                  grid={{ gutter: 6 }}
                  dataSource={summaryData ? summaryData : []}
                  itemLayout="vertical"
                  renderItem={(item) => 
                  <List.Item>
                    <Statistic 
                      style={StatisticStyle}
                      title={
                        <Title level={5} style={{ textAlign: 'center', marginTop: 8 }}>
                          {INFLORESCENCE_GENDER_TH[item.gender]} {
                            item.gender === 'female' && item.grade === 'None' ? '(เสีย)' :
                            item.gender === 'male' ? "" :
                            item.grade ? `(${item.grade})` : "(เสีย)"
                          }
                        </Title>
                      } 
                      value={item.count}
                      valueStyle={{ color: genderColor(item.gender), fontSize : 45, textAlign: 'center' }}
                    />
                  </List.Item>
                  }
                />
              </Card>

              {/* Card for product summary */}
              <Card>
                <Title level={4} style={{ marginTop: 0}}> ผลผลิตสะสม </Title>
                <div style={{ marginBottom : 24}}>
                  <Text>วันที่พบช่อใหม่ : </Text>
                  <DatePicker.RangePicker style={{ marginLeft : 8}} onChange={(_,dateStrings) => setRangeDateFilterTotal(dateStrings)}/>
                </div>
                <Space.Compact block>
                  {/* Female */}
                  <Card
                    style={{
                      width: "100%",
                      background: '#FFD6D659',
                      borderTopRightRadius: 0,
                      borderBottomRightRadius: 0,
                    }}>
                    <Title level={4} style={{ marginTop: 0, marginBottom : 16}}>ทะลาย</Title>
                    <div style={{ marginBottom : 8}}>
                      <Text style={{ color : "#A9A9A9"}}>จำนวนดี : </Text>
                      <Text>{totalProductionData && totalProductionData.accepted_bunch} ทะลาย</Text>
                    </div>
                    <div style={{ marginBottom : 8}}>
                      <Text style={{ color : "#A9A9A9"}}>จำนวนเสีย : </Text>
                      <Text>{totalProductionData && totalProductionData.rejected_bunch} ทะลาย</Text>
                    </div>
                    <div style={{ marginBottom : 8}}>
                      <Text style={{ color : "#A9A9A9"}}>นน. ต่อทะลาย (เฉลี่ย) : </Text>
                    <Text>{totalProductionData && formatComma(totalProductionData.avg_bunch_weight)} กิโลกรัม</Text>
                    </div>
                    <div style={{ marginBottom : 8}}>
                      <Text style={{ color : "#A9A9A9"}}>นน. ทะลาย (รวม) : </Text>
                    <Text>{totalProductionData && formatComma(totalProductionData.total_bunch_weight)} กิโลกรัม </Text>
                    </div>
                  </Card>
                  {/* Male */}
                  <Card
                    style={{
                      width: "100%",
                      background: '#4096FF29',
                      borderTopLeftRadius: 0,
                      borderBottomLeftRadius: 0,
                    }}>
                    <Title level={4} style={{ marginTop: 0, marginBottom : 16}}>ช่อผู้</Title>
                    <div style={{ marginBottom : 8}}>
                      <Text style={{ color : "#A9A9A9"}}>จำนวนดี : </Text>
                      <Text>{totalProductionData && totalProductionData.accepted_pollen} ช่อ</Text>
                    </div>
                    <div style={{ marginBottom : 8}}>
                      <Text style={{ color : "#A9A9A9"}}>จำนวนเสีย : </Text>
                      <Text>{totalProductionData && totalProductionData.rejected_pollen} ช่อ</Text>
                    </div>
                    <div style={{ marginBottom : 8}}>
                      <Text style={{ color : "#A9A9A9"}}>จน. เกสรต่อช่อ (เฉลี่ย) : </Text>
                      <Text>{totalProductionData && totalProductionData.avg_pollen} หลอด</Text>
                    </div>
                    <div style={{ marginBottom : 8}}>
                      <Text style={{ color : "#A9A9A9"}}>จน. เกสร (รวม) : </Text>
                      <Text>{totalProductionData && totalProductionData.total_pollen} หลอด</Text>
                    </div>
                  </Card>
                </Space.Compact>
              </Card>

              {/* Table for current infloerescence */}
              <TSTable
                ref={tableRef}
                columns={columns}
                style={{ marginTop : 16 }}
                rowKey={"id"}
                url={URL_INFLORESCENCE.INFLORESCENCE}
                params={{ tree : params.id  }}
                size={"small"}
                pageSize={5}
                paginationOptions={{
                  position: ["bottomCenter"],
                  showTotal: total => `Total ${total} rows`,
                  showSizeChanger: false,
                }}
              />
            </Col>
          </Row>
        </Layout>

        <InflorescenceStateModal
          open={stateTarget ? true : false}
          onClose={() => setStateTarget(null)}
          target={stateTarget}
        />
    </Spin>
  )
}