import React from 'react';
import PropTypes from 'prop-types';
import { Input, Space } from 'antd';
import * as _ from 'lodash';
import ScanQRButton from './ScanQRCodeButton';


const SEARCH_DELAY_MILLISECONDS = 800;

const QRCodeSearch = React.forwardRef((props, ref) => {
  const propsWithDefaults = { ...defaultProps, ...props };

  const {
    // value and onchange reserved for Form.Item only 
    // do not pass at any cost
    style,
    value,
    disabled,
    onChange,
    onPressEnter,
    ...inputProps
  } = propsWithDefaults;

  const inputRef = React.useRef(); 

  const onFocus = () => {
    if (inputRef.current) {
      inputRef.current.select()
    }
  }

  React.useImperativeHandle(ref, () => ({
    focus: onFocus,
  }));

  return (
    <Space.Compact style={{ width: '100%' }}>
      <Input
        style={style}
        ref={inputRef}
        value={value}
        onChange={onChange}
        onPressEnter={onPressEnter}
        onFocus={onFocus}
        disabled={disabled}
        {...inputProps}/>
      <ScanQRButton inputRef={inputRef} disabled={disabled}/>
    </Space.Compact>
  );
});

const defaultProps = {
  style: {},
  value: '',
  disabled: false,
  onChange: () => null,
  onPressEnter: () => null,
  placeholder: 'ค้นหา'
};

QRCodeSearch.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  onPressEnter: PropTypes.func,
  style: PropTypes.object,
}

export default QRCodeSearch;