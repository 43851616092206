import React from "react";
import dayjs from "dayjs";
import { Row, Col, theme, Button, Space, Tooltip } from 'antd';
import TSTable from "../components/common/TSTable";
import { URL_COMMON, URL_INFLORESCENCE, URL_PALMTREE } from "../constants/urls";
import { EyeOutlined, EditOutlined, FilePdfOutlined, FileExcelOutlined } from '@ant-design/icons';
import { 
  DATETIME_FORMAT, 
  HUMAN_DATETIME_FORMAT, 
  INFLORESCENCE_GENDER_OPTIONS, 
  INFLORESCENCE_GENDER_TH, 
  INFLORESCENCE_STATE, 
  INFLORESCENCE_STATE_OPTIONS, 
  INFLORESCENCE_STATE_TH 
} from "../constants/strings";
import InflorescenseEditModal from "../components/inflorescence/inflorescenceEditModal";
import InflorescenseStateModal from "../components/inflorescence/inflorescenceStateModal";
import useDropdownItem from "../hooks/useDropdownItem";

export default function InflorescenseListView() {
  const tableRef = React.useRef(null);
  const plantationDropdown = useDropdownItem({ url: URL_PALMTREE.PLANTATION, label: 'code', value:'code', pagination: true, params: { page_size: 999 } })
  const treeTypeDropdown = useDropdownItem({ url: URL_PALMTREE.TREE_TYPE, label: 'name', value:'name', pagination: true, params: { page_size: 999 } })
  const productionTypeDropdown = useDropdownItem({ url: URL_COMMON.PRODUCTION_TYPE, label: 'name', pagination: true, params: { page_size: 999 } })
  const [editTarget, setEditTarget] = React.useState(null);
  const [stateTarget, setStateTarget] = React.useState(null);

  const {
    token: { colorInfo, colorWarning  },
  } = theme.useToken();

  const refreshData = () => {
    if(tableRef.current)
      tableRef.current.fetch();
  }

  const columns = [
    {
      title: "Action",
      dataIndex: "",
      skipReport: true,
      render : (_, original) => (
      <Row gutter = {8} justify='center'>
        <a onClick={(e) => e.stopPropagation()} >
          <Col>
            <Tooltip title="ประวัติการทำงานกับช่อดอก">
              <Button 
                style={{background: colorInfo}}
                type={"primary"} 
                icon={<EyeOutlined />} 
                onClick={() => setStateTarget(original)}/>
            </Tooltip>
          </Col>
        </a>
      </Row>
      ),
    },
    {
      title: "สวน",
      dataIndex: "plantation_code",
      searcher: true,
      searcherOptions: [{ key: 'all', label: 'ทั้งหมด', value: '' }, ...plantationDropdown.options],
    },
    {
      title: "แปลง",
      dataIndex: "plot_code",
      searcher : true,
    },
    {
      title: "เลขต้น",
      dataIndex: "tree_number",
      searcher : true,
    },
    {
      title: "รหัสต้น",
      dataIndex: "tree_code",
      searcher : true,
    },
    {
      title: "รหัสช่อ",
      dataIndex: "code",
      searcher : true,
    },
    {
      title: "ทางใบ",
      dataIndex: "branch",
    },
    {
      title: "เพศ",
      dataIndex: "gender",
      render : (value) => (
        INFLORESCENCE_GENDER_TH[value]
      ),
      searcher: true,
      searchPlaceholder: 'Search Production Type',
      searcherOptions: [{ key: 'all', label: 'ทั้งหมด', value: '' }, ...INFLORESCENCE_GENDER_OPTIONS],
    },
    {
      title: "สถานะผลิต",
      dataIndex: "production_type_name",
      key: "production_type",
      render : (value,original) => (
        <>
        {value}
          <Button
            type='link'
            size='small'
            style={{color : colorWarning}}
            icon={<EditOutlined/>}
            onClick={() => setEditTarget(original)} />
        </>
      ),
      renderReport: (value) => (value),
      searcher: true,
      searchPlaceholder: 'Search Production Type',
      searcherOptions: [{ key: 'all', label: 'ทั้งหมด', value: '' }, ...productionTypeDropdown.options],
    },
    {
      title: "สถานะ",
      dataIndex: "state",
      render : (value) => (
        INFLORESCENCE_STATE_TH[value]
      ),
      searcher: true,
      searchPlaceholder: 'Search State',
      searcherOptions: [{ key: 'all', label: 'ทั้งหมด', value: '' }, ...INFLORESCENCE_STATE_OPTIONS],
    },
  ]

  React.useEffect(() => {
    plantationDropdown.fetchOptions();
    treeTypeDropdown.fetchOptions();
    productionTypeDropdown.fetchOptions();
  },[])

  return (
    <>
      <Space style={{ float: 'right', marginBottom: "10px" }}>
        <Row gutter={8}>
          <Col>
            <Button
              icon={<FilePdfOutlined />}
              onClick={async () => {
                await tableRef.current.exportPDF({
                  title: `รายการช่อดอก`,
                  subtitle: `ดึงข้อมูล ณ​ วันที่ ${dayjs().format(HUMAN_DATETIME_FORMAT)} น.`,
                  landscape: true
                })
              }}>
              PDF
            </Button>
          </Col>
          <Col>
            <Button
              icon={<FileExcelOutlined />}
              onClick={async () => {
                await tableRef.current.exportXlsx({
                  title: `รายการช่อดอก`,
                  subtitle: `ดึงข้อมูล ณ​ วันที่ ${dayjs().format(HUMAN_DATETIME_FORMAT)} น.`,
                  filename: `รายการช่อดอก - ${dayjs().format(DATETIME_FORMAT)}.xlsx`
                })
              }}>
              Excel
            </Button>
          </Col>
        </Row>
      </Space>

    <TSTable
      ref={tableRef}
      columns={columns}
      url={URL_INFLORESCENCE.INFLORESCENCE}
      style={{ marginTop : 16 }}
      rowKey={"id"}
      params={{}}
      size={"small"}
      pageSize={10}
    />

    <InflorescenseEditModal
      open={editTarget ? true : false}
      onClose={() => setEditTarget(null)}
      target={editTarget}
      onUpdate={() => {
        setEditTarget(null)
        refreshData()
      }}
    />

    <InflorescenseStateModal
      open={stateTarget ? true : false}
      onClose={() => setStateTarget(null)}
      target={stateTarget}
    />
    </>
  )
}