import React from "react";
import { Row, Col, Card } from 'antd';
import ResultPalmTreeTable from "./ResultPalmTreeTable";
import CurrentStockTable from "./CurrentStockTable";
import BunchesOnPalmTreeTable from "./BunchesOnPalmTreeTable";
import InforesenceStatusBar from "./InforesenceStatusBar";

export default function DashboardInfoView() {

  return(
    <Row gutter={[16, 16]}>
      <Col xxl={12} xl={12} lg={24} md={24} sm={24} xs={24}>
        <Card title="สรุปต้นพันธุ์" bordered={false}>
          <ResultPalmTreeTable />
        </Card>
      </Col>

      <Col xxl={12} xl={12} lg={24} md={24} sm={24} xs={24}>
        <Card title="สต๊อกเกสร" bordered={false}>
          <CurrentStockTable/>
        </Card>
      </Col>

      <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
        <Card title="ช่อดอกบนต้นพันธุ์" bordered={false}>
            <InforesenceStatusBar /> 
        </Card>
      </Col>

      <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
        <Card title="ทะลายบนต้นพันธุ์" bordered={false}>
          <BunchesOnPalmTreeTable/> 
        </Card>
      </Col>
  </Row>
  
  )
}   