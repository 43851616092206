import React from "react";
import TSTable from "../common/TSTable";
import { URL_DASHBOARD } from "../../constants/urls";

export default function CurrentStockTable(props) {  
  const tableRef = React.useRef(null);

  const columns = [
    {
      title: "ลูกผสม",
      dataIndex: "species_name",
      
    },
    {
      title: "ปริมาณเกสร(หลอด)",
      dataIndex: "",
      children: [
        {
            title: <center>ทั้งหมด</center>,
            dataIndex: "sum_total_amount",
        },
        {
          title: <center>ใช้ไปแล้ว</center>,
          dataIndex: "sum_usage_amount",
        },
        {
          title: <center>คงเหลือ</center>,
          dataIndex: "sum_current_amount",
        },
      ]
    },
  ]

  return (
    <TSTable
      ref={tableRef}
      columns={columns}
      rowKey={"id"}
      url={URL_DASHBOARD.STOCK_DASHBOARD}
      params={{ordering: "species_name"}}
      size={"small"}
      pageSize={5}
    />
  )
}