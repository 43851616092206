import React from "react";
import { Modal, Form, theme, Alert, Spin, Input } from 'antd';
import propTypes from "prop-types";
import { STOCK_ACTION } from "../../constants/strings";
import { POST } from "../../frameworks/HttpClient";
import { URL_STOCK } from "../../constants/urls";

export default function StockInOutModal(props) {
  const { open, onClose, target, action, onUpdate } = props;
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [form] = Form.useForm();

  const {
    token: { colorWarning, colorSuccess, colorError },
  } = theme.useToken();

  const handleSave = async() => {
    setErrorMessages(null);
    setLoading(true)
    try {
      let data = await form.validateFields();
      data['stock'] = target.id
      data['action'] = action

      await POST(URL_STOCK.STOCK_TRANSACTION, data)
      onUpdate();

    } catch (error) {
      setErrorMessages(error.errorMessages)
    } finally {
      setLoading(false)
    }
    
  }

  React.useEffect(() => {
    if(open){
      form.resetFields();
    }
  },[open])

  return(
    <Spin spinning={loading}>
      <Modal
        open={open}
        okButtonProps={{style : { background : (action === STOCK_ACTION.IMPORT ? colorSuccess : colorError) }}}
        onOk = {handleSave}
        okText = {action === STOCK_ACTION.IMPORT ? "รับเข้า" : "ตัดออก"}
        title={`${action === STOCK_ACTION.IMPORT ? "รับเข้า" : "ตัดออก"} - ${target && target.lot_number}`}
        onCancel={onClose}
        maskClosable={false}
        cancelText={"ยกเลิก"}>
        {errorMessages && 
          <Alert
            message={errorMessages}
            type="error"
            showIcon
            style={{marginBottom: 16, textAlign: "left"}}
          />
        }
        <Form
          form={form}
          layout="vertical"
          autoComplete="off">
            <Form.Item
              name={'amount'}
              label={action === STOCK_ACTION.IMPORT ? "ปริมาณรับเข้า (หลอด)" : "ปริมาณตัดออก (หลอด)"}
              rules={[{ required: true }]}>
              <Input style={{ width : '100%' }} type='number'/>
            </Form.Item>

            <Form.Item
              name={'note'}
              label={"หมายเหตุ"}>
              <Input.TextArea />
            </Form.Item>
        </Form>
      </Modal>
    </Spin>
  )
}

StockInOutModal.defaultProps = {
  open: null,
  onClose: () => {}, //ปุ่มกลับ
  target: [],
  action : null, // STOCK_ACTION.IMPORT , STOCK_ACTION.IMPORT
}

StockInOutModal.propTypes = {
  target: propTypes.object,
  action: propTypes.string,
}