import { URL_INFLORESCENCE } from "./urls"

export const DATE_FORMAT = 'YYYY-MM-DD'
export const HUMAN_DATE_FORMAT = 'DD/MM/YYYY'
export const TIME_FORMAT = 'HH:mm'
export const DATETIME_FORMAT = 'YYYY-MM-DD HH:mm'
export const HUMAN_DATETIME_FORMAT = 'DD/MM/YYYY HH:mm'
export const HUMAN_DATETIME_2_DIGIT_FORMAT = 'DD/MM/YY HH:mm'
export const MONTH_FORMAT = 'YYYY-MM'
export const YEAR_FORMAT = 'YYYY'

export const MENUS_MOBILE_TH = {
    'task' : 'แผนงาน',
    'sender' : 'ใบส่งของเข้าโรงงาน',
    'profile' : 'โปรไฟล์',
}

export const USER_GROUP = {
    WORKER: 'worker',
    RECEIVER_MOBILE: 'receiver_mobile',
    OFFICER: 'officer',
    MANAGER: 'manager',
    FIELD_OFFICER: 'field_officer'
}

export const WORK_STATUS = {
    SPECIFY_GENDER : 'specify_gender',
    COVER_BOUQUET : 'cover_bouquet',
    CUT_BUNCH : 'cut_bunch',
}

export const WORK_STATUS_TH = {
    [WORK_STATUS.SPECIFY_GENDER] : 'รอระบุเพศ',
    [WORK_STATUS.COVER_BOUQUET] : 'รอคลุมช่อ',
    [WORK_STATUS.CUT_BUNCH] : 'รอตัดทะลาย',
}

export const TREE_GENDER = {
    MALE : 'male',
    FEMALE : 'female',
}

export const TREE_GENDER_TH = {
    [TREE_GENDER.MALE]: 'ต้นพ่อ',
    [TREE_GENDER.FEMALE]: 'ต้นแม่',
}

export const TREE_GENDER_OPTIONS = [
    {value: TREE_GENDER.FEMALE, label: TREE_GENDER_TH[TREE_GENDER.FEMALE]},
    {value: TREE_GENDER.MALE, label: TREE_GENDER_TH[TREE_GENDER.MALE]},
]

export const INFLORESCENCE_GENDER = {
    MALE : 'male',
    FEMALE : 'female',
    QUEER : 'queer',
}

export const INFLORESCENCE_GENDER_TH = {
    [INFLORESCENCE_GENDER.MALE] : 'ช่อผู้',
    [INFLORESCENCE_GENDER.FEMALE] : 'ช่อเมีย',
    [INFLORESCENCE_GENDER.QUEER] : 'ช่อกะเทย',
}

export const INFLORESCENCE_GENDER_OPTIONS = [
    { value: INFLORESCENCE_GENDER.MALE, label: INFLORESCENCE_GENDER_TH[INFLORESCENCE_GENDER.MALE], key : INFLORESCENCE_GENDER.MALE},
    { value: INFLORESCENCE_GENDER.FEMALE, label: INFLORESCENCE_GENDER_TH[INFLORESCENCE_GENDER.FEMALE], key : INFLORESCENCE_GENDER.FEMALE},
]

export const INFLORESCENCE_ACTION = {
    CREATED : 'created',
    GENDER :'gender',
    COVER : 'cover',
    POLLINATE : 'pollinate',
    REVEAL : 'reveal',
    CUT : 'cut',
    COLLECT : 'collect',
}

export const INFLORESCENCE_ACTION_TH = {
    [INFLORESCENCE_ACTION.CREATED] : 'ช่อใหม่',
    [INFLORESCENCE_ACTION.GENDER] : 'ระบุเพศ',
    [INFLORESCENCE_ACTION.COVER] : 'คลุมช่อ',
    [INFLORESCENCE_ACTION.POLLINATE] : 'ผสมเกสร',
    [INFLORESCENCE_ACTION.REVEAL] : 'ตัดถุง',
    [INFLORESCENCE_ACTION.CUT] : 'ตัดทะลาย',
    [INFLORESCENCE_ACTION.COLLECT] : 'เก็บเกสร',
}

export const INFLORESCENCE_STATE = {
    INPUT_GENDER : 'wait_input_gender',
    COVERING : 'wait_covering',
    POLLINATING : 'wait_pollinating',
    REAVEALING : 'wait_revealing',
    CUTTING : 'wait_cutting',
    COLLECTING : 'wait_collecting',
    WAIT_EXPORTING : 'wait_exporting',
    EXPORTED : 'exported',
    ARRIVED : 'arrived',
    REJECTED : 'rejected',
}

export const INFLORESCENCE_TASK_STATE = {
    DONE : 'done',
    POSTPONE : 'postpone',
    PENDING : 'pending',
    CANCEL : 'cancel'
}

export const INFLORESCENCE_TASK_STATE_TH = {
    [INFLORESCENCE_TASK_STATE.DONE] : 'สำเร็จ',
    [INFLORESCENCE_TASK_STATE.POSTPONE] : 'เลื่อน',
    [INFLORESCENCE_TASK_STATE.PENDING] : 'รอดำเนินการ',
    [INFLORESCENCE_TASK_STATE.CANCEL] : 'ยกเลิก'
}

export const INFLORESCENCE_STATE_TH = {
    [INFLORESCENCE_STATE.INPUT_GENDER] : 'รอระบุเพศ',
    [INFLORESCENCE_STATE.COVERING] : 'รอคลุมช่อ',
    [INFLORESCENCE_STATE.POLLINATING] : 'รอผสมเกสร',
    [INFLORESCENCE_STATE.REAVEALING] : 'รอตัดถุง',
    [INFLORESCENCE_STATE.CUTTING] : 'รอตัดทะลาย',
    [INFLORESCENCE_STATE.COLLECTING] : 'รอเก็บเกสร',
    [INFLORESCENCE_STATE.WAIT_EXPORTING] : 'รอส่งโรงงาน',
    [INFLORESCENCE_STATE.EXPORTED] : 'จัดส่งโรงงาน',
    [INFLORESCENCE_STATE.ARRIVED] : 'รับเข้าโรงงาน',
    [INFLORESCENCE_STATE.REJECTED] : 'ยกเลิก'
}

export const INFLORESCENCE_STATE_OPTIONS = [
    { value: INFLORESCENCE_STATE.INPUT_GENDER, label: INFLORESCENCE_STATE_TH[INFLORESCENCE_STATE.INPUT_GENDER], key : INFLORESCENCE_STATE.INPUT_GENDER},
    { value: INFLORESCENCE_STATE.COVERING, label: INFLORESCENCE_STATE_TH[INFLORESCENCE_STATE.COVERING], key : INFLORESCENCE_STATE.COVERING},
    { value: INFLORESCENCE_STATE.POLLINATING, label: INFLORESCENCE_STATE_TH[INFLORESCENCE_STATE.POLLINATING], key : INFLORESCENCE_STATE.POLLINATING},
    { value: INFLORESCENCE_STATE.REAVEALING, label: INFLORESCENCE_STATE_TH[INFLORESCENCE_STATE.REAVEALING], key : INFLORESCENCE_STATE.REAVEALING},
    { value: INFLORESCENCE_STATE.CUTTING, label: INFLORESCENCE_STATE_TH[INFLORESCENCE_STATE.CUTTING], key : INFLORESCENCE_STATE.CUTTING},
    { value: INFLORESCENCE_STATE.COLLECTING, label: INFLORESCENCE_STATE_TH[INFLORESCENCE_STATE.COLLECTING], key : INFLORESCENCE_STATE.COLLECTING},
    { value: INFLORESCENCE_STATE.WAIT_EXPORTING, label: INFLORESCENCE_STATE_TH[INFLORESCENCE_STATE.WAIT_EXPORTING], key : INFLORESCENCE_STATE.WAIT_EXPORTING},
    { value: INFLORESCENCE_STATE.EXPORTED, label: INFLORESCENCE_STATE_TH[INFLORESCENCE_STATE.EXPORTED], key : INFLORESCENCE_STATE.EXPORTED},
    { value: INFLORESCENCE_STATE.ARRIVED, label: INFLORESCENCE_STATE_TH[INFLORESCENCE_STATE.ARRIVED], key : INFLORESCENCE_STATE.ARRIVED},
]

export const INFLORESCENCE_ACTION_OPTIONS = [
    { value: INFLORESCENCE_ACTION.GENDER, label: INFLORESCENCE_ACTION_TH[INFLORESCENCE_ACTION.GENDER], key: INFLORESCENCE_ACTION.GENDER },
    { value: INFLORESCENCE_ACTION.COVER, label: INFLORESCENCE_ACTION_TH[INFLORESCENCE_ACTION.COVER], key: INFLORESCENCE_ACTION.COVER },
    { value: INFLORESCENCE_ACTION.POLLINATE, label: INFLORESCENCE_ACTION_TH[INFLORESCENCE_ACTION.POLLINATE], key: INFLORESCENCE_ACTION.POLLINATE },
    { value: INFLORESCENCE_ACTION.REVEAL, label: INFLORESCENCE_ACTION_TH[INFLORESCENCE_ACTION.REVEAL], key: INFLORESCENCE_ACTION.REVEAL },
    { value: INFLORESCENCE_ACTION.CUT, label: INFLORESCENCE_ACTION_TH[INFLORESCENCE_ACTION.CUT], key: INFLORESCENCE_ACTION.CUT },
    { value: INFLORESCENCE_ACTION.COLLECT, label: INFLORESCENCE_ACTION_TH[INFLORESCENCE_ACTION.COLLECT], key: INFLORESCENCE_ACTION.COLLECT },
]

export const INFLORESCENCE_REPORT_ACTION_OPTIONS = [
    { value: INFLORESCENCE_ACTION.COVER, label: INFLORESCENCE_ACTION_TH[INFLORESCENCE_ACTION.COVER], key: INFLORESCENCE_ACTION.COVER },
    { value: INFLORESCENCE_ACTION.POLLINATE, label: INFLORESCENCE_ACTION_TH[INFLORESCENCE_ACTION.POLLINATE], key: INFLORESCENCE_ACTION.POLLINATE },
    { value: INFLORESCENCE_ACTION.CUT, label: INFLORESCENCE_ACTION_TH[INFLORESCENCE_ACTION.CUT], key: INFLORESCENCE_ACTION.CUT },
    { value: INFLORESCENCE_ACTION.COLLECT, label: INFLORESCENCE_ACTION_TH[INFLORESCENCE_ACTION.COLLECT], key: INFLORESCENCE_ACTION.COLLECT },
]

export const INFLORESCENCE_QC_PASSED_OPTIONS = [
    { value: false, label: "ไม่ผ่าน", key: 'false' },
    { value: true, label: "ผ่าน", key: 'true' },
]

export const STOCK_ACTION = {
    POLLINATE : 'pollinate',
    IMPORT : 'import',
    EXPORT : 'export',
    CLOSE : 'close',
}

export const STOCK_ACTION_TH = {
    [STOCK_ACTION.POLLINATE] : 'ผสมเกสร',
    [STOCK_ACTION.IMPORT] : 'รับ',
    [STOCK_ACTION.EXPORT] : 'จ่าย',
    [STOCK_ACTION.CLOSE] : 'ปิดสต๊อก',
}

export const TASK_COLOR = {
    WARNING: '#FFFABF',
    LATE: '#FFCACA',
}

export const TASK_TYPE = {
    INFLORESCENCE: 'inflorescencetask'
}

export const TASK_ACTION_MAP = {
    [TASK_TYPE.INFLORESCENCE]: INFLORESCENCE_ACTION_TH
}

export const REJECT_REASON_MAP = {
    [TASK_TYPE.INFLORESCENCE]: URL_INFLORESCENCE.REJECT_REASON
}

export const EXPORT_TYPE = {
    BUNCH : 'bunch',
    POLLEN : 'pollen',
}

export const EXPORT_TYPE_TH = {
    [EXPORT_TYPE.BUNCH] : 'ส่งทะลาย',
    [EXPORT_TYPE.POLLEN] : 'ส่งเกสร',
}

export const EXPORT_TYPE_OPTIONS = [
    { value: EXPORT_TYPE.BUNCH, label: 'ทะลาย', key : EXPORT_TYPE.BUNCH},
    { value: EXPORT_TYPE.POLLEN, label: 'เกสร', key : EXPORT_TYPE.POLLEN},
]

export const BUNCH_STATUS = {
    RAW: 'raw',
    RIPE: 'ripe',
    OVERRIPE: 'overripe',
    ROTTEN: 'rotten',
}

export const BUNCH_STATUS_TH = {
    [BUNCH_STATUS.RAW]: 'ยังไม่สุก',
    [BUNCH_STATUS.RIPE]: 'สุก',
    [BUNCH_STATUS.OVERRIPE]: 'สุกเกิน',
    [BUNCH_STATUS.ROTTEN]: 'เน่า',
}

export const BUNCH_STATUS_OPTIONS = [
    { value: BUNCH_STATUS.RAW, label: BUNCH_STATUS_TH[BUNCH_STATUS.RAW], key : BUNCH_STATUS.RAW},
    { value: BUNCH_STATUS.RIPE, label: BUNCH_STATUS_TH[BUNCH_STATUS.RIPE], key : BUNCH_STATUS.RIPE},
    { value: BUNCH_STATUS.OVERRIPE, label: BUNCH_STATUS_TH[BUNCH_STATUS.OVERRIPE], key : BUNCH_STATUS.OVERRIPE},
    { value: BUNCH_STATUS.ROTTEN, label: BUNCH_STATUS_TH[BUNCH_STATUS.ROTTEN], key : BUNCH_STATUS.ROTTEN},
]

export const DELIVERY_TRANSACTION_STATE = {
    PREPARE: 'prepare',
    SENT: 'sent',
    RECEIVED: 'received',
}

export const DELIVERY_TRANSACTION_STATE_TH = {
    [DELIVERY_TRANSACTION_STATE.PREPARE] : 'เตรียมของ',
    [DELIVERY_TRANSACTION_STATE.SENT] : 'ส่งเข้าโรงงาน',
    [DELIVERY_TRANSACTION_STATE.RECEIVED] : 'รับเข้าโรงงาน',
}

export const STOCK_IS_EMPTY_OPTIONS = [
    { value : "false", label: 'มีสินค้า', key:'false' },
    { value: "true", label: 'ไม่มีสินค้า', key:'true' },
]

export const REPORT_FILTER = {
    TREE_GENDER: 'gender',
    TREE_TYPE : 'tree_type',
    PRODUCTION_TYPE : 'production_type',
    PLANTATION : 'plantation',
    SPECIES : 'speices',
    DATE_RANGE: "date_range",
    DATE_RANGE_2: "date_range_2",
    DATE: "date",
    PLAN_DATE: "plan_date",
    PALMTREE: "palmtree",
    PLOT: "plot",
    ROW: "row",
    COL: "col",
    DELIVERY_TRANSACTION: "delivery_transaction",
    ACTION: "action",
    TREE_CODE: "code",
    IS_QC_PASSED: "is_qc_passed",
}

export const REPORT_FILTER_TH = {
    [REPORT_FILTER.TREE_GENDER] : 'ต้นแม่/ต้นพ่อ',
    [REPORT_FILTER.TREE_TYPE] : 'ประเภทต้นพันธุ์',
    [REPORT_FILTER.PRODUCTION_TYPE]: 'สถานะการผลิต',
    [REPORT_FILTER.PLANTATION] : 'สวน',
    [REPORT_FILTER.SPECIES] : 'รหัสพันธุ์',
    [REPORT_FILTER.DATE_RANGE] : 'ช่วงวันที่',
    [REPORT_FILTER.DATE_RANGE_2] : 'ช่วงวันที่',
    [REPORT_FILTER.DATE] : 'วันที่',
    [REPORT_FILTER.PLAN_DATE]: "วันที่",
    [REPORT_FILTER.PALMTREE] : 'รหัสต้น',
    [REPORT_FILTER.PLOT] : 'แปลง',
    [REPORT_FILTER.ROW] : 'แถว',
    [REPORT_FILTER.COL] : 'ต้น',
    [REPORT_FILTER.DELIVERY_TRANSACTION]: 'เลขที่ใบส่งของ',
    [REPORT_FILTER.ACTION]: 'ประเภทการทำงาน',
    [REPORT_FILTER.TREE_CODE]: 'รหัสต้น',
    [REPORT_FILTER.IS_QC_PASSED]: 'ผ่านการประเมิน',
}

export const MONTH = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']