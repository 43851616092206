import React from "react";
import TSTable from "../common/TSTable";
import { Modal, Row, Col, Tooltip, Button, theme, } from "antd";
import { EyeOutlined, EditOutlined, } from '@ant-design/icons';
import { URL_COMMON, URL_INFLORESCENCE, URL_PALMTREE } from "../../constants/urls";
import { DATETIME_FORMAT, INFLORESCENCE_GENDER_OPTIONS, INFLORESCENCE_GENDER_TH, INFLORESCENCE_STATE_OPTIONS, INFLORESCENCE_STATE_TH, INFLORESCENCE_TASK_STATE } from "../../constants/strings";
import useDropdownItem from "../../hooks/useDropdownItem";
import * as _ from "lodash";
import dayjs from "dayjs";
import { currencyFormat } from "../../frameworks/Util";

export default function EmployeeInflorescenceModal(props) {
  const tableRef = React.useRef(null);
  const { open, onClose, target, targetDetail, params} = props;
  const productionTypeDropdown = useDropdownItem({ url: URL_COMMON.PRODUCTION_TYPE, label: 'name', pagination: true, params: { page_size: 999 } })
  const plantationDropdown = useDropdownItem({ url: URL_PALMTREE.PLANTATION, label: 'code', value:'code', pagination: true, params: { page_size: 999 } })
  const [stateTarget, setStateTarget] = React.useState(null);

  const {
    token: { colorBgContainer, colorWarning, colorInfo },
  } = theme.useToken();

  const columns = [
    {
      title: "วันที่เวลา",
      dataIndex: "updated",
      render : (value) => (value ? dayjs(value).format(DATETIME_FORMAT): '-')
    },
    {
      title: "สวน",
      dataIndex: "plantation_code",
      searcher: true,
      searcherOptions: [{ key: 'all', label: 'ทั้งหมด', value: '' }, ...plantationDropdown.options],
    },
    {
      title: "รหัสช่อดอก",
      dataIndex: "inflorescence_code",
      searcher : true,
    },
    {
      title: "รหัสต้น",
      dataIndex: "tree_code",
      searcher : true,
    },
    {
      title: "เพศ",
      dataIndex: "inflorescence_gender",
      render : (value) => (
        INFLORESCENCE_GENDER_TH[value]
      ),
      searcher: true,
      searchPlaceholder: 'Search Production Type',
      searcherOptions: [{ key: 'all', label: 'ทั้งหมด', value: '' }, ...INFLORESCENCE_GENDER_OPTIONS],
    },
    {
      title: "หมายเหตุ",
      dataIndex: `${params.state === INFLORESCENCE_TASK_STATE.CANCEL ? 'reject_reason_name' : 'detail'}`
    }
  ]

    const refreshData  = () => {
      if(tableRef.current){
        tableRef.current.fetch({ submitter : target ? target : null, ...params});
      }
    }

    React.useEffect(() => {
      plantationDropdown.fetchOptions();
      productionTypeDropdown.fetchOptions();
    },[])

    React.useEffect(() => {
      if(target){
        refreshData()
      }
    },[target || params])

    React.useEffect(() => {
      if(!open && tableRef.current) {
        tableRef.current.clear()
      }
    }, [open])

  return (
  <Modal
    width={1200}
    open={open}
    onCancel={onClose}
    title={targetDetail}
    footer={[
      <Button key="cancel" onClick={onClose}>
        Close
      </Button>,
    ]}
    maskClosable={false}
  >
    <TSTable
      ref={tableRef}
      columns={columns}
      open={open}
      url={URL_INFLORESCENCE.TASK}
      params={{ submitter : target ? target : null, ...params, inflorescence_gender : _.get(params, 'gender', null)}}
      style={{ marginTop : 16 }}
      rowKey={"submitter"}
      size={"small"}
      pageSize={10}
    />
  </Modal>
  )
}