import React from "react";
import {
  Button,
  Drawer,
  Image,
  Layout,
  Menu,
  Spin,
  Space,
  theme,
  Popover,
} from "antd";
import { TabBar } from "antd-mobile";
import { PATH } from "../routes/CustomRoutes";
import {
  UserOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UnlockOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import { useWindowDimensionContext } from "../hooks/WindowDimensionProvider";
import { useUserContext } from "../hooks/UserSettingProvider";
import { useNavigate, useParams } from "react-router-dom";
import { Content, Footer, Header } from "antd/es/layout/layout";
import SenderView from "../views/SenderView";
import TaskListView from "../views/TaskListView";
import { DELIVERY_TRANSACTION_STATE, USER_GROUP } from "../constants/strings";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTree,
  faChartLine,
  faSeedling,
  faFlaskVial,
  faCubes,
  faStore,
  faBarcode,
  faFileLines,
  faTruck,
  faRectangleList,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import * as _ from "lodash";
import Sider from "antd/es/layout/Sider";
import ProfileView from "../views/ProfileView";
import {
  BG_LAYOUT_COLOR,
  BLACK_COLOR,
  PRIMARY_COLOR,
  WHITE_COLOR_BG,
} from "../constants/color";
import logo from "../assets/cpi.png";
import LogoutModal from "../components/user/LogoutModal";
import SeedlingsView from "../views/SeedlingsView";
import ChangePasswordModal from "../components/user/ChangePasswordModal";
import InflorescenceListView from "../views/InflorescenceListView";
import StockListView from "../views/StockListView";
import OrderView from "../views/OrderView";
import ReceiverView from "../views/ReceiverView";
import BarcodeView from "../views/BarcodeView";
import ReportView from "../views/ReportView";
import DashboardView from "../views/DashboardView";
import FieldSenderView from "../views/FieldSenderView";

export default function MenuScreen() {
  const params = useParams();
  const navigate = useNavigate();
  const { user } = useUserContext();
  const [currentMenu, setCurrentMenu] = React.useState(null);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [collapsed, setCollapsed] = React.useState(false);
  const [openUserMenu, setOpenUserMenu] = React.useState(false);
  const [openLogout, setOpenLogout] = React.useState(false);
  const [openChangePassword, setOpenChangePassword] = React.useState(false);
  const { lessThanOrEqual } = useWindowDimensionContext();
  const [loading, setLoading] = React.useState(true);
  const isOnMobile = lessThanOrEqual("sm");

  const {
    token: { colorBgContainer, colorPrimary },
  } = theme.useToken();

  // Check user permission
  const hasPermission = (target) => (user && user.groups.find((group) => group === target))

  const tabsMobile = [
    {
      key: PATH.TASK.substring(1),
      icon: <FontAwesomeIcon icon={faRectangleList} />,
      disable: (!hasPermission(USER_GROUP.WORKER)).toString(),
    },
    {
      key: PATH.SENDER.substring(1),
      icon: <FontAwesomeIcon icon={faTruck} />,
      disable: (!hasPermission(USER_GROUP.WORKER)).toString(),
    },
    {
      key: PATH.RECEIVER_MOBILE.substring(1),
      icon: <FontAwesomeIcon icon={faTruck} />,
      disable: (!hasPermission(USER_GROUP.RECEIVER_MOBILE)).toString(),
    },
    {
      key: PATH.PROFILE.substring(1),
      icon: <FontAwesomeIcon icon={faUser} />,
      disable: "false"
    },
  ];

  const menus = [
    {
      key: PATH.DASHBOARD.substring(1),
      icon: <FontAwesomeIcon icon={faChartLine} />,
      label: "Dashboard",
      disable: (!hasPermission(USER_GROUP.MANAGER)).toString(),
    },
    {
      key: PATH.SEEDLINGS.substring(1),
      icon: <FontAwesomeIcon icon={faTree} />,
      label: "ต้นพันธ์ุ",
      disable: (!hasPermission(USER_GROUP.MANAGER) && !hasPermission(USER_GROUP.OFFICER) && !hasPermission(USER_GROUP.FIELD_OFFICER)).toString(),
    },
    {
      key: PATH.INFLORESCENCE.substring(1),
      icon: <FontAwesomeIcon icon={faSeedling} />,
      label: "ช่อดอก",
      disable: (!hasPermission(USER_GROUP.MANAGER) && !hasPermission(USER_GROUP.OFFICER) && !hasPermission(USER_GROUP.FIELD_OFFICER)).toString(),
    },
    {
      key: PATH.ORDER.substring(1),
      icon: <FontAwesomeIcon icon={faFlaskVial} />,
      label: "สั่งผสมเกสร",
      disable: (!hasPermission(USER_GROUP.MANAGER) && !hasPermission(USER_GROUP.OFFICER)).toString(),
    },
    {
      key: PATH.RECEIVER.substring(1),
      icon: <FontAwesomeIcon icon={faCubes} />,
      label: "ใบส่งของ",
      disable: (!hasPermission(USER_GROUP.MANAGER) && !hasPermission(USER_GROUP.OFFICER)).toString(),
    },
    {
      key: PATH.FIELD_SENDER.substring(1),
      icon: <FontAwesomeIcon icon={faCubes} />,
      label: "ใบส่งของ (สวน)",
      disable: (!hasPermission(USER_GROUP.FIELD_OFFICER)).toString(),
    },
    {
      key: PATH.STOCK.substring(1),
      icon: <FontAwesomeIcon icon={faStore} />,
      label: "สต๊อกเกสร",
      disable: (!hasPermission(USER_GROUP.MANAGER) && !hasPermission(USER_GROUP.OFFICER)).toString(),
    },
    {
      key: PATH.BARCODE.substring(1),
      icon: <FontAwesomeIcon icon={faBarcode} />,
      label: "บาร์โค้ด",
      disable: (!hasPermission(USER_GROUP.OFFICER)).toString(),
    },
    {
      key: PATH.REPORT.substring(1),
      icon: <FontAwesomeIcon icon={faFileLines} />,
      label: "รายงาน",
      disable: (!hasPermission(USER_GROUP.MANAGER) && !hasPermission(USER_GROUP.OFFICER) && !hasPermission(USER_GROUP.FIELD_OFFICER)).toString(),
    },
  ];

  const renderContent = () => {
    switch (currentMenu) {
      // FOR WORKER + RECEIVER
      case PATH.TASK.substring(1):
        return <TaskListView /> /* WORKER ONLY */
      case PATH.SENDER.substring(1):
        /* WORKER ONLY */
        return <SenderView 
          params={{state: DELIVERY_TRANSACTION_STATE.PREPARE}} 
          readOnly={false}
          detailURL={PATH.SENDER_DETAIL}/> 
      case PATH.RECEIVER_MOBILE.substring(1):
        /* RECEIVER ONLY */
        return <SenderView 
          params={{state : DELIVERY_TRANSACTION_STATE.SENT}}
          readOnly={true}
          detailURL={PATH.RECEIVER_DETAIL_MOBILE}/> 
      case PATH.PROFILE.substring(1):
        return <ProfileView />
      
      // FOR MANAGER + OFFICER
      case PATH.DASHBOARD.substring(1):
        return <DashboardView/> /* MANAGER ONLY */
      case PATH.SEEDLINGS.substring(1):
        return <SeedlingsView  readOnly={(!hasPermission(USER_GROUP.MANAGER) && !hasPermission(USER_GROUP.OFFICER))}/>
      case PATH.INFLORESCENCE.substring(1):
        return <InflorescenceListView /> /* Add on FILED OFFICER */
      case PATH.ORDER.substring(1):
        return <OrderView />
      case PATH.RECEIVER.substring(1):
        return <ReceiverView />
      case PATH.FIELD_SENDER.substring(1):
        return <FieldSenderView />
      case PATH.STOCK.substring(1):
        return <StockListView />
      case PATH.BARCODE.substring(1):
        return <BarcodeView />
      
      // OFFICER + FILED OFFICER + MANAGER
      case PATH.REPORT.substring(1):
        return <ReportView disableStock={(!hasPermission(USER_GROUP.MANAGER) && !hasPermission(USER_GROUP.OFFICER))}/>;
      
      default:
        return <></>
    }
  };

  // Render menu
  const getMenus = () => {
    return (
      <Menu
        mode="inline"
        style={{ backgroundColor: WHITE_COLOR_BG }}
        onClick={(e) => {
          setCurrentMenu(e.key);
          onMenuSelected(e.key);
          setDrawerOpen(false);
        }}
        defaultSelectedKeys={[currentMenu]}
        selectedKeys={[currentMenu]}
        items={menus.filter((menu) => menu.disable != "true")}
      ></Menu>
    );
  };

  // Navigate to target path
  const onMenuSelected = (e) => {
    navigate(`/${e}`);
    setCurrentMenu(e);
  };

  const setDefaultMenu = (menu, list) => {
    const targetMenu = list
      .filter((m) => m.disable !== "true")
      .find((m) => menu === m.key);
    const firstMenu = list.find((menu) => menu.disable !== "true");
    onMenuSelected(targetMenu ? targetMenu.key : firstMenu.key);
  }

  // Route to target path
  const autoRoute = async ({ menu }) => {
    setLoading(true);
    try {
      if (menu) {
        onMenuSelected(menu);
      } else {
        if (hasPermission(USER_GROUP.WORKER) || 
          hasPermission(USER_GROUP.RECEIVER_MOBILE)) {
            setDefaultMenu(menu, tabsMobile)
          // const targetMenu = tabsMobile
          //   .filter((m) => m.disable !== "true")
          //   .find((m) => menu === m.key);
          // const firstMenu = tabsMobile.find((menu) => menu.disable !== "true");
          // onMenuSelected(targetMenu ? targetMenu.key : firstMenu.key);
          // onMenuSelected(tabsMobile[0].key);
        } else if (
          hasPermission(USER_GROUP.OFFICER) ||
          hasPermission(USER_GROUP.MANAGER) ||
          hasPermission(USER_GROUP.FIELD_OFFICER)
        ) {
          setDefaultMenu(menu, menus)
          // const targetMenu = menus
          //   .filter((m) => m.disable !== "true")
          //   .find((m) => menu === m.key);
          // const firstMenu = menus.find((menu) => menu.disable !== "true");
          // onMenuSelected(targetMenu ? targetMenu.key : firstMenu.key);
        }
      }
    } catch (error) {
      console.log(error.err);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    if (params) autoRoute(params);
  }, []);

  return (
    <Spin spinning={loading}>
      <Layout style={{ minHeight: "100vh", background: WHITE_COLOR_BG }}>
        {
          (() => {
            if (hasPermission(USER_GROUP.OFFICER) || hasPermission(USER_GROUP.MANAGER) || hasPermission(USER_GROUP.FIELD_OFFICER)) {
              return <>
              {isOnMobile ? (
                <Drawer
                  style={{ background: WHITE_COLOR_BG }}
                  placement="left"
                  title=""
                  headerStyle={{ height: 0 }}
                  bodyStyle={{ padding: 0 }}
                  width={250}
                  closeIcon={false}
                  open={drawerOpen}
                  onClose={() => setDrawerOpen(false)}
                >
                  <div
                    style={{
                      textAlign: "center",
                      marginTop: 24,
                      marginBottom: 16,
                    }}
                  >
                    <Image height={72} src={logo} preview={false} />
                  </div>
  
                  {getMenus()}
                </Drawer>
              ) : (
                <Sider
                  className="shadow"
                  style={{ background: WHITE_COLOR_BG }}
                  trigger={null}
                  collapsible
                  collapsed={collapsed}
                >
                  <div
                    style={{
                      textAlign: "center",
                      marginTop: 24,
                      marginBottom: 16,
                    }}
                  >
                    <Image
                      height={collapsed ? 32 : 72}
                      src={logo}
                      preview={false}
                    />
                  </div>
  
                  {getMenus()}
                </Sider>
              )}
              <Layout style={{ background: BG_LAYOUT_COLOR }}>
                <Header
                  style={{
                    padding: 0,
                    background: WHITE_COLOR_BG,
                    color: PRIMARY_COLOR,
                    fontWeight: "bold",
                  }}
                >
                  <Button
                    style={{
                      color: BLACK_COLOR,
                      fontSize: "16px",
                      width: 64,
                      height: 64,
                    }}
                    type="text"
                    icon={
                      collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />
                    }
                    onClick={() => {
                      if (isOnMobile) setDrawerOpen(true);
                      else setCollapsed(!collapsed);
                    }}
                  />
  
                  {_.get(
                    menus.find((menu) => menu.key == currentMenu),
                    "label",
                    "-"
                  )}
  
                  <div
                    style={{
                      display: "inline-block",
                      float: "right",
                      marginRight: 16,
                    }}
                  >
                    <Popover
                      trigger="hover"
                      title={user.full_name ? user.full_name : user.username}
                      open={openUserMenu}
                      onOpenChange={(newOpen) => setOpenUserMenu(newOpen)}
                      content={
                        <Space direction="vertical">
                          <Button
                            type="text"
                            style={{ textAlign: "left" }}
                            block
                            icon={<UnlockOutlined />}
                            onClick={() => setOpenChangePassword(true)}
                          >
                            เปลี่ยนรหัสผ่าน
                          </Button>
                          <Button
                            type="text"
                            style={{ textAlign: "left" }}
                            block
                            icon={<LogoutOutlined />}
                            onClick={() => setOpenLogout(true)}
                          >
                            ออกจากระบบ
                          </Button>
                        </Space>
                      }
                    >
                      <Button
                        shape="round"
                        size="large"
                        style={{ boxShadow: "0 0 0", color: colorPrimary }}
                        onClick={() => setOpenUserMenu(true)}
                      >
                        {_.get(user, "first_name", <UserOutlined />)}
                      </Button>
                    </Popover>
                  </div>
                </Header>
                <Content
                  style={{
                    margin: 24,
                    minHeight: 280,
                    padding: (currentMenu === PATH.DASHBOARD.substring(1)) ? null : "16px 24px",
                    background: (currentMenu === PATH.DASHBOARD.substring(1)) ? null : colorBgContainer,
                  }}>
                  {renderContent()}
                </Content>
              </Layout>
            </>
            }
            else if (hasPermission(USER_GROUP.WORKER) || hasPermission(USER_GROUP.RECEIVER_MOBILE)) {
              return <>
                <Content
                  style={{
                    margin: 24,
                    marginBottom: 60,
                    minHeight: 280,
                    background: colorBgContainer,
                  }}>
                  {renderContent()}
                </Content>
                <Footer
                  style={{
                    bordertop: "1px solid #e8e8e8",
                    position: "fixed",
                    left: 0,
                    bottom: 0,
                    width: "100%",
                    backgroundcolor: "white",
                    padding: 10,
                  }}
                >
                  <div className="tabbar-bottom">
                    <TabBar
                      onChange={(e) => onMenuSelected(e)}
                      activeKey={currentMenu}
                      defaultActiveKey={currentMenu} >
                        {/* menus.filter((menu) => menu.disable != "true") */}
                      {tabsMobile.map((item) => (
                        (item.disable != "true") 
                        ?
                        <TabBar.Item
                          key={item.key}
                          icon={item.icon}
                          title={item.title}
                        />
                        : null
                      ))}
                    </TabBar>
                  </div>
                </Footer>
              </>
            }
          })()
        }
      </Layout>

      <LogoutModal
        open={openLogout}
        onClose={() => setOpenLogout(false)}
        onUpdate={() => setOpenLogout(false)}
      />

      <ChangePasswordModal
        open={openChangePassword}
        onClose={() => setOpenChangePassword(false)}
      />
    </Spin>
  );
}
