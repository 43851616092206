import React from "react";
import TSTable from "../common/TSTable";
import { URL_DASHBOARD, URL_PALMTREE } from "../../constants/urls";
import { Col, Row, Select } from "antd";
import useDropdownItem from "../../hooks/useDropdownItem";

export default function BunchesOnPalmTreeTable(props) {  
  const tableRef = React.useRef(null);
  const [plantation, setPlantation] = React.useState(null);
  const plantationDropdown = useDropdownItem({ url: URL_PALMTREE.PLANTATION, label: 'code', value:'id', pagination: true, params: { page_size: 999 } })

  const columns = [
    {
      title: "ลูกผสม",
      dataIndex: "species_name",
    },
    {
      title: "อายุทะลาย",
      children: [
        {
            title: <center>1 เดือน</center>,
            dataIndex: "age_1m",
        },
        {
            title: <center>2 เดือน</center>,
            dataIndex: "age_2m",
        },
        {
            title: <center>3 เดือน</center>,
            dataIndex: "age_3m",
        },
        {
            title: <center>4 เดือน</center>,
            dataIndex: "age_4m",
        },
        {
            title: <center>5 เดือน</center>,
            dataIndex: "age_5m",
        },
        {
            title: <center>6 เดือน</center>,
            dataIndex: "age_6m",
        }
      ]
    }
  ]

  React.useEffect(() => {
    if (tableRef && tableRef.current)
      tableRef.current.fetch();

  }, [plantation]);

  React.useEffect(() => {
    plantationDropdown.fetchOptions();
  }, [])

  return (
    <>
      <Row gutter={[8, 16]} style={{marginBottom: 16}}>
        <label>สวน : </label>
        <Col xl={6} sm={12} xs={24}>
          <Select
            style={{width: '100%'}}
            placeholder={"เลือกสวน"}
            initialvalues={''}
            autoComplete='off'
            filterOption={false}
            allowClear={true}
            options={plantationDropdown.options}
            loading={plantationDropdown.loading}
            onChange={(value) => setPlantation(value)}/>
        </Col>
      </Row>
      <TSTable
        ref={tableRef}
        columns={columns}
        rowKey={"id"}
        url={URL_DASHBOARD.PALMBUNCH_DASHBOARD}
        params={{ordering: "species_name", plantation: plantation}}
        size={"small"}
        hidePagination={true}
        pageSize={100}
        autoFetch={false}
      />
    </>
  )
}