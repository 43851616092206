import React from "react";
import TSTable from "../common/TSTable";
import { URL_DASHBOARD } from "../../constants/urls";

export default function ResultPalmTreeTable(props) {
  const tableRef = React.useRef(null);

  const columns = [
    {
      title: "ลูกผสม",
      dataIndex: "species_name",
    },
    {
      title: "จำนวนต้นแม่",
      dataIndex: "female_count",
      
    },
    {
      title: "จำนวนต้นพ่อ",
      dataIndex: "male_count",
    },
    {
      title: "ปีปลูก",
      dataIndex: "plant_year",
    },
  ]

  return (
    <TSTable
    ref={tableRef}
    columns={columns}
    rowKey={"id"}
    url={URL_DASHBOARD.PALMTREE_DASHBOARD}
    params={{ordering: "species_name"}}
    hidePagination={false}
    size={"small"}
    pageSize={5}
    />
  )
}