import React from 'react';
import PropTypes from 'prop-types';
import { Card, theme, Space, Typography } from 'antd';
import * as _ from 'lodash';
import { DELIVERY_TRANSACTION_STATE_TH, EXPORT_TYPE_TH } from '../../constants/strings';

const SenderCard = React.forwardRef((props, ref) => {
  const propsWithDefaults = { ...defaultProps, ...props };
  const { item, style, onEdit, onDetail, ...cardProps } = propsWithDefaults
  const {Text} = Typography;

  const {
    token: { colorWarning, colorInfo },
  } = theme.useToken();

  return(
    <Card
      style={{ width : '350px'}}
      actions={!props.readOnly
        ? [
          <div style={{color : colorWarning}} onClick={onEdit}>แก้ไข</div>,
          <div style={{color : colorInfo}} onClick={onDetail}>รายละเอียด</div>,
        ] : [
          <div style={{color : colorInfo}} onClick={onDetail}>รายละเอียด</div>,
        ]}>
      <Space direction="vertical">
        <Text strong={true}>{`[${EXPORT_TYPE_TH[_.get(item, 'export_type', '-')]}]${item && item.code ? item.code : ''} - ${DELIVERY_TRANSACTION_STATE_TH[`${_.get(item, "state", '')}`]}`}</Text>
        <Text>วันที่ส่ง: {_.get(item, 'sent_date', '-')}</Text>
        <Text>ทะเบียนรถ: {_.get(item, 'vehicle', '-')}</Text>
        <Text>พนักงานขับรถ: {_.get(item, 'driver', '-')}</Text>
        <Text>หมายเหตุ: {_.get(item, 'note', '-')}</Text>
        <Text>จำนวน: {_.get(item, 'inflorescences_number', '-')}</Text>
      </Space>
    </Card>
  )
})

const defaultProps = {
  item: {},
  style: {},
  readOnly: false,
  onEdit: () => null,
  onDetail: () => null,
}

SenderCard.propTypes = {
  item: PropTypes.object,
  style: PropTypes.object,
  readOnly: PropTypes.bool,
  onEdit: PropTypes.func,
  onDetail: PropTypes.func,
}

export default SenderCard;