import React from 'react';
import * as _ from 'lodash';
import Cookies from 'js-cookie';
import { COOKIE_AUTH } from '../constants/cookies';


const UserContext = React.createContext({});

export function useUserContext() {
  return React.useContext(UserContext);
}

const defaultUserData = {
	created: "",
	date_joined: "",
	email: "",
  first_name: "",
  last_name: "",
  full_name: "",
	is_active: "",
	last_login: "",
  nickname: null,
  username: "",
  groups: [],
}

export default function UserSettingProvider({ children }) {
  // global state สำหรับใช้จัดการ feature ที่ขึ้นกับ user
  // component นี้ ถูกเรียกบน App.js ให้ component ล่าง ๆ เรียก useContext ได้
  // component อื่น ๆ เรียกใช้ state จาก useStoreContext
  // เช่น const { setting, setStaff, fetchStoreSetting, removeStoreSetting } = useStoreContext();
  const [user, setUser] = React.useState({
    ...defaultUserData, 
    ...JSON.parse(Cookies.get(COOKIE_AUTH.USER) ? Cookies.get(COOKIE_AUTH.USER) : null)
  })

  const setUserData = (userData) => {
    Cookies.set(COOKIE_AUTH.USER, JSON.stringify(userData), { expires: 30 })
    setUser({...defaultUserData, ...userData})
  }

  const removeUserData = () => {
    Cookies.remove(COOKIE_AUTH.USER)
    setUser(defaultUserData)
  }

  const onLogin = (data) => {
    Cookies.set(COOKIE_AUTH.TOKEN, _.get(data, "token", null), { expires: COOKIE_AUTH.TOKEN_EXPIRE_DAYS })
    setUserData(_.get(data, "user", {}))
  }

  const onLogout = () => {
    Cookies.remove(COOKIE_AUTH.TOKEN);
    removeUserData();
  }

  return (
    <UserContext.Provider value={{ user, onLogin, onLogout, setUserData, removeUserData }}>
      {children}
    </UserContext.Provider>
  )
}