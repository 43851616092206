import React from 'react';
import PropTypes from 'prop-types';
import { message, Select, Space, Spin } from 'antd';
import { LoadingOutlined }  from '@ant-design/icons'
import * as _ from 'lodash';
import { GET } from '../../frameworks/HttpClient';
import ScanQRButton from './ScanQRCodeButton';


const SEARCH_DELAY_MILLISECONDS = 800;

const QRCodeSelect = React.forwardRef((props, ref) => {
  const propsWithDefaults = { ...defaultProps, ...props };

  const {
    // fetch props
    fetchUrl,
    fetchParams,
    usePagination,
    optionValue,
    optionLabel,
    manual,
    disabled,
    
    // select props
    // value and onchange reserved for Form.Item only 
    // do not pass at any cost
    value,
    onChange,
    ...selectProps
  } = propsWithDefaults;

  // check scan support by check "jsInterface" in window
  const selectRef = React.useRef(); 
  const [search, setSearch] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [messageApi, contextHolder] = message.useMessage();
  const [scanValue, setScanValue] = React.useState(null);
  const [scanLoading, setScanLoaing] = React.useState(false);

  const debounceSearch = React.useCallback(
    _.debounce(setSearch, SEARCH_DELAY_MILLISECONDS),
    []
  );

  const fetchData = async (additionalParams) => {
    setLoading(true);
    try {
      const response = await GET(fetchUrl, { ...fetchParams, ...additionalParams });
      setOptions(_.get(response, usePagination ? 'data.results' : 'data', []));
    } catch (error) {
      messageApi.error('เกิดข้อผิดพลาดในการดึงตัวเลือก กรุณาลองอีกครั้ง');
    } finally {
      setLoading(false);
    }
  }

  const getData = async () => {
    setScanLoaing(true);
    try {
      const response = await GET(fetchUrl, { ...fetchParams, [optionLabel]: scanValue });
      const found = _.get(response, usePagination ? 'data.results' : 'data', [])
        .find(result => _.get(result, optionLabel, '') == scanValue);
      if (found) {
        const inOptions = await options.find(option => _.get(option, optionValue, '') == _.get(found, optionValue));
        if (!inOptions) {
          setOptions(options => [...options, found]);
        }
        onChange(_.get(found, optionValue, ''), found);
      } else {
        onChange(null, null);
      }
    } catch (error) {} finally {
      setScanValue(null);
      setScanLoaing(false)
    }
  }

  const onSearch = (value) => {
    if (value) {
      setScanValue(value)
    }
    debounceSearch(value)
  }

  React.useImperativeHandle(ref, () => ({
    fetch: fetchData,
  }));

  React.useEffect(() => {
    if (fetchUrl && !manual) {
      fetchData();
    }
  }, [fetchUrl])

  React.useEffect(() => {
    if (!scanLoading) {
      fetchData({ [optionLabel]: search })
    }
  }, [search])

  return (
    <Spin indicator={<LoadingOutlined/>} spinning={scanLoading}>
      {contextHolder}
      <Space.Compact style={{ width: '100%' }}>
        <Select
          showSearch
          autoClearSearchValue
          defaultActiveFirstOption={false}
          ref={selectRef}
          options={options}
          optionFilterProp={optionLabel}
          fieldNames={{ label: optionLabel, value: optionValue }}
          loading={loading}
          notFoundContent={loading ? <Spin indicator={<LoadingOutlined size='small'/>} /> : null}
          value={value}
          onSearch={onSearch}
          onInputKeyDown={(event) => _.get(event, 'key', '').toLowerCase() == 'enter' ? getData() : null}
          onChange={onChange}
          disabled={disabled}
          {...selectProps}/>
        <ScanQRButton inputRef={selectRef} disabled={disabled}/>
      </Space.Compact>
    </Spin>
  );
});

const defaultProps = {
  // fetch props
  fetchUrl: '',
  fetchParams: {},
  usePagination: true,
  optionValue: 'id',
  optionLabel: 'name',
  manual: false,
  disabled: false,

  // select props
  value: '',
  onChange: () => null,
  placeholder: 'ค้นหา'
};

QRCodeSelect.propTypes = {
  // fetch props
  fetchUrl: PropTypes.string.isRequired,
  fetchParams: PropTypes.object,
  usePagination: PropTypes.bool,
  optionValue: PropTypes.string,
  optionLabel: PropTypes.string,
  manual: PropTypes.bool,
  disabled: PropTypes.bool,

  // select props
  value: PropTypes.any,
  onChange: PropTypes.func,
  styles: PropTypes.object,
}

export default QRCodeSelect;