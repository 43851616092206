import React from "react";
import TSTable from "../common/TSTable";
import { URL_DASHBOARD, URL_INFLORESCENCE, URL_PALMTREE } from "../../constants/urls";
import { DatePicker, Typography, Table, Select } from "antd";
import dayjs from 'dayjs';
import { GET } from "../../frameworks/HttpClient";
import useDropdownItem from "../../hooks/useDropdownItem";
import EmployeeInflorescenceModal from "./EmployeeInflorescenceModal";
import * as _ from "lodash";
import { INFLORESCENCE_ACTION, INFLORESCENCE_TASK_STATE } from "../../constants/strings";

export default function EmployeePerformanceTable(props) {  
  const { Text } = Typography;
  const [selectedDate, setSelectedDate] = React.useState(dayjs());
  const [selectGender, setSelectGender] = React.useState(null);
  const [selectPlantation, setSelectPlantation] = React.useState(null);
  const [genderOptions, setGenderOptions] = React.useState(null);
  const [target, setTarget] = React.useState(null);
  const [targetDetail, setTargetDetail] = React.useState(null);
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [params, setParams] = React.useState(null);
  const tableRef = React.useRef(null);
  const [summaryData, setSummaryData] = React.useState(null);

  const plantationDropdown = useDropdownItem({
    url: URL_PALMTREE.PLANTATION,
    label: 'code',
    value: 'id',
    pagination: true,
    // params: { worker: _.get(user, 'id', null), page_size: 999 }
  })

  const fetchGenderOptions = async () => {
    setErrorMessages(null)
    try {
      const response = await GET(URL_INFLORESCENCE.GENDER, { page_size: 999 })
      setGenderOptions(response.data.map((item) => { 
          return { ...item, label: item[1], value: item[0] }
        })
      )
    } catch (error) {
      setErrorMessages(error.errorMessages)
    }
  }

  const filterOption = (input, option) =>
  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())

  const columns = [
    {
      title: "พนักงาน",
      dataIndex: "submitter_name",
    },
    {
      title: "จำนวนช่อ",
      children: [
        {
          title: <center>สำรวจ (ช่อ)</center>,
          dataIndex: "create_count",
          render : (value, original) => <a onClick={() => {
            setTarget(original.submitter)
            setTargetDetail(`${original.submitter_name} - สำรวจ (ช่อ)`)
            setParams({ action: INFLORESCENCE_ACTION.CREATED, state: INFLORESCENCE_TASK_STATE.DONE})
          }
          }>
            {value}
          </a>
        },
        {
          title: <center>ช่อคลุม (ช่อ)</center>,
          dataIndex: "cover_count",
          render : (value, original) => <a onClick={() => {
            setTarget(original.submitter)
            setTargetDetail(`${original.submitter_name} - ช่อคลุม (ช่อ)`)
            setParams({ action: INFLORESCENCE_ACTION.COVER, state: INFLORESCENCE_TASK_STATE.DONE })
          }
          }>
            {value}
          </a>
        },
        {
          title: <center>ช่อผสม (ช่อ)</center>,
          dataIndex: "pollinate_count",
          render : (value, original) => <a onClick={() => {
            setTarget(original.submitter)
            setTargetDetail(`${original.submitter_name} - ช่อผสม (ช่อ)`)
            setParams({ action: INFLORESCENCE_ACTION.POLLINATE, state: INFLORESCENCE_TASK_STATE.DONE })
          }
          }>
            {value}
          </a>
        },
        {
          title: <center>ตัดทะลาย (ทะลาย)</center>,
          dataIndex: "cut_count",
          render : (value, original) => <a onClick={() => {
            setTarget(original.submitter)
            setTargetDetail(`${original.submitter_name} - ตัดทะลาย (ทะลาย)`)
            setParams({ action: INFLORESCENCE_ACTION.CUT, state: INFLORESCENCE_TASK_STATE.DONE })
          }
          }>
            {value}
          </a>
        },
        {
          title: <center>ช่อเสียหาย(ช่อ)</center>,
          dataIndex: "reject_count",
          render : (value, original) => <a onClick={() => {
            setTarget(original.submitter)
            setTargetDetail(`${original.submitter_name} - ช่อเสียหาย (ช่อ)`)
            setParams({ action : null, state : INFLORESCENCE_TASK_STATE.CANCEL })
          }
          }>
            {value}
          </a>
      },
      ]
    },
  ]

  const renderSummary = (e) => {
    return (
      <Table.Summary fixed>
        <Table.Summary.Row >
          <Table.Summary.Cell index={0}>รวม</Table.Summary.Cell>
          <Table.Summary.Cell index={1}>{_.sumBy(summaryData, 'create_count')}</Table.Summary.Cell>
          <Table.Summary.Cell index={2}>{_.sumBy(summaryData, 'cover_count')}</Table.Summary.Cell>
          <Table.Summary.Cell index={3}>{_.sumBy(summaryData, 'pollinate_count')}</Table.Summary.Cell>
          <Table.Summary.Cell index={4}>{_.sumBy(summaryData, 'cut_count')}</Table.Summary.Cell>
          <Table.Summary.Cell index={5}>{_.sumBy(summaryData, 'reject_count')}</Table.Summary.Cell>
        </Table.Summary.Row>
      </Table.Summary>
    )
  }

  const onChange = (date, _) => {
    setSelectedDate(date)
  };

  React.useEffect(() => {
    if (tableRef) {
      tableRef.current.fetch();
    }
  }, [selectedDate])

  React.useEffect(() => {
    fetchGenderOptions();
    plantationDropdown.fetchOptions()
  },[])

  React.useEffect(() => {
    if (tableRef) {
      tableRef.current.fetch();
    }
  },[selectGender])

  React.useEffect(() => {
    if (tableRef) {
      tableRef.current.fetch();
    }
  },[selectPlantation])

  return (
    <div>
      <div style={{ marginBottom : 8}}>
        <Text>เดือน : </Text>
        <DatePicker 
          onChange={onChange} 
          picker="month" 
          placeholder={"เลือก..."} 
          allowClear={false}
          defaultValue={dayjs()}/>
        <Text style= {{ marginLeft : 20}}>เพศ : </Text>
        <Select
          style={{ width : 200}}
          initialvalues={'-'}
          autoComplete='off'
          placeholder='เลือกเพศ...'                                                    
          filterOption={filterOption}
          options={genderOptions}
          onChange={(value) => setSelectGender(value)}
          allowClear={true}
          onClear={() => setSelectGender('')}
        />
        <Text style={{ marginLeft : 20}}>สวน : </Text>
        <Select
            style= {{ width : 200}}
            showSearch
            autoComplete='off'
            optionFilterProp='code'
            placeholder='เลือก...' 
            options={plantationDropdown.options}
            loading={plantationDropdown.loading}
            onChange={(value) => setSelectPlantation(value)}
            allowClear={true}
            onClear={() => setSelectPlantation('')}/>
      </div>

      <TSTable
        ref={tableRef}
        columns={columns}
        rowKey={"id"}
        url={URL_DASHBOARD.WORKER_DASHBOARD}
        params={{
          ordering: "submitter_name", 
          month: selectedDate ? selectedDate.format("M") : null, 
          year: selectedDate ? selectedDate.format("YYYY") : null,
          gender: selectGender,
          plantation : selectPlantation
        }}
        size={"small"}
        hidePagination={true}
        pageSize={100}
        summary={(e) => renderSummary(e)}
        onData={(data) => setSummaryData(data)} 
      />

      <EmployeeInflorescenceModal
        open={target ? true : false}
        onClose={() => {
          setTarget(null)
          setParams(null)
        }}
        target={target}
        targetDetail={targetDetail}
        params={{...params,
          ordering: "submitter_name", 
          month: selectedDate ? selectedDate.format("M") : null, 
          year: selectedDate ? selectedDate.format("YYYY") : null,
          gender: selectGender,
          plantation : selectPlantation
        }}
      />

    </div>
  )
}