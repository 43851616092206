import React from "react";
import propTypes from "prop-types";
import dayjs from "dayjs";
import { theme, Col, Row, Space, Button, Tooltip } from 'antd';
import { FileDoneOutlined, FilePdfOutlined, FileExcelOutlined, EditOutlined } from '@ant-design/icons';
import TSTable from "../components/common/TSTable";
import { URL_PALMTREE } from "../constants/urls";
import { PATH } from "../routes/CustomRoutes";
import useDropdownItem from "../hooks/useDropdownItem";
import { DATETIME_FORMAT, DELIVERY_TRANSACTION_STATE, DELIVERY_TRANSACTION_STATE_TH, EXPORT_TYPE_OPTIONS, EXPORT_TYPE_TH, HUMAN_DATETIME_FORMAT } from "../constants/strings";
import * as _ from 'lodash'

const ReceiverList = React.forwardRef((props, ref) => {
  const tableRef = React.useRef(null);
  const plantationDropdown = useDropdownItem({ 
    url: URL_PALMTREE.PLANTATION, 
    label: 'code', 
    value: 'code', 
    pagination: true, 
    params: { page_size: 999 } })

  const { isWaiting, isSender } = props;

  const {
    token: { colorInfo, colorWarning},
  } = theme.useToken();

  // For external componance call function
  React.useImperativeHandle(ref, () => ({ 
    // Refresh
    refresh: () => {
      return refreshData();
    }
  }));

  const columns = [
    {
      title: "Action",
      dataIndex: "id",
      skipReport: true,
      width: 60,
      render : (value, original) => (
        <Row gutter={[4, 4]} justify='center'>
          {(isSender && (original.state !== DELIVERY_TRANSACTION_STATE.RECEIVED)) && (
            <Col>
              <Tooltip title="แก้ไข">
                <Button 
                  style={{background: colorWarning}}
                  type={"primary"} 
                  icon={<EditOutlined />} 
                  onClick={() => props.onEdit(original)}/>
              </Tooltip>
            </Col>
          )}

          <Col>
            <Tooltip title="รายละเอียดใบส่งของ">
              <Button 
                style={{background: colorInfo}}
                type={"primary"} 
                icon={<FileDoneOutlined />} 
                onClick={() => window.open(`${PATH.RECEIVER_DETAIL}/${value}/?field_view=${isSender ? true : false}`)}/>
            </Tooltip>
          </Col>
        </Row>
      )
    },
    {
      title: "สวน",
      dataIndex: "plantation_code",
      hidden: isSender,
      searcher: props.plantationCode ? false : true,
      searchPlaceholder: 'Search Plantation',
      searcherOptions: [{ key: 'all', label: 'ทั้งหมด', value: '' }, ...plantationDropdown.options],
    },
    {
      title: "เลขที่ใบส่ง",
      dataIndex: "code",
      searcher : true,
    },
    {
      title: "ประเภท",
      dataIndex: "export_type",
      searcher: true,
      searchPlaceholder: 'Search Plantation',
      searcherOptions: [{ key: 'all', label: 'ทั้งหมด', value: '' }, ...EXPORT_TYPE_OPTIONS],
      render: (value) => (
        _.get(EXPORT_TYPE_TH, [value], '-')
      )
    },
    {
      title: "ทะเบียนรถ",
      dataIndex: "vehicle",
      hidden: !isSender,
    },
    {
      title: "พนักงานขับรถ",
      dataIndex: "driver",
      hidden: !isSender,
    },
    {
      title: "จำนวนทะลาย",
      dataIndex: "inflorescences_number",
    },
    {
      title: "สวนประเมิน",
      hidden : (!isWaiting && !isSender),
      renderReport: (value, original) => {
        return `${original.worker_accepted_number} / ${original.worker_rejected_number}`
      },
      children: [
        {
          title: "ผ่าน",
          dataIndex: "worker_accepted_number",
        },
        {
          title: "ไม่ผ่าน",
          dataIndex: "worker_rejected_number",
        },
      ],
    },
    {
      title: "โรงงานประเมิน",
      hidden : (isWaiting || isSender),
      renderReport: (value, original) => {
        return `${original.accepted_number} / ${original.rejected_number}`
      },
      children: [
        {
          title: "ผ่าน",
          dataIndex: "accepted_number",
        },
        {
          title: "ไม่ผ่าน",
          dataIndex: "rejected_number",
        },
      ],
    },
    {
      title: "หมายเหตุ",
      dataIndex: "note",
      hidden : (!isWaiting && !isSender),
      render: (value) => (value || '-'),
    },
    {
      title: "วันที่ส่ง",
      dataIndex: "sent_date",
      hidden : (isWaiting),
      // key : 'sent_after',
      // searcher : true, 
      // dateSearcher: true,
    },
    {
      title: "ผู้ส่ง",
      dataIndex: "sender_name",
      hidden : isWaiting
    },
    {
      title: "วันที่รับ",
      dataIndex: "received_date",
      hidden : (isWaiting || isSender),
      // key : 'receive_after',
      // searcher : true, 
      // dateSearcher: true,
    },
    {
      title: "ผู้ตรวจรับ",
      dataIndex: "receiver_name",
      hidden : (isWaiting || isSender)
    },

    {
      title: "สถานะ",
      dataIndex: "state",
      hidden : (!isSender),
      render: (value) => (DELIVERY_TRANSACTION_STATE_TH[value]),
      renderReport: (value) => (DELIVERY_TRANSACTION_STATE_TH[value])
    },
  ]

  const refreshData = () => {
    if(tableRef.current)
      tableRef.current.fetch();
  }

  React.useEffect(() => {
    if (props.isActive) {
      plantationDropdown.fetchOptions();

      // Fetch data
      refreshData();
    }
  },[props.isActive])
  
  return (
    <>
      <Space style={{ float: 'right', marginBottom: '10px' }}>
        <Row gutter={8}>
          <Col>
            <Button
              icon={<FilePdfOutlined />}
              onClick={async () => {
                await tableRef.current.exportPDF({
                  title: `รายการ${props.title}`,
                  subtitle: `ดึงข้อมูล ณ​ วันที่ ${dayjs().format(HUMAN_DATETIME_FORMAT)} น.`,
                  landscape: true
                })
              }}>
              PDF
            </Button>
          </Col>
          <Col>
            <Button
              icon={<FileExcelOutlined />}
              onClick={async () => {
                await tableRef.current.exportXlsx({
                  title: `รายการ${props.title}`,
                  subtitle: `ดึงข้อมูล ณ​ วันที่ ${dayjs().format(HUMAN_DATETIME_FORMAT)} น.`,
                  filename: `รายการ${props.title} - ${dayjs().format(DATETIME_FORMAT)}.xlsx`
                })
              }}>
              Excel
            </Button>
          </Col>
        </Row>
      </Space>

      <TSTable
        ref={tableRef}
        columns={columns}
        style={{ marginTop : 16 }}
        rowKey={"id"}
        url={props.url}
        params={{...props.params }}
        size={"small"}
        pageSize={10}
        autoFetch={false} />
    </>
  )
})
export default ReceiverList;

ReceiverList.defaultProps = {
  url: '',
  params: {},
  isWaiting: false,
  plantationCode: null,
  title: "",
  isActive: false,
  onEdit: (item) => {},
}

ReceiverList.propTypes = {
  url: propTypes.string,
  params: propTypes.object,
  isWaiting: propTypes.bool,
  plantationCode: propTypes.string,
  title: propTypes.string,
  isActive: propTypes.bool,
  onEdit: propTypes.func,
}