import React from 'react'
import { Modal, theme, Alert} from 'antd';
import ColorButton from '../common/ColorButton';
import * as _ from 'lodash';
import { POST } from '../../frameworks/HttpClient';
import { URL_DELIVERY } from '../../constants/urls';
import { useNavigate } from 'react-router-dom';
import { DELIVERY_TRANSACTION_STATE } from '../../constants/strings';
import { PATH } from '../../routes/CustomRoutes';

export default function SendProductSiModal(props) {
  const {open, onClose, target} = props;
  const [errorMessages, setErrorMessages] = React.useState(null);
  const navigate = useNavigate();

  const {
    token: { colorError, colorInfo }
  } = theme.useToken();

  const handleSend = async() => {
    setErrorMessages(null)
    try {
      await POST(`${URL_DELIVERY.DELIVERY_TRANSACTION}${_.get(target, 'id', null)}/${DELIVERY_TRANSACTION_STATE.SENT}/`)
      navigate(PATH.SENDER);
    } catch (error) {
      setErrorMessages(error.errorMessages)
    }
  }

  return(
    <Modal
      open={open}
      onCancel={onClose}
      centered
      maskClosable={false}
      footer={null}
    >
      {errorMessages && 
        <Alert
          style={{marginBottom: "12px", textAlign: "left"}}
          message={errorMessages}
          type="error"
          showIcon 
      />}
      <div style={{ textAlign : 'center' }}>
      <div style={{ fontSize : 18, fontWeight : 'bold', marginBottom : 16}}>ส่งของ</div>  
      <div>ยืนยันการส่งของ - {_.get(target, 'code', '')} ใช่หรือไม่ ?</div>
      </div>

      <ColorButton style={{ width : '100%', height : 50, marginTop : 20}} type='primary' override={colorInfo} onClick={() => handleSend()}>
        ยืนยัน
      </ColorButton>

    </Modal>
  )
}