import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserContext } from '../hooks/UserSettingProvider';
import { Alert, Button, Form, Image, Input, Modal, Spin } from 'antd';
import { LockOutlined, UserOutlined, LoginOutlined } from '@ant-design/icons';
import { URL_AUTH } from '../constants/urls';
import { POST } from '../frameworks/HttpClient'
import logo from '../assets/cpi.png'
import Segment from '../components/common/Segment';
import * as _ from 'lodash'
import { PATH } from '../routes/CustomRoutes';
import { USER_GROUP } from '../constants/strings';

export default function LoginScreen() {
  const navigate = useNavigate();
	const user = useUserContext();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);
  const passwordRef = React.useRef();

  // Login
  const handleLogin = async (values) => {
    setErrorMessages(null);
    setIsLoading(true);
    let response = null;
    try {
      response = await POST(URL_AUTH.API_TOKEN_AUTH, {...values})
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally {
      setIsLoading(false);
    }

    if (response)
      onFinish(response.data);
  }

  const onFinish = async (data) => {
    if (!data || !data.user) return;

    if (data.user.groups && data.user.groups.length === 0) {
      warningLogin("ยังไม่ได้ลงทะเบียนประเภทผู้ใช้งาน กรุณาติดต่อผู้ดูแลระบบ!");
      form.resetFields();
      return;
    }
    
    if (data.user.groups 
      && data.user.groups.find((group) => group === USER_GROUP.WORKER)
      && !data.user.plantation) {
      warningLogin("ผู้ใช้งานยังไม่ได้ลงทะเบียนสวน กรุณาติดต่อผู้ดูแลระบบ!");
      form.resetFields();
      return;
    }
    
    // Set data into cookies
    user.onLogin(data)

    // Goto target page after loged-in
    if (_.get(data, 'user', false)) {
      navigate(PATH.MAIN, {replace: true});
    }
  };

  const warningLogin = (msg) => {
    Modal.warning({
      title: "แจ้งเตือน",
      content: msg,
    });
  };

  const handleNext = (event, ref) => {
    if ((event.key === "Enter") && ref) {
      ref.current.input.focus();
    }
  }

  return(
    <div className='center-screen'>
      <div style={{width: "90%", maxWidth: 420}}>
        <Image
          style={{textAlign: "center", width: "100%", maxWidth: 180}}
          src={logo} 
          preview={false} />
        
        <Segment 
          style={{marginTop: 24, padding: 24}}
          detail={
            <Spin spinning={isLoading}>
              {errorMessages && 
                <Alert
                  style={{marginBottom: "12px", textAlign: "left"}}
                  message={errorMessages}
                  type="error"
                  showIcon 
                />}
              <Form
                style={{marginTop: 8, textAlign: "left"}}
                className="login-form"
                form={form}
                onFinish={handleLogin}
                autoComplete={"off"}>
                <Form.Item
                  name="username"
                  rules={[{ required: true, message: 'กรุณากรอกชื่อผู้ใช้!' }]}>
                  <Input 
                    autoCapitalize='none'
                    prefix={<UserOutlined className="site-form-item-icon" />} 
                    placeholder="ชื่อผู้ใช้"
                    onKeyDown={(ev) => handleNext(ev, passwordRef)} />
                </Form.Item>

                <Form.Item
                  name="password"
                  rules={[{ required: true, message: 'กรุณากรอกรหัสผ่าน!' }]}>
                  <Input.Password
                    ref={passwordRef}
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    placeholder="รหัสผ่าน"/>
                </Form.Item>

                <Form.Item style={{margin: 0}}>
                  <Button
                    style={{width: "100%"}}
                    type={"primary"} 
                    htmlType="submit" 
                    icon={<LoginOutlined />}>{"เข้าสู่ระบบ"}</Button>
                </Form.Item>
              </Form>
            </Spin>
          }/>
      </div>
    </div>
  )
}