import React from 'react'
import propTypes from "prop-types";
import { Header } from 'antd/es/layout/layout'
import { Button, Space, Typography, theme } from 'antd';
import { LeftOutlined, CloseOutlined } from '@ant-design/icons';
import "./../../styles.css"
import { useNavigate } from 'react-router-dom';
import { BG_LAYOUT_COLOR, BLACK_COLOR, PRIMARY_COLOR, PRIMARY_LIGHT_COLOR, WHITE_COLOR_BG } from '../../constants/color';

export default function HeaderScreen(props) {
	const {
		title,
		showBackIcon,
		showCloseIcon,
		element,
		onClick,
	} = props;

	const navigate = useNavigate();

	const {
    token: { colorPrimary },
  } = theme.useToken();

	const goBack = () => navigate(-1)
	const goClose = () => { window.close(); navigate(-1) }

	return (
		<Header 
			style={{ 
				position: 'sticky', 
				top: 0, 
				zIndex: 1, 
				// width: '100%', 
				background: PRIMARY_COLOR, 
				color: "#fff", 
				paddingInline: '1.5rem',
			}}>
			<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
				<Space direction='horizontal' align='baseline'>
					{ showBackIcon && (
						<Button
							style={{ color: WHITE_COLOR_BG }}
							type='text'
							size='large'
							icon={<LeftOutlined/>}
							onClick={onClick ? onClick : goBack} />
					)}
					{ showCloseIcon && (
						<Button
							style={{ color: WHITE_COLOR_BG }}
							type='text'
							size='large'
							icon={<CloseOutlined/>}
							onClick={onClick ? onClick : goClose} />
					)}
					<Typography.Title style={{ margin: 0, color : WHITE_COLOR_BG }} level={5}>{title}</Typography.Title>
					{element}
				</Space>			
			</div>
		</Header>
	)
}

HeaderScreen.defaultProps = {
	title: null,
	showBackIcon: true,
	showCloseIcon: false,
	element: null,
	onClick: undefined
}

HeaderScreen.propTypes = {
	title: propTypes.string,
	showBackIcon: propTypes.bool,
	showCloseIcon: propTypes.bool,
	element: propTypes.element,
	onClick: propTypes.func,
}