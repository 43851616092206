import React from "react";
import { Modal } from "antd";

export default function PrintAllConfirmModal (props) {
  const { open, onClose, onConfirm, getTotal } = props

  const [total, setTotal] = React.useState(0);

  React.useEffect(() => {
    if (open) {
      setTotal(getTotal())
    }
  }, [open])

  return (
    <Modal
      title='ยืนยันการพิมพ์สติ๊กเกอร์รวม'
      open={open}
      okText='พิมพ์'
      cancelText='ยกเลิก'
      onOk={onConfirm}
      onCancel={onClose}
      maskClosable={false}
    >
      ต้องการพิมพ์สติ๊กเกอร์รวมทั้งหมด {total} ใบหรือไม่ ?
    </Modal>
  )
}