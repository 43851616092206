import React from 'react';
import HeaderMobile from '../components/common/HeaderMobile';
import { Button, Layout, DatePicker, Form, Input, Row, Col, Space, Checkbox, theme, Alert, Select, message, Image } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { Content, } from 'antd/es/layout/layout';
import ColorButton from '../components/common/ColorButton';
import imageDefault from "../assets/placeholderImage.png";
import { SUCCESS_COLOR } from '../constants/color';
import { GET, POST } from '../frameworks/HttpClient';
import { useNavigate } from 'react-router-dom';
import { URL_COMMON, URL_INFLORESCENCE, URL_PALMTREE, URL_TASK } from '../constants/urls';
import * as dayjs from 'dayjs'
import { PATH } from '../routes/CustomRoutes';
import _ from 'lodash';
import { DATE_FORMAT, INFLORESCENCE_ACTION, INFLORESCENCE_GENDER, INFLORESCENCE_STATE, REJECT_REASON_MAP, TASK_TYPE } from '../constants/strings';
import QRCodeSelect from '../components/common/QRCodeSelect';
import RejectReasonSelect from '../components/task/RejectReasonSelect';
import QRCodeSearch from '../components/common/QRCodeSearch';
import { useUserContext } from '../hooks/UserSettingProvider';
import { resizedImage } from '../frameworks/CropUtil';
import { MAX_TASK_NUMNER } from '../constants/configs';


export default function AddInflorescences() {
  const { user } = useUserContext();
  const [form] = Form.useForm();
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [genderOptions, setGenderOptions] = React.useState(null);
  const [damagedChecked, setDamagedChecked] = React.useState(false);
  const [taskNumber, setTaskNumber] = React.useState(null);
  const [messageApi, contextHolder] = message.useMessage();
  const [imageData, setImageData] = React.useState(null);
  const [maximumDay, setMaximumDay] = React.useState(null);
  const [selectGender, setSelectGender] = React.useState(null); // For validate code required
  const uploadUserRef = React.useRef(null);
  const navigate = useNavigate();

  const handleSave = async() => {
    try {
      let data = await form.validateFields();
      data['new_date'] = dayjs(data.new_date).format(DATE_FORMAT)
      if(imageData){
        data['images'] = [{ image : imageData}]
      }
      await POST(URL_INFLORESCENCE.INFLORESCENCE, data)
      messageApi.open({
        type: 'success',
        content: 'เพิ่มช่อดอกสำเร็จ',
      });
      navigate(PATH.TASK)
    } catch (error) {
      setErrorMessages(error.errorMessages)
    }
  }

  const {
    token: { colorError},
  } = theme.useToken();

  const filterOption = (input, option) =>
  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())

  const fetchGenderOptions = async () => {
    setErrorMessages(null)
    try {
      const response = await GET(URL_INFLORESCENCE.GENDER, { page_size: 999 })
      setGenderOptions(response.data.map((item) => { 
          return { ...item, label: item[1], value: item[0] }
        })
      )
    } catch (error) {
      setErrorMessages(error.errorMessages)
    }
  }

  // Fetch Task on day (number)
  const fetchTaskNumber = async (dateStr) => {
    try {
      const response = await GET(URL_TASK.WORKER_TASKA_COUNT, { plan_date: dateStr });
      setTaskNumber(response.data.number);
    } catch (error) {
      console.log(error.errorMessages);
    }
  }

  // Handle maximum day
  const fetchTaskActivateDate = async () => {
    try {
      const response = await GET(URL_COMMON.TASK_ACTIVATE_DATE_SUBMIT, 
        {
          action: INFLORESCENCE_ACTION.GENDER, 
          gender: INFLORESCENCE_GENDER.FEMALE
        });
      setMaximumDay(response.data.detail);
    } catch (error) {
      console.log(error.errorMessages);
    }
  }

  React.useEffect(() => {
    fetchTaskActivateDate();
    fetchGenderOptions();
    form.resetFields();
    setSelectGender(null);
  },[])

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <HeaderMobile title={'เพิ่มช่อดอก'} className='navbar-top' back={true}/>
      {contextHolder}
      <Content
        style={{
          margin: 24,
          marginTop: 54,
          marginBottom: 60,
          minHeight: 280,
        }}>
        
        <Form form={form} layout={'vertical'}>
          <Row>
            <Space.Compact block>
              <Form.Item
                name={'tree'}
                label={'รหัสต้น'}
                rules={[{ required: true }]}
                style={{ width : '100%'}}>
                <QRCodeSelect
                  fetchUrl={URL_PALMTREE.PALMTREE}
                  fetchParams={{ plantation_code: user ? user.plantation_code : '', is_active: true }}
                  optionLabel={'code'}
                  placeholder='เลือกต้นพันธุ์'/>
              </Form.Item>
            </Space.Compact>
          </Row>
          <Row>
            <Col md={24} sm={24} xs={24}>
              <Form.Item
                name={'code'}
                label={'รหัสช่อ'}
                rules={[{ required: (selectGender && !damagedChecked) ? true : false }]}>
                <QRCodeSearch />
              </Form.Item>
            </Col>
            <Col md={24} sm={24} xs={24}>
              <Form.Item
                name={'branch'}
                label={'ทางใบ'}
                rules={[{ required: true }]}>
                <Input />
              </Form.Item>
            </Col>
            <Col md={24} sm={24} xs={24}>
              <Form.Item
                name={'gender'}
                label={'เพศ'}>
                <Select
                  style={{ width : '100%'}}
                  initialvalues={'-'}
                  autoComplete='off'
                  filterOption={filterOption}
                  options={genderOptions}
                  onChange={(value) => setSelectGender(value)}
                  allowClear={true}
                />
              </Form.Item>
            </Col>
            <Col md={24} sm={24} xs={24}>
              <Checkbox 
                style={{ marginBottom : 20}} 
                checked={damagedChecked} 
                onChange={(e) => setDamagedChecked(e.target.checked)}>
                  เสีย
              </Checkbox>
            </Col>

            {!damagedChecked &&
              <Col md={24} sm={24} xs={24}>
                <>
                  <Form.Item
                    name={'new_date'}
                    label={'สำรวจช่อครั้งถัดไป'}
                    rules={[{ required: true }]}>
                  <DatePicker 
                    onFocus={evt => { evt.target.blur() }}
                    placeholder={"เลือกวันสำรวจ"}
                    minDate={dayjs()} 
                    maxDate={maximumDay ? dayjs().add(maximumDay, 'day') : null}
                    style={{ width : '100%'}} 
                    onChange={(date, dateStr) => {
                      fetchTaskNumber(dateStr);
                      form.setFieldValue('new_date', date)
                    }}
                    format={'YYYY-MM-DD'}/>
                  </Form.Item>
                  {taskNumber != null &&
                    <Form.Item>
                      <Alert
                        message={taskNumber != null ? `งานรอทำ ${taskNumber} งาน` : ""}
                        type={(taskNumber != null && taskNumber > MAX_TASK_NUMNER) ? "warning" : "success"} />
                    </Form.Item>
                  }
                </>
              </Col>
            }

            {damagedChecked &&
              <Col md={24} sm={24} xs={24}>
                <Form.Item
                  name={'reject_reason'}
                  label={'เหตุผล'}
                  rules={[{ required: true}]}>
                  <RejectReasonSelect
                    url={_.get(REJECT_REASON_MAP, _.get(TASK_TYPE, 'INFLORESCENCE', ''), '')}
                    state={_.get(INFLORESCENCE_STATE, 'INPUT_GENDER', null)}
                  />
                </Form.Item>
              </Col>
            }

            <Col md={24} sm={24} xs={24}>
              <Form.Item
                name={'note'}
                label={'หมายเหตุ'}>
                <Input.TextArea />
              </Form.Item>
            </Col>
            <Col md={24} sm={24} xs={24} 
              style={{ 
                width : '100%', 
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
              }}>
              {imageData && 
                <Image
                  src={imageData ? imageData : imageDefault}
                    style={{ aspectRatio: imageData ? 2/3 : 1 , height : 350 }}
                />
              }
            </Col>
            <Col md={24} sm={24} xs={24}>
              <div                
                style={{ 
                  width : '100%', 
                  alignItems: 'center',
                  justifyContent: 'center',
                  display: 'flex',
                }}>
                <Button 
                  style={{marginTop: 8, marginBottom: 16}}
                  icon={<UploadOutlined />} 
                  onClick={() => uploadUserRef.current.click()}>
                    อัพโหลดรูป
                </Button>
              </div>
            </Col>

            {errorMessages && 
              <Alert
                message={errorMessages}
                type="error"
                showIcon
                style={{marginBottom: 8, textAlign: "left", width : '100%'}}/>
            }

            <ColorButton 
              style={{ width : '100%', height : 48}} 
              type='primary' 
              // shape="round" 
              override={damagedChecked ? colorError : SUCCESS_COLOR } 
              onClick={() => handleSave()}>  
              บันทึก
            </ColorButton>
          </Row>
        </Form>
      </Content>

      <input id="imgSelect"
        type="file"
        accept="image/*"
        ref={uploadUserRef}
        style={{ display: 'none' }}
        onChange={async (evt) => {
          if (evt.target.files.length > 0) {
            setImageData(await resizedImage(evt.target.files[0]))
            evt.target.value = null  // clear reference to enable select same file}
        }}}
      />

    </Layout>
  )
}